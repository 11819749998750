<template>
<div id="timetrackerPrev">
      <div class="nav">
        <Header :readFile="readFile" page="accueil"/>
      </div>
    
        <link ref="https://use.fontawesome.com/releases/v5.7.2/css/all.css"/>
        <link rel="stylesheet" href="https://code.jquery.com/ui/1.13.1/themes/smoothness/jquery-ui.css">
        
        <link rel="stylesheet" href="extensions/sticky-header/bootstrap-table-sticky-header.css">
      <div id="main">
         
        <div class="container-fluid" >

             <tabs class="pt-2">

                <tab class="nav-tabs" name="Timetracker previsionnel">
                    <div class="row " v-show="!readFile">
                        <div class="col-2 text-center mt-5">
                            <img style="width : 80%" src="@/assets/LogoVII.png" alt="LOGO" />
                        </div>
                        <div class="col-5 p-3 ml-2  border" style="margin-top : 5px; justify-content: center; align-items: center; padding-top: 50px !important;">

                            <label>Choisir une année</label>
                            <select class="form-control mr-sm-2"  v-model="year" @change="onchangeYear" placeholder="mois" >
                                
                                <option v-for="(item) in years" :value="item" :key="item">{{item}}</option>
                            </select>
                            <hr>
                            <label>Choisir un mois</label>
                            
                            <select class="form-control mr-sm-2"  v-model="month" @change="onchangeMonth" placeholder="mois" >
                                
                                <option v-for="(item) in monthsDispo" :value="item" :key="item">{{months[item-1]}}</option>
                            </select>
                            <hr>
                        
                            <!--button class="btn btn-block btn-primary mt-2" @click="exportToExcel('xlsx')"> Génerer Doc</button--> 
                        </div>
                        <div class="col-sm-4 pl-2 m-2 ml-5 ">
                            
                            <div class="row">
                                <div class="col-sm-6 p-3 pl-2">
                                    <table style="border-collapse:separate; border-spacing: 0 10px;" >
                                    <tr >
                                        <td class="JT p-2"> JT</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">Jour Travaillé</td>
                                    </tr>
                                    <tr>
                                        <td class="MA p-2"> MA</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">Maladie</td>
                                    </tr>
                                    <tr>
                                        <td class="CP p-2 "> CP</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">Congé payé</td>
                                    </tr>
                                    
                                    <tr >
                                        <td class="CI p-2">CI</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">1/2 Congé / Intercontrat</td>
                                    </tr>
                                    <tr class="mb-1">
                                        <td class="CX p-2"> CX</td>
                                        <td></td>
                                        <td class="p-2"  style="text-align: left">Congé exceptionnel</td>
                                    </tr>
                                    <tr >
                                        <td class="TI">TI</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">1/2 Travail / Intercontrat</td>
                                    </tr>
                                    <tr class="mb-1">
                                        <td class="CS"> CS</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">Congé sans solde</td>
                                    </tr>
                                    
                                    
                                    </table>
                                    
                                </div>
                                <div class="col-sm-6 p-3" >
                                    <table style="border-collapse:separate; border-spacing: 0 10px;">
                                    <tr class="mb-1">
                                        <td class="RT p-2"> RT</td>
                                        <td></td>
                                        <td class="p-1 p-2" style="text-align: left">RTT</td>
                                    </tr>
                                <tr >
                                        <td class="TC p-2"> TC</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">1/2 Travail / Congé payé</td>
                                    </tr>
                                    <tr >
                                        <td class="TM p-2"> TM</td>
                                        <td></td>
                                        <td class="p-2"  style="text-align: left">1/2 Travail / Maladie </td>
                                    </tr>
                                    <tr >
                                        <td class="TR p-2"> TR</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">1/2 Travail / RTT</td>
                                    </tr>
                                    <tr class="mb-1">
                                        <td class="IR p-2"> IR</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">1/2 Intercontrat / RTT</td>
                                    </tr>
                                    
                                    <tr >
                                        <td class="IC p-2"> IC</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">Intercontrat</td>
                                    </tr>
                                    <tr>
                                        <td class="AP">AP</td>
                                        <td></td>
                                        <td class="p-2" style="text-align: left">Absence prévisionnelle</td>
                                    </tr>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div class="row" v-show="!readFile">
                        <div class="col-sm-2 pt-5">
                            <router-link to="/annuaire" style="padding-left : 10px">Voir la liste des collaborateurs</router-link>
                        </div>
                        <div class="col-sm-10 align-items-center">
                            <hr>
                            <!-- <button @click='$refs.file.click()' class="btn btn-warning text-white float-right mt-2 mr-2"><i class=" fas fa-solid fa-upload"></i>  Importer Excel</button> -->
                            <!--button v-show="user.role==5" @click='goToListTimetrackerValid()' class="btn text-white float-right mt-2 mr-2" style="background-color:#0F5752" :disabled="tabIsReady.length==0"><i class=" fas fa-solid fa-list"></i>&ensp;Les Timetrackers validés <span class="badge badge-danger">{{tabIsReady.length}}</span></button-->
                            <!--button @click='goToListTimetrackerReady()' class="btn text-white float-right mt-2 mr-2" style="background-color:lightseagreen" :disabled="tabIsReady.length==0"><i class=" fas fa-solid fa-circle-check"></i>&ensp;Valider Timetrackers <span class="badge badge-danger">{{countTimetrackerToValid()}}</span></button-->
                            <button @click='printDocument()' class="btn text-white float-right mt-2 mr-2" style="background-color:lightseagreen" ><i class=" fas fa-solid fa-circle-check"></i>&ensp;Exporter </button>
                                <input  type='file' ref="file" accept=".xlsx" id='openfile' hidden @change="onChangeFile2" multiple/>
                            <div class="mt-1 form-inline">
                            <h2 class="form-control " style="background-color: #0c0c56">RECHERCHE </h2>
                            <input class="form-control" v-model="search"/>
                            </div>
                        </div>
                    </div>
                   
                    <div  v-if="readFile" style="overflow-x:auto;">
                        <Carousel>
                            <Slide v-for="(timetracker, index) in timetrackers" :key="index">
                                <div class="carousel__item" align="center">
                                    <div class="alert alert-danger" id="error-alert" v-if="error && error_message[index]!=''">
                                        <!--button type="button" class="close" data-dismiss="error-alert">x</button-->
                                        <p id="message_error"><strong>ERREUR ! </strong> </p>
                                        <div v-html="replaceWithBr(error_message[index])"></div>
                                </div>
                                <div class="alert alert-success" id="success-alert" v-else>
                                    <!--button type="button" class="close" data-dismiss="success-alert">x</button-->
                                    <p id="message_error"><strong>Fichier correct ! </strong> </p>
                                    <button :id="'btn_valid_'+timetracker.previsionnelle._id" v-if="!timetracker.previsionnelle.isValid && timetracker.previsionnelle.isValid=='NO'" class="btn btn-success" @click="valid(timetracker.previsionnelle)"> Valider</button>
                                    <p :id="'p_valid_'+timetracker.previsionnelle._id" v-else>Timetracker validé</p>
                                </div>
                            
                                <h2>{{timetracker.name}} {{timetracker.firstname}}</h2>                    
                                <h2 align="center" style="height: 50px; background-color: hwb(41deg 5% 11%)" class="text-white pt-2">Timetracker <b>{{timetracker.month}} {{timetracker.year}}</b></h2>
                                <div style="overflow-x:auto;">
                                <table class="table table-bordered" width='100%'>
                                    <tr>
                                        <th style="width: 200px">Nombre jours ouvrés</th>
                                        <th style="width: -200px">{{timetracker.jours_ouvrable}}</th>
                                        <th style="width: 2px" v-for="(item,index) in timetracker.days"  :key="index">
                                            <b>{{format_date(item)}}</b>
                                        </th>
                                    </tr>
                                    <tbody>
                                        <tr>
                                            <td>Nombre jours saisis</td>
                                            <td>{{timetracker.jours_saisi}}</td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.daysTimetracker"  :key="index">
                                                <b>{{item}}</b> 
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Absence</td>
                                            <td>{{timetracker.missed_day}}</td>
                                        </tr>
                                        <tr v-if="timetracker.missed_day>0" >
                                            <td></td>
                                            <td>Congés </td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.aConge"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr v-if="timetracker.missed_day>0">
                                            <td></td>
                                            <td>RTT </td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.aRtt"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr v-if="timetracker.missed_day>0" >
                                            <td></td>
                                            <td>Arrêt maladie </td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.aMaladie"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr v-if="timetracker.missed_day>0">
                                            <td></td>
                                            <td>Formation </td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.aFormation"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr v-if="timetracker.missed_day>0">
                                            <td></td>
                                            <td>Congés exc. </td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.aCongeExc"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr v-if="timetracker.missed_day>0">
                                            <td></td>
                                            <td>Congés s. solde </td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.acongeSSolde"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Clientèle</td>
                                            <td>{{timetracker.clientele}}</td>
                                        </tr>
                                        <tr v-if='timetracker.daysSurSite>0'>
                                            <td></td>
                                            <td>Sur site</td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.clienteleSite"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr v-if='timetracker.daysTeletravail>0'>
                                            <td></td>
                                            <td>Télétravail</td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.clienteleTeletravail"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr v-if='timetracker.daysDeplacement>0'>
                                            <td></td>
                                            <td>Déplacement</td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.clienteleDeplacement"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr v-if='timetracker.daysPresentation>0'>
                                            <td></td>
                                            <td>Présentation</td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.clientelePresentation"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Interne</td>
                                            <td>{{timetracker.interne_day}}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Projet</td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.projet"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Intercontrat</td>
                                            <td style="width: 2px" v-for="(item,index) in timetracker.intercontrat"  :key="index">
                                                <b v-if='item!=0'>{{item}}</b>
                                                <b v-else></b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <h2 class="text-center p-2 text-white"  style="background-color: #024034;margin-left: -5px; width:100%" v-if="timetracker.previsionnelle">Timetracker prévisionnel {{months[timetracker.previsionnelle.month-1]}} {{timetracker.previsionnelle.year}}</h2>
                                <div style="overflow-x:auto;">
                                <table id="real_timetracker" class="table table-bordered" width='100%' align="center" v-if="timetracker.previsionnelle">
                                
                                    <tr>
                                        <th style="width:50px;"></th>
                                        <th style="width:50px;"></th>
                                        
                                        <th style="width:2px; text-align:center" v-for='index in daysInMonth' :key="index">
                                                <b>{{index}}</b>
                                                <b hidden>{{index+1}}</b>
                                        </th>
                                    </tr>
                                    
                                <tbody>
                                    
                                    <tr>
                                        <td><b>NOM</b></td>
                                        <td><b>Prénom</b></td>
                                        <td style="width:2px; text-align:center" v-for='index in timetracker.previsionnelle.days' :key="index">
                                            <b>{{index}}</b>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td>{{timetracker.previsionnelle.user.last_name}}</td>
                                        <td>{{timetracker.previsionnelle.user.first_name}}</td>
                                        
                                        <td :contenteditable="!timetracker.previsionnelle.isValid=='YES'? false : true" :class="code.code_abbrev? code.code_abbrev : 'null'"  @blur="changeCodePrev(index,timetracker.previsionnelle, timetracker )" :id="'td'+index+'_'+timetracker.previsionnelle.user._id" style="width:2px;text-transform:uppercase" v-for='(code, index) in timetracker.previsionnelle.tt_by_day'  :key="index">
                                            <strong>{{code.code_abbrev}} </strong> 
                                            
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                                <hr>
                                <div class="row" v-if="timetracker.previsionnelle">
                                    <div class="col-sm-5  ">
                                        <h4>Modifier code entre deux date</h4>
                                        <select class="form-control mr-sm-2" :id="'datestart_'+timetracker.previsionnelle.user._id" placeholder="Jour début" >
                                            <option value=""  selected>Jour début </option>
                                            <option  v-for="(item,index) in timetracker.days"  :value="format_date(item)" :key="index">{{format_date(item)}}</option> 
                                        </select>
                                        <hr>
                                        <select class="form-control mr-sm-2" :id="'dateend_'+timetracker.previsionnelle.user._id" placeholder="Jour fin" >
                                            <option value=""  selected>Jour Fin </option>
                                            <option  v-for="(item,index) in timetracker.days"  :value="format_date(item)" :key="index">{{format_date(item)}}</option> 
                                        </select>
                                        <hr>
                                        <select class="form-control mr-sm-2" :id="'codeDate_'+timetracker.previsionnelle.user._id" placeholder="Code" >
                                            <option value=""  selected>Code </option>
                                            <option v-for="item in etats" :value="item.code_id" :key="item">{{item.code_abbrev}}</option>
                                        </select>
                                        <hr>
                                        <button class="btn btn-block btn-info" @click="updateCode(timetracker.previsionnelle, timetracker)">Modifier code entre deux date</button>
                                    </div>
                                    <div class="col-sm-1 ">
                                    </div>
                                    <div class="col-sm-6 ">
                                        <div class="form-group">
                                            <label for="note">Notes</label>
                                            <textarea class="form-control" :id="'note_'+timetracker.previsionnelle._id" rows="3" v-model="timetracker.previsionnelle.note" @blur="saveNote(timetracker.previsionnelle)"></textarea>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </Slide>

                            <template #addons="{ slidesCount }">
                                <Navigation v-if="slidesCount > 1" />
                                <Pagination v-if="slidesCount > 1" />
                            </template>
                        </Carousel>
                    </div>
                    <div class="row p-1" v-if="!readFile" id="timetrackerToExport">
                        <h2 class="text-center p-2 text-white sticky-top"  style="background-color: #024034;  width:100%">
                                {{ months[month-1]}} {{year}}
                        </h2>
                       
                        <!-- <div class="table-container" > -->
                        <table id="tbl_exporttable_to_xls" class="table table-bordered " style="margin-top: -10px;max-height: 900px;" width='100%'>
                            <thead>
                                    <tr>
                                        <th class="thead" style="max-width:10px;"></th>
                                        <th class="thead" style="width:50px;"></th>
                                        <th class="fixed-width thead"></th>
                                        <th class="fixed-width thead"><b>Semaines</b></th>
                                        <th v-for='(week, index) in getWeek(month)'  :key="index" class="thead">
                                                S{{week}}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="thead"></th>
                                        <th class="thead"></th>
                                        <th class="thead"></th>
                                        <th class="thead"><b>Jours</b></th>
                                        <th class="thead" style="width:2px; text-align:center" v-for='index in daysInMonth' :key="index">
                                            <b>{{index}}</b>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="thead" style="width:10px;cursor : pointer" id="VALID"><b :class="checkValid=='YES'? 'checkValid' : ''">Validé</b></th>
                                        <th class="thead" style="width:10px;cursor : pointer" id="PRET" ><b :class="checkPret=='YES'? 'checkPret' : ''">Prêt</b></th>
                                        <th class="fixed-width thead"><b>NOM</b></th>
                                        <th class="fixed-width thead"><b>Prénom</b></th>
                                        <th class="thead" style="width:2px;text-align: center" v-for='(day, i) in getDay(daysInMonth)' :key="i">
                                            <small>{{day}} </small> 
                                        </th>
                                    </tr>
                            </thead>
                            <tbody>
                               
                                
                                <tr class="fixed-height"  v-for="(item, indexPrev) in filterPrevUser().slice(this.startRowAcc,this.startRowAcc+this.rowsPerPageAcc)" :key="item._id" >
                                    <td v-if='item.isValid=="YES"' align="center" style="background-color: green"  @click="handleClick(item._id, item.user)"><i class="fas fa-sharp fa-solid fa-check" style="color: white; width: 100%"></i></td>
                                    <td v-else align="center" ><input type="checkbox" style="width:100%" disabled/></td>
                                    <td :class="isTimetrackerReady(item.user._id)? 'pret' : 'nonPret'"><span v-if='isTimetrackerReady(item.user._id)' class="text-white">P</span></td>
                                    <td class="fixed-width" :title="item.user.last_name" ><router-link :to="{path: 'timetrackerCollab', query:{id: item.user._id, month:month, year : year}}" :class="!isTimetrackerReady(item.user._id)? 'disabled' : ''" >{{item.user.last_name}}</router-link></td>
                                    <td class="fixed-width" :title="item.user.first_name"><router-link :to="{path: 'timetrackerCollab', query:{id: item.user._id, month:month, year : year}}" :class="!isTimetrackerReady(item.user._id)? 'disabled' : ''">{{item.user.first_name}}</router-link></td>
                                    

                                    <td maxLength="2" minLength="2" style="text-transform:uppercase; text-align: center" :contenteditable="item.isValid=='YES'? 'false' : true" :class="code.code_abbrev? code.code_abbrev+' limit_2' : 'null limit_2'" :id="'td'+indexPrev+'-'+index" @blur="changeCodePrev2(index,indexPrev,item )" @input="limit_2('td'+indexPrev+'-'+index)"  v-for='(code, index) in item.tt_by_day'  :key="index">
                                        {{code.code_abbrev}} 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        <div id="page-navigation" v-if="filterPrevUser().length>rowsPerPageAcc">
                            <button class="btn btn-primary text-white" @click=movePage(-1)>Précédent</button>
                            {{startRowAcc / rowsPerPageAcc + 1}} page sur {{Math.ceil(filterPrevUser().length / rowsPerPageAcc)}}
                            <button class="btn btn-primary text-white" @click=movePage(1)>Suivant</button>
                        </div> 
                    <!-- </div> -->
                    
                </tab>
                <tab class="nav-tabs" name="Mon timetracker">
                    <Timetracker :showNav="false"/>
                </tab>
            </tabs>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import data from '@/data/data'
import {setCookie, getCookie} from '@/data/function'


import readXlsxFile from 'read-excel-file'
import moment from 'moment'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
 import { useToast } from "vue-toastification";
const toast = useToast()
import Header from '@/components/Header.vue'
import Timetracker from './Timetracker.vue'
import store from '../store'

import * as $ from "jquery";

import emailjs from 'emailjs-com';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";


/*import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';*/
export default {
  name: 'TimetrackerPrev',
  components: {
      Carousel, Slide, Pagination, Navigation, Header,Timetracker, 
  },
  created(){
        var currentTime = new Date()
        //var today = currentTime.getDate()   
        
        let cookie = getCookie('month')
            
        if(cookie != null) this.month = parseInt(cookie)   
        else{
            if(currentTime.getDate()<20)
                this.month = currentTime.getMonth() 
            else   this.month = currentTime.getMonth() +1
        }
        this.year = currentTime.getFullYear()
        
        //this.years = [this.year-2, this.year-1, this.year]
        this.daysInMonth =  new Date(this.year, this.month, 0).getDate();
        this.days = this.getDay(this.daysInMonth)
        this.weeks = this.getWeek(this.month)

       
  },
  mounted(){
    
    this.getTimetrackerPrevisionnel()
    $(".custom-file-input").on("change", function() {
        var fileName = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    })
    

    let jQueryScript = document.createElement('script')
    jQueryScript.setAttribute('src', 'https://unpkg.com/vue-nav-tabs/dist/vue-tabs.js')
    let script = document.createElement('script')
    script.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.0/umd/popper.min.js')
    
    document.head.appendChild(jQueryScript)
    document.head.appendChild(script)
   
    document.getElementById("VALID").addEventListener('click', (()=>{
        if(this.checkValid=='NO')
            this.checkValid = 'YES'
        else this.checkValid = 'NO'
    }))
    document.getElementById("PRET").addEventListener('click', (() => {
        if(this.checkPret=='NO')
            this.checkPret= 'YES'
        else this.checkPret = 'NO'
    }))
    axios.get(data.baseUrl + "timetracker/get-year-list")
        .then(response => {
            this.years = response.data
        })
        .catch(error => console.log(error))
    axios.get(data.baseUrl + "users")
        .then(response => {
          this.collaborateurs = response.data
        })
        .catch(error => console.log(error))
    
    axios.post(data.baseUrl + "timetracker/get-month-list-by-year/", {"year" : this.year})
        .then(response => {
            this.monthsDispo = response.data
        })
        .catch(error => console.log(error))
    axios.post(data.baseUrl+"timetracker-collab/", { "month" : this.months[this.month-1], "year" : parseInt(this.year)})
    .then(response=> {
        
            this.tabIsReady = response.data
            
    }).catch(error=> console.log(error))
    
    axios.get(data.baseUrl + "codetypes")
    .then(response => {
        this.etats = response.data
    })
    .catch(error => console.log(error))
   
    this.countJourOuvre()
  },
  
  data(){
    return{
        user : store.state.user,
        collaborateurs: [],
      search : '', 
      startRowAcc: 0,
      rowsPerPageAcc: 20,
      months :["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"],
      year : 0,
      month : 0,
      etats : ['JT', 'MA', 'IC', 'CP','RT', 'CX','CS', 'TC', 'CI', 'TM', 'TI', 'AP'],
      code : "JT", 
      daysInMonth:0,
      days : [], 
      readFile : false, 
      years : [], 
      weeks : [],
      timetracker : {},
      error : false, 
      compteurJourOuvre : 0,
      objectPrevisionnelle : [],
      timetrackers : [], 
      error_message : [], 
      monthsDispo : [], 
      tabIsReady :[],
      checkPret : 'NO',
      checkValid : 'NO',
    }
  },
  computed:{
    
  },
  methods: {
    addCookie(){
        console.log("ADD COOKIE")
        setCookie('month', this.month, 1)
    },
    
    limit_2(elementID){
        var texte = document.getElementById(elementID).textContent;
        let suggestion = ''
        let codes =  ['JT', 'MA', 'IC', 'CP','RT', 'CX','CS', 'TC', 'CI', 'TM', 'TI', 'AP']
        if(texte!='')
            for (let i = 0; i < codes.length; i++) {
                let suggestionCourante = codes[i];
                if (suggestionCourante.startsWith(texte)) {
                    suggestion = suggestionCourante;
                    break;
                }
            }
        if (texte.length > 2) {
            document.getElementById(elementID).textContent = texte.slice(0, 2);
        }
        if (suggestion !== '') {
            document.getElementById(elementID).textContent = suggestion;
        }
    },
    handleClick(idPrev, user){
        
        if(this.user.role==5){
            var result = confirm("Are you sure?")
            if(result)
                this.annulerValidation(idPrev, user)
            
        }
       
    },
    setRequired(){

        let scenario = document.getElementById("textAreaExample2");
        let optionSelect = document.getElementById("selectExample");
        let inputImage = document.getElementById("inputGroupFile");
        //let imageInput = document.getElementById("displayScenario");
        //let captureDecran = document.getElementById("captureEcran");

        if (optionSelect.value == 1){
             scenario.required = true;
             inputImage.required = true;
            //imageInput.style.display = ''
            //captureDecran.style.display = ''
        }

        // else {
        //     imageInput.style.display = 'none';
        //     captureDecran.style.display = 'none';
        // }
    },


sendEmail(e) {
try {
emailjs.sendForm('service_vwxwj8h', 'template_ev15f1m', e.target,
'CqM0qDSneePqm4eNb', {
  name: this.name,
  email: this.email,
  message: this.message,
  image: this.image
})


} catch(error) {
  console.log({error})
}
// Reset form field
this.name = ''
this.email = ''
this.message = ''
},

    annulerValidation(objPrev, user){
        
        const timetracker = this.tabIsReady.filter(element=>{
            //console.log(element)
            return element.user_id==user._id
        })
        axios.put(data.baseUrl + "timetracker/update/", {"_id": objPrev,"isValid": "NO", "valid_user_id" :this.user._id, "valid_date" : Date.now})
        .then(response => {
            console.log("firts update : ", response)
            if(response.data){
                axios.put(data.baseUrl + "timetracker-collab/update/", {"_id": timetracker[0]._id,"isValid" : 'NO', "valid_user_id" :this.user._id, "valid_date" : Date.now})
                    .then(response => {
                        console.log(response) 
                })
                //document.getElementById("btn_"+idTimetracker).disabled
                toast("Validation annulée")
             }
            
        })
        .catch(error => console.log(error)) 
        this.$router.go()
        
    },
    getTimetrackerPrevisionnel(){
        
        if(this.user.role==5 || this.user.role==4 ){
             axios.post(data.baseUrl + "timetracker/get-details/", {'year' : this.year, "month": this.month})
                .then(response => {
                    this.objectPrevisionnelle = response.data
                    if(this.objectPrevisionnelle.length>this.rowsPerPageAcc)
                        this.rowsPerPageAcc = Math.ceil(this.objectPrevisionnelle.length/2)
                })
                .catch(error => console.log(error))
        }
        else{
            
             axios.post(data.baseUrl + "timetracker/get-details/", {'year' : this.year, "month": this.month, "validator_id" : this.user._id})
                .then(response => {
                    this.objectPrevisionnelle = response.data
                    if(this.objectPrevisionnelle.length>this.rowsPerPageAcc)
                        this.rowsPerPageAcc = Math.ceil(this.objectPrevisionnelle.length/2)
                })
                .catch(error => console.log(error))
        }
        
    },
    countTimetrackerToValid(){
        var tt = this.tabIsReady.filter((obj)=>{
                        return obj.isValid == 'NO'
        })
        return tt.length
        
    },
    codeforMonth(){
        let tab = []
        for(let i=1; i<=this.daysInMonth; i++){
            if(!this.isWeekend(i, this.month)){
                tab.push(this.code)
            }
            else tab.push(null)
        }
        return tab
    },
    isValid(value){
        if(value=='YES')
            return true
        else return false
    },
    codeforMonth2(user){
        const userPrev =  this.objectPrevisionnelle.filter(item=> {
            return item.collaborateur._id== user._id
        })
        return userPrev.day_code
    },
   
    getDay(nbrjours){
        const days=[]
        for(let i=1; i<=nbrjours; i++){
            const date = new Date(this.year+'-'+this.month+'-'+ i)
            const dayNum = date.getDay()
            switch ( dayNum ) {
                case 0:
                    days.push('D')
                    break;
                case 1:
                    days.push('L')
                    break;
                case 2:
                    days.push('M')
                    break;
                case 3:
                    days.push('M')
                    break;
                case 4:
                    days.push('J')
                    break;
                case 5:
                    days.push('V')
                    break;
                default: 
                    days.push('S')
                    break;
            }
        }
        
        return days
    },
    getDayTimetracker(date){
        const dayNum = date.getDay()
        let dayOfDate = ''
        switch ( dayNum ) {
            case 0:
                dayOfDate='D'
                break;
            case 1:
                dayOfDate='L'
                break;
            case 2:
                dayOfDate='M'
                break;
            case 3:
                dayOfDate='M'
                break;
            case 4:
                dayOfDate='J'
                break;
            case 5:
                dayOfDate='V'
                break;
            default: 
                dayOfDate='S'
                break;
        }
        return dayOfDate
    },
    getWeek(month)
    {
        let tabWeek = []
        var oneJan = new Date(this.year,0,1);
        for(let i=1; i<=this.daysInMonth; i++){
            const laDate = new Date(this.year, month-1, i)
            const week = Math.ceil((((laDate.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);
            tabWeek.push(week)
        }
        return tabWeek
    },
   isWeekend(day, month) {
        
        const date = new Date(this.year+'-'+month+'-'+ day)
        let isWk = false
        // let isFerier = false
        //let dateString = moment(date).format('DD-MM-YYYY');
        // let country='FRA'
        // if(this.user.companyDetails && this.user.companyDetails.length!=0)
        //  country = this.user.companyDetails[0].countryDetails.countryAlpha3
        // let holidays = data.jourFerieFrance
        // if(country=='MUS') 
        //     holidays = data.jourFerieMaurice
        // holidays.then(date=>{
        //     this.jourFerie = date    
        //     this.compteurJO = this.countJourOuvre()
        // })
        // isFerier = this.jourFerie.includes(dateString)
        
        if(date.getDay() == 6 || date.getDay() == 0 )
            isWk = true
        
        return isWk
    },
   
    countJourOuvre(){
        for(let day = 1; day<=this.daysInMonth; day++){
            const date = new Date(this.year+'-'+this.month+'-'+ day)
            if(date.getDay() == 6 || date.getDay() == 0)
                console.log("WEEK-END")
            else this.compteurJourOuvre++
        }   
    },

    
    onchangeMonth(){
        this.days=[]
        this.compteurJourOuvre = 0
        this.daysInMonth = new Date(this.year, this.month, 0).getDate();
        this.getWeek(this.month)
        this.days = this.getDay(this.daysInMonth)
        this.countJourOuvre()
        this.tabIsReady=[]
        this.objectPrevisionnelle = []
        this.getTimetrackerPrevisionnel()
        axios.post(data.baseUrl+"timetracker-collab/", {"rtt_id":this.user._id, "month" : this.months[this.month-1], "year" : parseInt(this.year), "state": "A_VALIDER"})
        .then(response=> {
                this.tabIsReady = response.data  
        }).catch(error=>console.log(error))
       
        this.addCookie()
    },
    onchangeYear(){
        //this.onchangeMonth()
        this.compteurJourOuvre = 0
        this.getWeek(this.month)
        this.code_for_month = this.codeforMonth()
        axios.post(data.baseUrl + "timetracker/get-month-list-by-year/", {"year" : this.year})
        .then(response => {
            console.log("MONTH DISPO : " + response.data)
            this.monthsDispo = response.data
        })
        .catch(error => console.log(error))
        this.countJourOuvre()
        //this.onchangeMonth()
    },
    onChangeFile2(event){
        let xlsxfile = event.target.files;
        if(xlsxfile.length>0){
        this.readFile = true

        for(let i=0; i<xlsxfile.length; i++){
            readXlsxFile(xlsxfile[i]).then((rows) => {
                console.log("rows:", rows)
                let dayss = [], dayssFormatted = []
                let daysTimetracker = []
                for(let i = 6; i<rows[7].length; i++){
                    dayss.push(rows[7][i])
                    dayssFormatted.push(this.format_date2(rows[7][i]))
                    daysTimetracker.push(this.getDayTimetracker(rows[7][i]))
                }
                let monthYear = ''
                rows[5].forEach(letter=>{
                    if(letter!=null)
                    monthYear= monthYear+letter
                })
                let year_tm = monthYear.substring(monthYear.length-4, monthYear.length)
                
                let month_tm =  monthYear.substring(0, monthYear.length-4)
                console.log("MONTH", month_tm)
                let clienteleSite = [], clienteleTeletravail = [], clienteleDeplacement = [], clientelePresentation = [],  clientele=[]
                
                for(let i=6; i<rows[19].length; i++){
                    if(rows[19][i]==null){
                        clienteleSite.push(0)
                    }else  clienteleSite.push(rows[19][i])
                }
                    
                for(let i=6; i<rows[20].length; i++){
                    if(rows[20][i]==null)
                            clienteleTeletravail.push(0)
                    else clienteleTeletravail.push(rows[20][i])
                }
                
                for(let i=6; i<rows[21].length; i++){
                    if(rows[21][i]==null)
                        clienteleDeplacement.push(0)
                    else clienteleDeplacement.push(rows[21][i])
                }
                    
                for(let i=6; i<rows[22].length; i++){
                    if(rows[22][i]==null)
                        clientelePresentation.push(rows[22][i])
                    else clientelePresentation.push(rows[22][i])
                }
                for(let i=0; i<clienteleSite.length; i++){
                    clientele.push(clienteleSite[i]+clienteleTeletravail[i]+ clienteleDeplacement[i]+ clientelePresentation[i])
                }

                let absence = [], aConge = [], aRtt = [], aMaladie = [], aFormation= [], aCongeExc = [], acongeSSolde = []
                for(let i=6; i<rows[12].length; i++){
                    if(rows[12][i]==null){
                        aConge.push(0)
                    }else  aConge.push(rows[12][i])
                }
                for(let i=6; i<rows[13].length; i++){
                    if(rows[13][i]==null){
                        aRtt.push(0)
                    }else  aRtt.push(rows[13][i])
                }
                for(let i=6; i<rows[14].length; i++){
                    if(rows[14][i]==null){
                        aMaladie.push(0)
                    }else  aMaladie.push(rows[14][i])
                }
                for(let i=6; i<rows[15].length; i++){
                    if(rows[15][i]==null){
                        aFormation.push(0)
                    }else  aFormation.push(rows[15][i])
                }
                for(let i=6; i<rows[16].length; i++){
                    if(rows[16][i]==null){
                        aCongeExc.push(0)
                    }else  aCongeExc.push(rows[16][i])
                }
                for(let i=6; i<rows[17].length; i++){
                    if(rows[17][i]==null){
                        acongeSSolde.push(0)
                    }else  acongeSSolde.push(rows[17][i])
                }
                for(let i=0; i<aRtt.length; i++){
                    absence.push(aConge[i]+aRtt[i]+ aMaladie[i]+ aFormation[i]+aCongeExc[i], acongeSSolde[i])
                }
                
                let pBulma = [], pBulmaTeletravail = [], pInterne = [] , projet = []//, //internes = []
                for(let i=6; i<rows[24].length; i++){
                    if(rows[24][i]==null){
                        pBulma.push(0)
                    }else  pBulma.push(rows[24][i])
                }
                for(let i=6; i<rows[25].length; i++){
                    if(rows[25][i]==null){
                        pBulmaTeletravail.push(0)
                    }else  pBulmaTeletravail.push(rows[25][i])
                }
                for(let i=6; i<rows[26].length; i++){
                    if(rows[26][i]==null){
                        pInterne.push(0)
                    }else  pInterne.push(rows[26][i])
                }
                for(let i= 0; i<pBulma.length; i++){
                    projet.push(pBulma[i]+pBulmaTeletravail[i]+pInterne[i] )
                }
                console.log(projet)
                
                let intercontrat1 = [], intercontrat2 = [], intercontrat=[]
                for(let i=6; i<rows[29].length; i++){
                    if(rows[29][i]==null){
                        intercontrat1.push(0)
                    }else  intercontrat1.push(rows[29][i])
                }
                
                for(let i=6; i<rows[30].length; i++){
                    if(rows[30][i]==null){
                        intercontrat2.push(0)
                    }else  intercontrat2.push(rows[30][i])
                }
                
                for(let i=0; i<intercontrat1.length; i++){
                    intercontrat.push(intercontrat1[i]+intercontrat2[i])
                }

                var prev = this.objectPrevisionnelle.filter((obj)=>{
                        return obj.user.first_name.toLowerCase().includes(rows[2][6].toLowerCase()) && obj.user.last_name.toLowerCase().includes(rows[2][11].toLowerCase())
                })
                var timetracker = {
                    "name" : rows[2][11], 
                    "firstname" : rows[2][6], 
                    "month_year" : monthYear,
                    "year" : year_tm,
                    "month" : month_tm,
                    "jours_ouvrable" : rows[7][5],
                    "daysTimetracker" : daysTimetracker,
                    "days" : dayss,
                    "daysFormatted" : dayssFormatted,
                    "jours_saisi" : rows[8][5], 
                    "missed_day" : rows[11][2],
                    "clientele" : rows[18][2], 

                    "daysSurSite" : rows[19][5],
                    "daysTeletravail" : rows[20][5],
                    "daysDeplacement" : rows[21][5],
                    "daysPresentation" : rows[22][5],
                
                    "clienteleday" : clientele,

                    "clienteleSite" : clienteleSite, 
                    "clienteleTeletravail" : clienteleTeletravail, 
                    "clienteleDeplacement" : clienteleDeplacement, 
                    "clientelePresentation" : clientelePresentation, 

                    "absence" : absence, 
                    "aConge" : aConge,
                    "aRtt" : aRtt,
                    "aMaladie" : aMaladie,
                    "aFormation" : aFormation,
                    "aCongeExc" : aCongeExc, 
                    "aCongeSSolde" : acongeSSolde,

                    "interne_day" : rows[23][2],
                    "projet" : projet,
                    "intercontrat" : intercontrat,

                    "previsionnelle" : prev[0]

                }
                this.timetrackers.push(timetracker)
                console.log("TIMETRACKER :::: " , this.timetrackers)
                let erreur = this.compare(timetracker,prev[0])
                this.error_message.push(erreur)
                
                console.log("ERROR MESSAGE : ", this.error_message)
            })
           
            }
            console.log(this.timetrackers.length)
            if(this.timetrackers.length>0)
            {
                this.saveTimetrackers()
            }
        }
        
    },
    saveTimetrackers(){
        axios.post(data.baseUrl + "timetracker-collab/add/",JSON.parse(JSON.stringify(this.timetrackers)))
            .then(response => {
                console.log("natao ito", response.data) 
            }).catch(error => console.log(error))
    },
    changeCodePrev(index, previsionnelle, timetracker){
        console.log("CHANGE CODE dans l'index : ", index)
        const codeAbbrev = []
        this.etats.forEach(item=>{
            codeAbbrev.push(item.code_abbrev)
        })
        
        const user_id = previsionnelle.user._id
        
        const id = "td"+index+"_"+user_id
        
        const year = this.year
        const month = this.month
        //const code_abbrev = document.getElementById(id).textContent
         if(codeAbbrev.includes(document.getElementById(id).textContent)){
            document.getElementById(id).className = document.getElementById(id).textContent.toUpperCase()
            const code_abbrev = document.getElementById(id).textContent.toUpperCase()
            var payload = {"user_id" : user_id, "year" : year, "month" : month, "day": index+1, "code_abbrev" : code_abbrev}
            console.log("PAYLOAD ::", payload)
            axios.put(data.baseUrl + "timetracker_by_day/update-by-code-abbrev/", payload)
            .then(response => {
                
                console.log(response.data)
                let error  = this.compare(timetracker, previsionnelle)
                toast.error(error)
            })
            .catch(error => console.log(error))
             
        }
        else{
            if(document.getElementById(id).textContent==""){
                document.getElementById(id).className = "null"
                //const code_abbrev = document.getElementById(id).textContent
                axios.put(data.baseUrl + "timetracker_by_day/update-by-code-abbrev/", {"user_id" : user_id, "year" : year, "month" : month, "day": index+1, "code_abbrev" : ""})
                .then(response => {
                    
                    console.log(response.data)
                })
                .catch(error => console.log(error))
            }
            else  document.getElementById(id).textContent = "JT"
        }
        
    },
    changeCodePrev2(index,indexPrev,previsionnelle ){
        console.log("ATO")
        const id = "td"+indexPrev+'-'+index
        const codeAbbrev = []
        this.etats.forEach(item=>{
            codeAbbrev.push(item.code_abbrev)
        })
        const user_id = previsionnelle.user._id
        const year = this.year
        const month = this.month
        const code_abbrev = document.getElementById(id).textContent.toUpperCase()
        console.log(code_abbrev)
        if(codeAbbrev.includes(document.getElementById(id).textContent.toUpperCase())){
            console.log("CHANGE CODE dans l'index : ", index, id)
            document.getElementById(id).className = document.getElementById(id).textContent.toUpperCase()
            var payload = {"user_id" : user_id, "year" : year, "month" : month, "day": index+1, "code_abbrev" : code_abbrev}
            
            axios.put(data.baseUrl + "timetracker_by_day/update-by-code-abbrev/", payload)
            .then(response => {
                console.log(response.data)
            })
            .catch(error => console.log(error))
             
        }
        
        else{
            if(document.getElementById(id).textContent==""){
                document.getElementById(id).className = "null"
                
                axios.put(data.baseUrl + "timetracker_by_day/update-by-code-abbrev/", {"user_id" : user_id, "year" : year, "month" : month, "day": index+1, "code_abbrev" : ""})
                .then(response => {
                    console.log(response.data)
                })
                .catch(error => console.log(error))
            }
            else {
                document.getElementById(id).textContent = "JT"
            }
        }
        
    },
    updateCode(previsionnelle, timetracker){
        const user_id = previsionnelle.user._id
        const start = document.getElementById("datestart_"+user_id).value
        const end = document.getElementById("dateend_"+user_id).value
        const code = document.getElementById("codeDate_"+user_id).value
        let sel = document.getElementById("codeDate_"+user_id)
        sel.options[sel.selectedIndex].text;
        const codeText = sel.options[sel.selectedIndex].text;
        const indexUser = this.objectPrevisionnelle.indexOf(previsionnelle)
        console.log("index user :", indexUser, end, start)
        console.log("USER TO CHANGE  :: ",  previsionnelle.user)
        
        const year = this.year
        const month = this.month
        
        for(let i= parseInt(start)-1; i<parseInt(end); i++){
            if(!this.isWeekend(i+1, this.month)){
                const index = "td"+i+"_"+user_id
                document.getElementById(index).textContent = codeText
                document.getElementById(index).className = codeText
               
            }
            
        }
        var payload = {"user_id" : user_id, "year" : year, "month" : month, "start_day": start, "end_day": end, "code_id" : code}
        console.log(payload)
        axios.put(data.baseUrl + "timetracker_by_day/update-btwn-two-dates/", {"user_id" : user_id, "year" : year, "month" : month, "start_day": start, "end_day": end, "code_id" : code})
        .then(response => {
            
            console.log(response.data)
            let error  = this.compare(timetracker, previsionnelle)
            console.log(error)
        })
        .catch(error => console.log(error))
    },
    filterPrevUser(){
        
      if(this.search!="") {
            this.startRowAcc=0
            return this.objectPrevisionnelle.filter(item => {
                return (item.user.first_name.toLowerCase().includes(this.search.toLowerCase()) || item.user.last_name.toLowerCase().includes(this.search.toLowerCase()) )
          
        })
      }
      if(this.checkPret=='YES'){
          this.startRowAcc=0
            return this.objectPrevisionnelle.filter(item => {
                console.log(item)
                return (this.isTimetrackerReady(item.user._id))
            })
      }
      if(this.checkValid=='YES'){
          this.startRowAcc=0
            return this.objectPrevisionnelle.filter(item => {
                return (item.isValid=='YES')
            })
      }
      else return  this.objectPrevisionnelle
    }, 
    isTimetrackerReady(idUser){
        let res = false

        //timetrackerPRev.tt_collab_id!='' -->
        // var obj =  this.tabIsReady.filter(item => {
        //         return (item.user_id==idUser)
        // })
        // if(obj.length==0){
        //     res = false
        // }

        var obj =  this.objectPrevisionnelle.filter(item => {
                return (item.user_id==idUser)
        })

        if(obj.length!=0 && obj[0].tt_collab_id!=''){
            res = true
        }
        return res
          
    },
    
    movePage(amount) {
        console.log("MOVE PAGE")
        var newStartRows = this.startRowAcc + (amount * this.rowsPerPageAcc);
        if (newStartRows >= 0 && newStartRows < this.objectPrevisionnelle.length) {
          this.startRowAcc = newStartRows;
        }
        
    },
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD')
          }
    },
    format_date2(value){
         if (value) {
           return moment(String(value)).format('yyyy-MM-DD')
          }
    },
    replaceWithBr(message) {
        return message.replace(/\n/g, "<br />")
    }, 
    valid(objPrev){
        var payload = {
                "timetracker_id": objPrev._id,
                "timetracker_collab_id": objPrev.tt_collab_id,
                "timetracker_user_id": objPrev.user_id,
                "user_id": this.user._id,
                "month": this.month,
                "year": this.year
            }
            //console.log(payload)
            axios.post(data.baseUrl + "timetracker/validate-timetracker/", payload)
                .then(response => {
                    //console.log(response.data) 
                    if (!JSON.stringify(response.data).includes("Error"))
                        toast("Timetracker validé")
                    else {
                        toast.error(JSON.stringify(response.data))
                    }

                    setTimeout(() => this.$router.push('/'), 1500)
                })
        
    },
    saveNote(previsionnelle){
        const idPrev = previsionnelle._id
        console.log("SAVE NOTE ", idPrev)
        const note = document.getElementById("note_"+idPrev).value
        const obj = this.objectPrevisionnelle.filter((item)=>{
            console.log("item", item)
            return item._id ==previsionnelle._id
        })
        console.log('note : ' ,note, "PREV : ", previsionnelle.note)
        if(note!=obj.note){
            axios.put(data.baseUrl + "timetracker/update/", {"_id":idPrev,"note": note})
            .then(response => {
                console.log(response.data)
                toast.success("Note enregistrée")
            })
            .catch(error => console.log(error))
        }
    },

    compare(timetracker, previsionnel){
        
                console.log(timetracker, previsionnel)
				let erreur = ''
                
                const user = this.collaborateurs.filter(item => {
                    return item.first_name.toLowerCase().includes(timetracker.firstname.toLowerCase()) && item.last_name.toLowerCase().includes(timetracker.name.toLowerCase())
                })
                
                if(user.length==0){
                    this.error = true
                    //document.getElementById("message_error").innerHTML+= 
                    erreur=erreur + "Utilisateur non trouvé \n "
                }
                
                if(timetracker.month_year.toLowerCase()!=(this.months[previsionnel.month-1]+''+ previsionnel.year).toLowerCase()){
                    this.error = true
                    //document.getElementById("message_error").innerHTML+= "<li>Mois et année not the same </li>" +"<br/>"
                    erreur= erreur + "Erreur de fichier : Mois incorrect \n "
                }
				let clientele = timetracker.clienteleday, projet = timetracker.projet, aMaladie = timetracker.aMaladie, aConge = timetracker.aConge
                let aRtt = timetracker.aRtt,aCongeExc = timetracker.aCongeExc, acongeSSolde = timetracker.aCongeSSolde , intercontrat = timetracker.intercontrat
               
                if(erreur=='' && previsionnel){
                    for(let i=0; i< clientele.length; i++){
                        let jours = i+1
                        if(clientele[i]==1 || projet[i]==1){
                            if(previsionnel.tt_by_day[i].code_abbrev!="JT"){
                            
                                this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1]+" " + this.year +" ==> Jour travaillé : JT \n"
                            }
                        }
                        else if(clientele[i]==0.5 || projet[i]==0.5){
                            if(aConge[i]==0.5 && previsionnel.tt_by_day[i].code_abbrev!="TC"){
                                this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> 1/2 Travail / Congé payé : TC \n"
                            }
                            else if(aMaladie[i]==0.5 && previsionnel.tt_by_day[i].code_abbrev!="TM"){
                                this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> 1/2 Travail / Maladie : TM \n" 
                            }
                            else if(aRtt[i]==0.5 && previsionnel.tt_by_day[i].code_abbrev!="TR"){
                                this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> 1/2 Travail / RTT : TR \n"
                            }
                            else if(intercontrat[i]==0.5 && previsionnel.tt_by_day[i].code_abbrev=="TI"){
                                this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==>1/2 Travail / Intercontrat : TI \n";
                            }
                            //else if(aFormation[i]==0.5 && prev[0].day_code[i]=="??"){
                              //  compt++;
                            //}
                        }else if(intercontrat[i]==0.5){
                            if(aRtt[i]==0.5 && previsionnel.tt_by_day[i].code_abbrev!="IR"){
                                 this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> 1/2 Intercontrat / RTT : IR \n"
                            }
                        }
                        else{
                            if(aMaladie[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="MA"){
                                this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> Maladie : MA \n"
                            }else if(aConge[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="CP"){
                                this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> Congés : CP \n"
                            }else if(aRtt[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="RT"){
                               this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> RTT : RT \n"
                            }
                            else if(aCongeExc[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="CX"){
                                this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> Congés exception : CX \n"
                            }else if(acongeSSolde[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="CS"){
                                this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> Congés sans solde : CS \n"
                            }
                            else if(intercontrat[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="IC"){
                                this.error = true
                                erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> Intercontrat : IC \n"
                            }
                            
                        }
                    }
                }
                return erreur	
    },
    
    goToListTimetrackerReady(){
        this.$router.push({name : 'TimetrackerList', query:{ month:this.month, year : this.year}})
    },
    goToListTimetrackerValid(){
        //Affiché avec l'user qui a validé
        this.$router.push({name : 'TimetrackerValidList', query:{ month:this.month, year : this.year}})
    },
    printDocument() {
        
          

          var divContents = document.getElementById("timetrackerToExport").innerHTML;
            var a = window.open('', '');
            a.document.write('<html>');
            a.document.write('<link ref="https://use.fontawesome.com/releases/v5.7.2/css/all.css"/>');
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">')
        
            a.document.write('<body  > <h1>Timetracker prévisionnel <br> <div class="row" style="zoom: 60%">' );
            a.document.write(divContents);
            a.document.write('</div></body></html>');
            a.document.close();
            a.print();
            /*var element = "<html> "
            element+= '<link ref="https://use.fontawesome.com/releases/v5.7.2/css/all.css"/>'
            element +='<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">'
        
            element+='<body > '
            element+=divContents
            element+='</body></html>'

            var html = htmlToPdfmake(element);
        
          const documentDefinition = { content: html,pageSize:"A3", pageOrientation: 'landscape' };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          pdfMake.createPdf(documentDefinition).open();*/
        
    },
  }, 
  unmounted(){
    this.rttChecked=[]
    this.companieChecked=[], 
    this.objectPrevisionnelle = [],
    this.timetrackers = [], 
    this.error_message = [], 
    this.monthsDispo = [], 
    this.tabIsReady =[]
  }
}
</script>
<style>

.nav{
    background :hsl(136, 94%, 13%);
    height: 60px;
    /*align-content: center;*/
    color: white;
    z-index: 0;
    align-items: center;
    /*padding-right : 5px; */
    margin-bottom: 10px;
    width: 100%;
}
.fixed-width {
    table-layout: fixed; 
}
.table-container{
    height: 900px;
    overflow-x: auto;
    width: 100%;
}
.thead{
    background-color: #fff;
}
thead tr:nth-child(1) th .thead { position: sticky; top: 43px; z-index: 1; }
thead tr:nth-child(2) th .thead { position: sticky; top: 86px; z-index: 1; }
thead tr:nth-child(3) th .thead { position: sticky; top: 129px; z-index: 1; }
th.fixed-width {
    width: 150px; 
    /*word-wrap: break-word;*/
    white-space: nowrap; /* Empêche le retour à la ligne du texte */
    overflow: hidden; /* Masque le contenu dépassant la largeur */
    text-overflow: ellipsis; /* Affiche "..." pour le contenu coupé */
}
td.fixed-width {
    width: 150px; 
    /*word-wrap: break-word;*/
    white-space: nowrap; 
    overflow: hidden; /* Masque le contenu dépassant la largeur */
    text-overflow: ellipsis; /* Affiche "..." pour le contenu coupé */
}
tr.fixed-height{
   display: table-row;
   overflow: hidden; 
   
   height: 5px;
}
.form-inline h2{
    color: white; 
    margin-top: 3px;
  }
ul {
    padding-bottom: 20px
}

ul li a img {
    background: #66BB6A;
    top: 0;
    border: none;
    width: 20px
}
.main{
    transition: margin-left .5s;
    
}
td{
    text-align: center;
}

td.limit_2{
    max-height: 5px;
    white-space: nowrap; 
    max-width: 7px;
}
.carousel__item  {
     display: block;
     overflow: hidden;
     position: static;
     /*height: 500px !important;*/
     
 }
 .carousel__slide{
     width: 50%;
     margin-left : 20px
      
 }
 .null{
     background-color: grey;
     width:2px;
 }
 .codeType{
     width:2px!important;
     padding: 10px;
 }
 .MA{
     background-color: #E37D0C;
     color : white; 
     font-weight: bold;
     width:2px
 }
 .JT{
     background-color: #07534fbf;
     color : #fff; 
     width:2px;
     font-weight: bold;
 }
 .CP{
     background-color: #9fe0bc;
     color : white; 
     width:2px; 
     font-weight: bold;
 }
 .IC{
     background-color: #6C93A6;
     color : white;
     width:2px;
     font-weight: bold;
 }
 .TM{
     background-color: #794415;
     color : white;
     width:2px;
     font-weight: bold;
 }
.CX{
     background-color: #146791;
     color : white;
     width:2px;
     font-weight: bold;
 }
 .CS{
     background-color: #368eb9;
     color : white;
     width:2px;
     font-weight: bold;
 }
.RT{
     background-color: #10964c;
     color : white;
     width:2px;
     font-weight: bold;
 }
 .TI{
     background-color: #494e4bdc;
     color : white;
     width:2px;
     font-weight: bold;
 }
 .IR{
     background-color: #494e4b;
     color : white;
     width:2px;
     font-weight: bold;
 }
 .TR{
     background-color: #025a25;
     color : white;
     width:2px; 
     font-weight: bold;
 }
 .AP{
     background-color: #f7e43c;
     width:2px; 
     font-weight: bold;
 }
 .CI{
    background-color: #494e4bdc;
    font-weight: bold;
     width:2px
 }
 .TC{
     background-color: #1F3204;
     font-weight: bold;
     color : white;
 }

 .tabs-component-tabs {
     
  display: flex;              /* Transformation en flexbox */
    padding:0;     /* Suppression des marges internes */
    justify-content: center;
}
 .tabs-component-tab {
  padding: 15px ;
  border: 1px solid rgb(138 139 135 / 56%);
}
 
li{
    list-style-type: none;
}
li a{
    text-decoration: none;
}
.tabs-component-tab:hover{
    padding: 15px ;
  border: 1px solid rgb(138 139 135 / 56%);
  background-color:rgb(157, 189, 18);
  text-color: white
}
a :active{
    padding: 15px ;
  border: 1px solid rgb(138 139 135 / 56%);
  background-color:rgb(157, 189, 18);
  color: white
}
.pret{
    background-color: lightseagreen;
}
.nonPret{
    background-color: red;
}
.checkValid{
    background-color: green;
    color: white;
    padding : 5px
}
.checkPret{
    background-color: green;
    color: white; 
    padding : 5px
}
tr{
    max-height: 10px;
}
.disabled{
    
    pointer-events: none;
}
</style>