export function setCookie(nom, valeur, expirationJours) {
    var date = new Date();
    date.setTime(date.getTime() + (expirationJours * 24 * 60 * 60 * 1000));
    var expiration = "expires=" + date.toUTCString();
    document.cookie = nom + "=" + valeur + ";" + expiration + ";path=/";
}
export function getCookie(name) {
  const cookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith(`${name}=`));

  if (cookie) {
      return cookie.split('=')[1];
  }

  return null;
}