
import axios from 'axios'
import data from '@/data/data'

import { useToast } from "vue-toastification";
const toast = useToast()
export function getListTimetrackerPrev(month, year){
    axios.post(data.baseUrl + "timetracker/get-details/", {'year' : year, "month": month})
    .then(response => {
        console.log(response.data)
        return response.data
    })
    .catch(error => console.log(error))
}
export function getListTimetrackerPrevByValidator(month, year, user_validator_id){
    axios.post(data.baseUrl + "timetracker/get-details/", {'year' : year, "month": month, "validator_id" : user_validator_id})
    .then(response => {
        console.log(response.data)
        return response.data
    })
    .catch(error => console.log(error))
}
export function validateTimetracker (payload){
    axios.post(data.baseUrl + "timetracker/validate-timetracker/", payload)
    .then(response => {
        //console.log(response.data) 
        if (!JSON.stringify(response.data).includes("Error"))
            toast("Timetracker validé")
        else {
            toast.error(JSON.stringify(response.data))
        }

        setTimeout(() => this.$router.push('/'), 1500)
    })
}
export function annulerValidationTimetracker(payload){
    axios.post(data.baseUrl + "timetracker/remove-validate-timetracker/", payload)
    .then(response => {
        if (!JSON.stringify(response.data).includes("Error"))
            toast("Timetracker validé")
        else {
            toast.error(JSON.stringify(response.data))
        }
        setTimeout(() => this.$router.push('/'), 1500)
    })
}
export function updateCode(payload){
    axios.put(data.baseUrl + "timetracker_by_day/update-btwn-two-dates/", payload)
    .then(response => {
        console.log(response.data)
    })
    .catch(error => console.log(error))
}
export async function resetPassword(email){
    try {
        const payload = {
            "email": email
        }
        console.log(payload)
        const response = await axios.post(data.baseUrl + "users/reset-password-email/", payload)
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    
}