<template>
    <nav class="nav navbar navbar-expand-lg " style="background-color: #024013;">
            <button class="navbar-toggler" type="button" style="background-color : transparent" data-toggle="collapse"  data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <button class="btn" style="background-color : transparent" v-if="readFile"  @click="back()" ><i v-if="readFile"   class="fas fa-solid fa-arrow-left" style="color: white; width: 25px"></i></button>
            <a class="navbar-brand" style=" text-decoration: none; color: white" href="#" >VII ORIGIN GROUP Timetracker </a>

            <div class="collapse navbar-collapse navbar-nav navbar-right p-1" id="navbarTogglerDemo03">
                
                <div class="dropdown show" style=" margin-left : 80%;">
                    <a class=" dropdown-toggle" @click="showDropDown()" role="button" id="mail" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       {{user.email}}
                    </a>

                    <div id="myDropdown" class="dropdown-menu" aria-labelledby="logout">
                        <a class="dropdown-item" @click="logout()">Se déconnecter</a>
                    </div>
                </div>
            </div>
        </nav>

        
        <MyAccount v-if="popupTrigger" :togglePopup="togglePopup"  >
                <button type="button" class="btn  float-right" @click="togglePopup()" v-if="this.user.password!='Pwd1234'" style="color:black" >x</button>
                <h1 style="color:black">Modifier mot de passe</h1>
                <hr>
            </MyAccount>
</template>

<script>
import store from '../store'
import MyAccount from "./MyAccount.vue";
export default {
    
    props:{
            readFile : Boolean, 
            page : String
        
    },
    components:{MyAccount},
    data(){
        return {
            user : store.state.user,
            popupTrigger : false
            
        }
    },
    mounted(){
        
        try{
            if(this.user.id == -1)
                this.$router.push('/login')
            else{
                    
                    if(this.user.pwd_changed=="0"){
                        this.popupTrigger = true
                    }else{this.popupTrigger = false}
                    if(this.user.role<=1)
                        this.$router.push('/timetracker')
            } 
                
        }catch(error){
            console.error(error)
        }
    },
    methods: {
        showDropDown(){
            
            document.getElementById("myDropdown").classList.toggle("show");
        },
        logout(){
            localStorage.removeItem('user')
            this.$router.push('/login')
        },
        back(){
            //console.log("back ato am header")
            if(this.page=="accueil")this.$router.go("/")
            else this.$router.push("/")
        },
        togglePopup(){
            this.popupTrigger =!this.popupTrigger
        }
    }
}
</script>
<style>
#myDropdown{
 z-index:1020;
 position:'absolute';
}

</style>