import axios from "axios"
import moment from 'moment';
const url = "https://timetracker.groupviiorigin.com/timetracker/api/"
//const url = "http://localhost:4000/api/"
// const url = "http://149.202.159.49:33490/api/"
const data = {
    baseUrl : url,
    jourFerie : getFerie(), 
    jourFerieMaurice : getFerieMaurice(),
    jourFerieFrance : getFerieFrance()
}

async function getFerieMaurice(){
    
    let date = []
    await axios.post(url+"exemptional_dates/", {"countryAlpha3": "MUS"}).then(response=>{
        const dates = response.data
        for(let i=0; i<dates.length; i++)
            date.push( moment(dates[i].date).format('DD-MM-YYYY'))
        
    }).catch(error=>console.log(error))
    
    return date
}
async function getFerieFrance(){
    
    let date = []
    await axios.post(url+"exemptional_dates/", {"countryAlpha3": "FRA"}).then(response=>{
        const dates = response.data
        for(let i=0; i<dates.length; i++)
            date.push( moment(dates[i].date).format('DD-MM-YYYY'))
        
    }).catch(error=>console.log(error))
    
    return date
}
async function getFerie(){
    
    let date = []
    await axios.get(url+"exemptional_dates/").then(response=>{
        const dates = response.data
        for(let i=0; i<dates.length; i++)
            date.push( moment(dates[i].date).format('DD-MM-YYYY'))
        
    }).catch(error=>console.log(error))
    
    return date
}

  
export default data