import { createRouter, createWebHistory } from 'vue-router'
import TimetrackerPrev from '../components/TimetrackerPrev.vue'
import Timetracker from '../components/Timetracker.vue'
import TimetrackerCollab from '../components/TimetrackerCollab.vue'
import TimetrackerList from '../components/TimetrackerList.vue'
import Annuaire from "../components/Annuaire.vue"
import Login from "../components/Login.vue"

const routes = [
    {
      path: '/',
      name: 'Home',
      component: TimetrackerPrev
    },
    {
      path: '/timetracker',
      name: 'Timetracker',
      component: Timetracker
    },
    {
      path: '/timetrackerCollab',
      name: 'TimetrackerCollab',
      component: TimetrackerCollab
    },
    {
      path: '/timetrackerList',
      name: 'TimetrackerList',
      component: TimetrackerList
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/annuaire',
      name: 'Annuaire',
      component: Annuaire
    },

]
const router = createRouter({
    //mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
  
  export default router