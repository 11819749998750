<template>
    <div class="nav" v-show="showNav">
       <Header :readFile="true" />
           
     </div>
   <div   style="overflow-x:auto;">
       <Carousel>
           <Slide v-for="(timetracker, index) in timetrackers" :key="index">
               <div class="carousel__item" align="center">
                   <div id="content">
                       <div class="card p-3 " style="background-color: hwb(41deg 5% 11%)">
                           <div class="row justify-content-center align-items-center" >
                                    <h2 class="text-white">{{ timetracker.name}} {{timetracker.firstname}} - Mois {{months[month-1]}} {{year}} </h2>
          
                           </div>
                       </div> 
                              <div v-if='getPrevisionnel(timetracker.user_id)'>
                               <div class="alert alert-danger" id="error-alert" v-if="compare(timetracker,getPrevisionnel(timetracker.user_id))!=''">
                                   
                                   <p id="message_error"><strong>ERREUR ! </strong> </p>
                                   <div v-html="replaceWithBr(compare(timetracker,getPrevisionnel(timetracker.user_id)))"></div>
                                   <button class="btn btn-danger" v-show="user.role==5 && getPrevisionnel(timetracker.user_id).isValid=='YES'" @click="annulerValidation(getPrevisionnel(timetracker.user_id),timetracker._id)"> Annuler validation </button>
                               </div>
                               <div class="alert alert-success" id="success-alert" v-else>
                                   <p :id="'message_error_'+timetracker._id"><strong>Timetracker correct {{getPrevisionnel(timetracker.user_id).isValid=='YES'? 'et validé !' : '!'}} </strong> </p>
                                   <button class="btn btn-danger" v-show="user.role==5 && getPrevisionnel(timetracker.user_id).isValid=='YES'" @click="annulerValidation(getPrevisionnel(timetracker.user_id),timetracker._id)"> Annuler validation </button>
                               </div>
                               </div>
                               <div style="overflow-x: auto">
                                   <table class="table table-bordered" width='100%'>
                                       <thead>
                                           <th style="width: 150px">Nbre jours ouvrés</th>
                                           <th style="width: 50px">{{compteurJourOuvre}}</th>
                                           <th style="width: 1px" v-for="(item,index) in daysInMonth"  :key="index">
                                               <b>{{item}}</b>
                                           </th>
                                       </thead>
                                       <tbody>
                                           <tr>
                                               <td></td>
                                               <td></td>
                                               <td style="width: 1px" v-for="(item,index) in days"  :key="index">
                                                   {{item}}
                                               </td>
                                           </tr>
                                           <tr>
                                               <td>Nbre jours saisis</td>
                                               <td>{{timetracker.jours_saisi}}</td>
                                               <td :id="'tdb_'+index" style="width: 1px" :class="item==0? 'normal' : item<=1? 'correct' : 'plusDeUn' " v-for="(item,index) in timetracker.sumDays"  :key="index">
                                                   <span style="font-style : italic;">{{item!=0? item : ''}}</span>
                                                   <!--{{sumByDay(index)}}-->
                                               </td>
                                           </tr>
                                           <tr class="titleTab">
                                               <td>Absence </td>
                                               <td>{{timetracker.missed_day}}</td>
                                               <td :COLSPAN="daysInMonth"></td>
                                           </tr>
                                           <tr >
                                               <td></td>
                                               <td><span style="float: left">Congés</span> <span style="float: right">{{sumColumns[0]!=0?sumColumns[0] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"   v-for="(item,index) in timetracker.aConge"  :key="index" :id="'tdb_'+index+'_CP'" @blur="changeTdContent(index, 'CP')">
                                                   {{(item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                           <tr>
                                               <td></td>
                                               <td><span style="float: left">RTT</span> <span style="float: right">{{sumColumns[1]!=0?sumColumns[1] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  v-for="(item,index) in timetracker.aRtt"  :key="index" :id="'tdb_'+index+'_RT'" @blur="changeTdContent(index, 'RT')">
                                               {{(item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                           <tr  >
                                               <td></td>
                                               <td><span style="float: left">Arrêt maladie</span><span style="float: right">{{sumColumns[2]!=0?sumColumns[2] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  v-for="(item,index) in timetracker.aMaladie"  :key="index" :id="'tdb_'+index+'_MA'" @blur="changeTdContent(index, 'MA')">
                                                   {{( item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                           <tr >
                                               <td></td>
                                               <td><span style="float: left">Formation </span><span style="float: right">{{sumColumns[3]!=0? sumColumns[3] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  v-for="(item,index) in timetracker.aFormation"   :key="index" :id="'tdb_'+index+'_FO'" @blur="changeTdContent(index, 'FO')">
                                                   {{(item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                           <tr>
                                               <td></td>
                                               <td><span style="float: left">Congés exc.</span><span style="float: right">{{sumColumns[4]!=0? sumColumns[4] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" v-for="(item,index) in timetracker.aCongeExc"  :key="index" :id="'tdb_'+index+'_CX'" @blur="changeTdContent(index, 'CX')">
                                                   {{(item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                           <tr >
                                               <td></td>
                                               <td><span style="float: left">Congés s. solde</span><span style="float: right">{{sumColumns[5]!=0? sumColumns[5] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" v-for="(item,index) in timetracker.aCongeSSolde"  :key="index" :id="'tdb_'+index+'_CS'" @blur="changeTdContent(index, 'CS')">
                                                   {{(item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                           <tr class="titleTab">
                                               <td>Clientèle</td>
                                               <td>{{timetracker.clientele}}</td>
                                               <td :COLSPAN="daysInMonth"></td>
                                           </tr>
                                           <tr >
                                               <td></td>
                                               <td><span style="float: left">Sur site</span><span style="float: right">{{sumColumns[6]!=0? sumColumns[6] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  v-for="(item,index) in timetracker.clienteleSite"  :key="index" :id="'tdb_'+index+'_site'" @blur="changeTdContent(index, 'site')">
                                                   {{( item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                           <tr>
                                               <td></td>
                                               <td><span style="float: left">Téletravail</span> <span style="float: right">{{sumColumns[7]!=0? sumColumns[7] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" v-for="(item,index) in timetracker.clienteleTeletravail"  :key="index" :id="'tdb_'+index+'_teletravail'" @blur="changeTdContent(index, 'teletravail')">
                                               {{( item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                           <tr>
                                               <td></td>
                                               <td><span style="float: left">Déplacement</span><span style="float: right">{{sumColumns[8]!=0? sumColumns[8] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" v-for="(item,index) in timetracker.clienteleDeplacement"  :key="index" :id="'tdb_'+index+'_deplacement'" @blur="changeTdContent(index, 'deplacement')">
                                                   {{(item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                           <tr>
                                               <td></td>
                                               <td><span style="float: left">Présentation</span><span style="float: right">{{sumColumns[9]!=0? sumColumns[9] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" v-for="(item,index) in timetracker.clientelePresentation"  :key="index" :id="'tdb_'+index+'_presentation'" @blur="changeTdContent(index, 'presentation')">
                                               {{( item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                           <tr class="titleTab">
                                               <td >Interne</td>
                                               <td>{{timetracker.interne_day}}</td>
                                               <td :COLSPAN="daysInMonth"></td>
                                           </tr>
                                          <tr>
                                   <td rowspan="3" class="text-center">Projet</td>
                                   <td><span style="float: left"> Bulma </span><span style="float: right">{{sumColumns[10]!=0? sumColumns[10] : ''}}</span></td>
                                   <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  v-for="(item,index) in timetracker.projetBulma"  :key="index" :id="'tdb_'+index+'_projetBulma'" @blur="changeTdContent(index, 'projetBulma')">
                                           {{(item==1 || item==0.5)? item : ''}}
                                   </td>
                               </tr>
                               <tr>
                                   
                                   <td><span style="float: left"> Bulma télétravail</span><span style="float: right">{{sumColumns[11]!=0? sumColumns[11] : ''}}</span></td>
                                   <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  v-for="(item,index) in timetracker.projetBulmaTeletravail"  :key="index" :id="'tdb_'+index+'_projetBulmaTeletravail'" @blur="changeTdContent(index, 'projetBulmaTeletravail')">
                                           {{(item==1 || item==0.5)? item : ''}}
                                   </td>
                               </tr>
                               <tr>
                                   <td><span style="float: left">Projet interne</span><span style="float: right">{{sumColumns[12]!=0? sumColumns[12] : ''}}</span></td>
                                   <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  v-for="(item,index) in timetracker.projetInterne"  :key="index" :id="'tdb_'+index+'_projetInterne'" @blur="changeTdContent(index, 'projetInterne')">
                                           {{(item==1 || item==0.5)? item : ''}}
                                   </td>
                               </tr>
                               <tr>
                                   <td rowspan="2">Structure</td>
                                   <td><span style="float: left">Domremy</span><span style="float: right">{{sumColumns[13]!=0? sumColumns[13] : ''}}</span></td>
                                   <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  v-for="(item,index) in timetracker.structureDomremy"  :key="index" :id="'tdb_'+index+'_structureDomremy'" @blur="changeTdContent(index, 'structureDomremy')">
                                           {{(item==1 || item==0.5)? item : ''}}
                                   </td>
                               </tr>
                               <tr>
                                   <td><span style="float: left">Télétravail</span><span style="float: right">{{sumColumns[14]!=0? sumColumns[14] : ''}}</span></td>
                                   <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" v-for="(item,index) in timetracker.structureTeletravail"  :key="index" :id="'tdb_'+index+'_structureTeletravail'" @blur="changeTdContent(index, 'StructureTeletravail')">
                                           {{(item==1 || item==0.5)? item : ''}}
                                   </td>
                               </tr>
                               <tr>
                                   <td rowspan="2">Intercontrat</td>
                                   <td><span style="float: left">Domremy</span><span style="float: right">{{sumColumns[15]!=0? sumColumns[15] : ''}}</span></td>
                                   <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" v-for="(item,index) in timetracker.intercontratDomremy"  :key="index" :id="'tdb_'+index+'_ICDomremy'" @blur="changeTdContent(index, 'ICDomremy')">
                                       {{(item==1 || item==0.5)? item : ''}}
                                   </td>
                               </tr>
                               <tr>
                                   <td><span style="float: left">Télétravail</span><span style="float: right">{{sumColumns[15]!=0? sumColumns[15] : ''}}</span></td>
                                   <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" v-for="(item,index) in timetracker.intercontratTeletravail"  :key="index" :id="'tdb_'+index+'_ICTeletravail'" @blur="changeTdContent(index, 'ICTeletravail')">
                                       {{(item==1 || item==0.5)? item : ''}}
                                   </td>
                               </tr>

                                           <tr class="titleTab">
                                               <td>Supplémentaire</td>
                                               <td>{{timetracker.suppDay}}</td>
                                               <td :COLSPAN="daysInMonth"></td>
                                           </tr>
                                           <tr>
                                               <td>Entrer en heure</td>
                                               <td><span style="float: left">Trav supp(h) </span><span style="float: right">{{sumColumns[12]!=0? sumColumns[12] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" v-for="(item,index) in timetracker.supp"  :key="index" :id="'tdb_'+index+'_supp'" @blur="changeTdContent(index, 'supp')">
                                                       {{item==0? '' : item}}
                                               </td>
                                           </tr>
                                           <tr>
                                               <td></td>
                                               <td><span style="float: left">Astreinte</span><span style="float: right">{{sumColumns[13]!=0? sumColumns[13] : ''}}</span></td>
                                               <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" v-for="(item,index) in timetracker.astreinte"  :key="index" :id="'tdb_'+index+'_astreinte'" @blur="changeTdContent(index, 'astreinte')">
                                                   {{(item==1 || item==0.5)? item : ''}}
                                               </td>
                                           </tr>
                                       </tbody>
                                   </table>
                               </div>

                               
                               <fieldset class="border p-4">
                                   <legend class="w-auto pt-4">Timetracker prévisionnel</legend>
                                   <div style="overflow-x:auto;">
                               <table class="table table-bordered" width='100%'  v-if="getPrevisionnel(timetracker.user_id)">
                               
                               <thead>
                                   <th style="width:50px;">NOM</th>
                                   <th style="width:50px;">Prénom</th>
                                   
                                   <th style="width:2px; text-align:center" v-for='index in daysInMonth' :key="index">
                                           <b>{{index}}</b>
                                           <b hidden>{{index+1}}</b>
                                   </th>
                                   
                               </thead>
                               <tbody>
                               
                                   
                                   <tr>
                                       <td>{{getPrevisionnel(timetracker.user_id).user.last_name}}</td>
                                       <td>{{getPrevisionnel(timetracker.user_id).user.first_name}}</td>
                                       
                                       <td :contenteditable="getPrevisionnel(timetracker.user_id).isValid=='NO'? true : false" :class="code.code_abbrev? code.code_abbrev : 'null'"  @blur="changeCodePrev(index,getPrevisionnel(timetracker.user_id), timetracker )" :id="'td'+index+'_'+getPrevisionnel(timetracker.user_id).user_id" style="width:2px;text-transform:uppercase" v-for='(code, index) in getPrevisionnel(timetracker.user_id).tt_by_day'  :key="index">
                                           <strong>{{code.code_abbrev}} </strong> 
                                       </td>
                                   </tr>
                                   </tbody>

                               </table>
                               
                               </div>
                               <button :disabled="timetracker.isValid=='YES'" style="background-color: #a57e57" :id='"btn_"+timetracker._id'  class="btn btn-block text-white" @click="valid(getPrevisionnel(timetracker.user_id), timetracker._id)" >Valider</button>
                               </fieldset>

                                
                   </div>
               </div>
           </Slide>

           <template #addons="{ slidesCount }">
               <Navigation v-if="slidesCount > 1" />
               <Pagination v-if="slidesCount > 1" />
           </template>
       </Carousel>
   </div>
</template>

<script>
import moment from 'moment'

import store from '@/store'
import Header from '@/components/Header.vue';
import { useToast } from "vue-toastification";
const toast = useToast()

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import axios from 'axios'
import data from '@/data/data'
export default {
 name: 'TimetrackerList',
 components: {
     Header, Carousel, Slide, Pagination, Navigation
 },
 props:{
       showNav : {
           type : Boolean, 
           default : true
       } 
   },
 created(){
       this.month = this.$route.query.month
       this.year = this.$route.query.year
       console.log(this.month, this.year)
       this.years = [this.year-2, this.year-1, this.year]
       this.daysInMonth =  new Date(this.year, this.month, 0).getDate();
       this.days = this.getDay(this.daysInMonth)
       console.log(this.days)
       this.weeks = this.getWeek(this.month)
       this.compteurJO = this.countJourOuvre()
 },
 mounted(){
   try{
      if(this.user.id == -1)
           this.$router.push('/login')
     
   }catch(error){
           console.error(error)
   }
   let payload = {'year' : parseInt(this.year), "month": parseInt(this.month)}
   
   axios.post(data.baseUrl + "timetracker/get-details", payload)
   .then(response => {
       this.allPrevisionnels = response.data
   })
   
   
   axios.post(data.baseUrl+"timetracker-collab/", {"rtt_id":this.user._id, "month" : this.months[this.month-1], "year" : parseInt(this.year), "state": "A_VALIDER"})
   .then(response=> {
           this.timetrackers = response.data  
   }).catch(error=>console.log(error))
   axios.get(data.baseUrl + "codetypes")
       .then(response => {
           this.etats = response.data
       })
       .catch(error => console.log(error))
   
   
 },
 data(){
   return{
     user : store.state.user,
     
     search : '', 
     startRow: 0,
     rowsPerPage: 20,
     months :["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"],
     year : 0,
     month : 0,
     daysInMonth:0,
     days : [], 
     readFile : false, 
     years : [], 
     weeks : [],
     timetrackers : [],
     allPrevisionnels : [],
     previsionnels : [],
     timetracker : {},
     error : false, 
     error_message : [],
     compteurJourOuvre : 0,
     compteurJO : 0 , 
     sumDays :[],
     libelles: ["CP", "RT",  "MA", "FO", "CX", "CS", "site", "teletravail", "deplacement", "presentation", "projet", "IC", "supp", "astreinte"],
     sumColumns: [], 
     clienteleSite : [],
     clienteleTeletravail : [], 
     clienteleDeplacement : [], 
     clientelePresentation : [],
     clientele : [], 
     previsionnelles : []
     
   }
 },
 computed:{
   
 },
 methods: {
   codeforMonth(){
       let tab = []
       for(let i=1; i<=this.daysInMonth; i++){
           if(!this.isWeekend(i, this.month)){
               tab.push(this.code)
           }
           else tab.push(null)
       }
       return tab
   },
   
   getPrevisionnel(userId){
       let user_id = userId
       var prev = this.allPrevisionnels.filter((obj)=>{
           return obj.user_id==user_id
       })
       return prev[0]
   },
 
   getDay(nbrjours){
       const days=[]
       for(let i=1; i<=nbrjours; i++){
           const date = new Date(this.year+'-'+this.month+'-'+ i)
           const dayNum = date.getDay()
           switch ( dayNum ) {
               case 0:
                   days.push('D')
                   break;
               case 1:
                   days.push('L')
                   break;
               case 2:
                   days.push('M')
                   break;
               case 3:
                   days.push('M')
                   break;
               case 4:
                   days.push('J')
                   break;
               case 5:
                   days.push('V')
                   break;
               default: 
                   days.push('S')
                   break;
           }
       }
       
       return days
   },
   
   getWeek(month)
   {
       let tabWeek = []
        var oneJan = new Date(this.year,0,1);
        
       for(let i=1; i<=this.daysInMonth; i++){
           const laDate = new Date(this.year, month-1, i)
           const week = Math.ceil((((laDate.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);
           tabWeek.push(week)
       }
       return tabWeek
   },
   isWeekend(day, month) {
       const date = new Date(this.year+'-'+month+'-'+ day)
       let isWk = false
       if(date.getDay() == 6 || date.getDay() == 0)
           isWk = true
       return isWk
   },
   classIfWeekEnd(day,month){
       if(this.isWeekend(day, month))
           return "null1"
   },
   countJourOuvre(){
       for(let day = 1; day<=this.daysInMonth; day++){
           const date = new Date(this.year+'-'+this.month+'-'+ day)
           if(date.getDay() == 6 || date.getDay() == 0)
               console.log("WEEK-END")
           else this.compteurJourOuvre++
       }   
   },
   compare(timetracker, previsionnel){
               
               let erreur = ''
              
               let clientele = timetracker.clienteleday, projet = timetracker.projet, aMaladie = timetracker.aMaladie, aConge = timetracker.aConge
               let aRtt = timetracker.aRtt,aCongeExc = timetracker.aCongeExc, acongeSSolde = timetracker.aCongeSSolde , intercontrat = timetracker.intercontrat
               let structure = timetracker.structure
               
               if(erreur=='' && previsionnel){
                   for(let i=0; i< clientele.length; i++){
                       let jours = i+1
                       
                       if(clientele[i]==1 || projet[i]==1 || structure[i]==1){
                           if(previsionnel.tt_by_day[i].code_abbrev!="JT"){
                               this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1]+" " + this.year +" ==> Jour travaillé : JT \n"
                           }
                       }
                       else if(clientele[i]==0.5 || projet[i]==0.5 || structure[i]==0.5){
                           if(aConge[i]==0.5 && previsionnel.tt_by_day[i].code_abbrev!="TC"){
                               this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> 1/2 Travail / Congé payé : TC \n"
                           }
                           else if(aMaladie[i]==0.5 && previsionnel.tt_by_day[i].code_abbrev!="TM"){
                               this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> 1/2 Travail / Maladie : TM \n" 
                           }
                           else if(aRtt[i]==0.5 && previsionnel.tt_by_day[i].code_abbrev!="TR"){
                               this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> 1/2 Travail / RTT : TR \n"
                           }
                           else if(intercontrat[i]==0.5 && previsionnel.tt_by_day[i].code_abbrev=="TI"){
                               this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==>1/2 Travail / Intercontrat : TI \n";
                           }
                           //else if(aFormation[i]==0.5 && prev[0].day_code[i]=="??"){
                             //  compt++;
                           //}
                       }else if(intercontrat[i]==0.5){
                           if(aRtt[i]==0.5 && previsionnel.tt_by_day[i].code_abbrev!="IR"){
                                this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> 1/2 Intercontrat / RTT : IR \n"
                           }
                       }
                       else{
                           if(aMaladie[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="MA"){
                               this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> Maladie : MA \n"
                           }else if(aConge[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="CP"){
                               this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> Congés : CP \n"
                           }else if(aRtt[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="RT"){
                              this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> RTT : RT \n"
                           }
                           else if(aCongeExc[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="CX"){
                               this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> Congés exception : CX \n"
                           }else if(acongeSSolde[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="CS"){
                               this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> Congés sans solde : CS \n"
                           }
                           else if(intercontrat[i]==1 && previsionnel.tt_by_day[i].code_abbrev!="IC"){
                               this.error = true
                               erreur= erreur+ "Erreur de code  à la date de "+ jours +' '+ this.months[this.month-1] +" " + this.year +" ==> Intercontrat : IC \n"
                           }
                           
                       }
                   }
               }
               console.log(erreur)
               if(erreur=='')
                   document.getElementById("btn_"+timetracker._id).disabled
               return erreur	
   },
   format_date(value){
        if (value) {
          return moment(String(value)).format('DD')
         }
   },
   
   valid(objPrev, idTimetracker){
      
       console.log( {"_id": objPrev._id,"isValid": "YES"})
       axios.put(data.baseUrl + "timetracker/update/", {"_id": objPrev._id,"isValid": "YES", "valid_user_id" :this.user._id, "valid_date" : Date.now})
       .then(response => {
           console.log(response.data)
           axios.put(data.baseUrl + "timetracker-collab/update/", {"_id": idTimetracker,"isValid" : 'YES', "valid_user_id" :this.user._id, "valid_date" : Date.now})
                   .then(response => {
                       console.log(response.data) 
               })
           document.getElementById("btn_"+idTimetracker).disabled
           toast("Timetracker validé")
           
           this.$forceUpdate();
       })
       .catch(error => console.log(error))
       
   },
   annulerValidation(objPrev, idTimetracker){
      
       axios.put(data.baseUrl + "timetracker/update/", {"_id": objPrev._id,"isValid": "NO", "valid_user_id" :this.user._id, "valid_date" : Date.now})
       .then(response => {
           console.log("firts update : ", response.data)
           axios.put(data.baseUrl + "timetracker-collab/update/", {"_id": idTimetracker,"isValid" : 'NO', "valid_user_id" :this.user._id, "valid_date" : Date.now})
                   .then(response => {
                       console.log(response.data) 
               })
           toast("Validation annulée")
           
           this.$router.go()
       })
       .catch(error => console.log(error)) 
   },
    changeCodePrev(index, previsionnelle){
       console.log("CHANGE CODE dans l'index : ", index)
       const codeAbbrev = []
       this.etats.forEach(item=>{
           codeAbbrev.push(item.code_abbrev)
       })
       
       const user_id = previsionnelle.user._id
       
       const id = "td"+index+"_"+user_id
       
       const year = this.year
       const month = this.month
        if(codeAbbrev.includes(document.getElementById(id).textContent)){
           document.getElementById(id).className = document.getElementById(id).textContent
           const code_abbrev = document.getElementById(id).textContent
           var payload = {"user_id" : user_id, "year" : year, "month" : month, "day": index+1, "code_abbrev" : code_abbrev}
           console.log("PAYLOAD ::", payload)
           axios.put(data.baseUrl + "timetracker_by_day/update-by-code-abbrev/", payload)
           .then(response => {
               
               console.log(response.data)
               
           })
           .catch(error => console.log(error))
            
       }
       else{
           if(document.getElementById(id).textContent==""){
               document.getElementById(id).className = "null"
               axios.put(data.baseUrl + "timetracker_by_day/update-by-code-abbrev/", {"user_id" : user_id, "year" : year, "month" : month, "day": index+1, "code_abbrev" : ""})
               .then(response => {
                   
                   console.log(response.data)
               })
               .catch(error => console.log(error))
           }
           else  document.getElementById(id).textContent = "JT"
       }
       //this.$router.go()
   },
   format_date2(value){
        if (value) {
          return moment(String(value)).format('yyyy-MM-DD')
         }
   },
   replaceWithBr(message) {
       return message.replace(/\n/g, "<br />")
   },
   
 
   logout(){
           localStorage.removeItem('user')
           this.$router.push('/login')
       },
 }, 
 unmounted(){
   this.allPrevisionnels=[]
   this.timetrackers=[]
 }
}
</script>
