<template id="comment-form"> 
  <div class="container h-100 ">
      <div class =" row h-100 justify-content-center align-items-center  ">
          <div class=" card col-9 justify-content-center ">
              
            <div class="row justify-content-center align-items-center"> 
                <div class="col-3">
                <img style="width:100%"  src="@/assets/LogoVII.png" alt="LOGO" />
                </div>
                <div class="col-2">
                
                </div>
                <div class="col-7">
                    <h1 style="color::hsl(136, 94%, 13%)">Timetracker</h1>
                </div>
            </div>
              
              <hr/>
            <div >
                <div class="form-group">
                    <label for="email">Adresse e-mail</label>
                    <input 
                    v-model="email" 
                    v-bind:class="{'form-control':true, 'is-invalid' : !validEmail(email) && emailBlured}"
                    v-on:blur="emailBlured = true">
                    <div class="invalid-feedback">Une adresse courriel valide est requise</div>
                </div> 
                 
                <div class="form-group">
                    <label for="password">Mot de passe</label>
                    
                    <input class="form-control"
                    v-model="password" type="password" v-on:keyup.enter="login" >
                </div>             
                <a @click="togglePopup()">Mot de passe oublié?</a>
                <hr>
                
                <div class="form-group ">
                    <button type="submit" v-on:click.stop.prevent="login" class="btn btn-lg btn-success btn-block">Se connecter</button>
                </div>    
            </div>
            


        </div>
        
    </div>
    <ForgotPassword v-if="popupTrigger" :togglePopup="togglePopup"  >
        <div class="row pr-5 pl-5 pb-4">
            <h1 class="col-11" style="color:black">Mot de passe oublié</h1>
            <button type="button" class="btn col-1  float-right" @click="togglePopup()" style="color:black" >x</button>
            
        </div>
        <hr>
    </ForgotPassword>
  </div>
</template>


<script>
 import { useToast } from "vue-toastification";
import ForgotPassword from "./ForgotPassword.vue";

const toast = useToast()
export default {
    name: "Login",
    data: function () {
        return {
            email: "",
            emailBlured: false,
            valid: false,
            submitted: false,
            password: "",
            popupTrigger : false
        };
    },
    methods: {
        validate: function () {
            this.emailBlured = true;
            if (this.validEmail(this.email)) {
                this.valid = true;
            }
        },
        validEmail: function (email) {
            var re = /(.+)@(.+){2,}\.(.+){2,}/;
            return re.test(email.toLowerCase());
        },
        login: function () {
            this.validate();
            if (this.valid) {
                this.submitted = true;
                this.$store.dispatch("login", {
                    email: this.email.toLowerCase(),
                    password: this.password
                }).then(response => {
                    //console.log(response.data)
                    if (response.data.Error)
                        toast.error(response.data.Error);
                    else {
                        if (response.data[0].role > 1)
                            this.$router.push("/");
                        else
                            this.$router.push("/timetracker");
                    }
                }).catch(error => console.log(error));
            }
        },
        togglePopup(){
            this.popupTrigger =!this.popupTrigger
        }
    },
    components: { ForgotPassword }
}
</script>

<style>
.card{
    padding: 35px;
}
</style>