<template>
<div class="popup">
    <div class="popup-inner">
        <div class="row p-5">
            <div class="card p-4" style="width: 100%;">
              <slot/>
              <form @submit.prevent="callApiReset()">
                <div class="row">
                    <div class="form-group col-12">
                        <label class="form-label">Saisir votre adresse email</label>
                        <input class="form-control " type="email" v-model="email" name="mailAdress" required/>
                    </div>
                </div>
                <button type="submit"  class="btn btn-lg btn-primary float-right">Continuer</button>
                
              </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
 import {resetPassword} from '@/data/apis'
export default {
    name: 'forgotPassword',
    components: {
        //Header
    }, 
    data(){
        return{
            email: ''
        }
    },
    props:['togglePopup'], 
    methods:{
        callApiReset(){
            resetPassword(this.email.toLowerCase())
        }
    }
}
</script>