<template>
<div class="popup">
    <div class="popup-inner">
      
      <div class="p-5">
          <div class="pl-5 pr-5 ">
            <slot/>
              <form style="color: black;">
                      <div class="form-group">
                          <label >Mot de passe actuel</label>
                          <input v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(lastPassword)}" type="password" id="lastPwd" v-model="lastPassword"  required/>                                        
                          <!-- <div class="invalid-feedback">Mot de passe incorrect</div> -->
                      </div>
                      <div class="form-group">
                          <label>Nouveau mot de passe</label>
                          <input type="password" class="form-control" id="password" v-model="password" required>
                          
                      </div>
                      <div class="form-group">
                          <label>Confirmez votre mot de passe</label>
                          <input type="password" 
                              v-bind:class="{'form-control':true, 'is-invalid' : !validNewPassword(confirmPassword)}" id="confirmPassword" v-model="confirmPassword" required>
                              <div class="invalid-feedback">Non conforme avec votre nouveau mot de passe </div>
                      </div>
                      <br>
          
                      <button type="reset" class="btn btn-block btn-primary btn-lg" :disabled="!validPassword && !validNewPassword" @click="updateUser(); togglePopup()">Enregistrer</button> 
              </form>
          </div>
      </div>
    </div>
</div>

</template>
<script>
  import data from '@/data/data'
  import axios from 'axios';
  import store from '@/./store'
  import { useToast } from "vue-toastification";
  
  const toast = useToast()
    export default{
        name: 'my Account', 
        props:['togglePopup'],
        data(){
            return{
                user : store.state.user,
                password : '',
                lastPassword :'', 
                confirmPassword :''
            }
        }, 
        methods:{
            validPassword(lastPassword){
                return (lastPassword==this.user.password)
            }, 
            validNewPassword(confirmPassword){
                return this.password == confirmPassword
            },
            updateUser(){
                var updated_user = {
                    "_id" : this.user._id,
                    "password" : this.password ,
                    "updated_by" : this.user._id
                }
            
                axios.put(data.baseUrl+'users/update-user', updated_user)
                .then( response=> {
                    if(!JSON.stringify(response.data).includes('Error')){
                        toast.success("Votre mot de passe a été mis à jour ")
                        this.$store.dispatch("logAfterRegister",response.data).then(response => {
                            console.log(response)
                        })
                        setTimeout(() => {
                            this.$router.push('/')
                        }, 300);
                    }else{toast.error(JSON.stringify(response.data))}
                }).catch(error => toast.error(error))  
            } , 
        }
    }
</script>
<style>
.popup{
    position: fixed;
    top:0;
    
    left : 0;
    right: 0;
    bottom : 0;
    z-index: 99;
    background-color: rgba(0,0, 0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.popup-inner{
        background-color: white;
        width: 50%;
        padding: 32px;
        max-height: 100vh;
        overflow-y: auto;
    }
</style>