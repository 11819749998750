import axios from 'axios'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import data from '@/data/data'
let user = null
try{
  user = JSON.parse(JSON.stringify(localStorage.getItem('user')))
  
}catch(e){
  console.error(e)
}

if(!user){
  user = {
    id :-1,
    token : '', 
    email : '', 
    name : '', 
    role : ''
  }
}

export default createStore({
  state: {
    user : user,
  },
  getters : {
  },
  mutations: {
   
    UPDATE_USER(state, user){
      
      state.user = user
    },
    
  },
  actions: {
    login(context, userInfos){
      return new Promise((resolve, reject)=>{
        axios.post(data.baseUrl+"users/find-user/", userInfos)
        .then(response=> {
          //console.log(response.data)
          if(!response.data.Error){
            if(response.data[0]){
              //console.log(response.data)
              context.commit('UPDATE_USER', response.data[0])
              localStorage.setItem('user', user)
              resolve(response)
            }
          }else{
            resolve(response)
          }
        })
        .catch(error=> reject(error)) 
      })
    },
    logAfterRegister(context, user){
          context.commit('UPDATE_USER', user)
          localStorage.setItem('user', user)
    },
    
  },
  modules: {
  },
  plugins : [createPersistedState()],
})
