<template>
<div id="timetracker" >
      <div class="nav" v-show="showNav">
        <Header :readFile="false" />
            
      </div>
    
        <link ref="https://use.fontawesome.com/releases/v5.7.2/css/all.css"/>
        <link rel="stylesheet" href="extensions/sticky-header/bootstrap-table-sticky-header.css">

      <div class="container-fluid" >
          

        <div id="content" v-show="!fromDatabase">
            <div class="card p-3 " style="background-color: hwb(41deg 5% 11%); position: inherit;">
                <div class="row">
                    <div class="col-5 text-white" >
                        <h3>{{timetracker.name}} {{timetracker.firstname}}</h3>
                        <span class=" text-white">Mois</span> : <select class="form-control" width="50%"  v-model="month" @change="onchangeMonth" placeholder="mois" >
                                <option v-for="(item, index) in months" :value="index+1" :key="item">{{item}}</option>
                            </select> 
                        <span class=" text-white">Année</span> : <select class="form-control"  width="50%"  v-model="year" @change="onchangeMonth" placeholder="mois" >
                                <option v-for="(item) in years" :value="item" :key="item">{{item}}</option>
                            </select>
                        
                    </div>
                    <div class="col-6">
                    </div>
                    <div class="col-1">
                        <button class="btn text-white" style="float: right; background-color: #024013" @click="saveTimetracker()">Soumettre</button>
                        <button class="btn text-white mt-2" style="float: right; width: 240px; background-color: #024013" data-toggle="modal" data-target="#exampleModal">Rapporter un bug/feedback</button>
                    </div>
                </div>
            </div>    
            
            
<!-- Modal -->
<div class="modal fade" data-backdrop="static" data-keyboard="false" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header bg-dark justify-content-center">
        <h5 class="modal-title text-white" id="exampleModalLabel" style="font-size: 30px;"><b>FAIRE UN RAPPORT</b></h5>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendEmail" method="get">
          <div class="mb-3">
            <label for="exampleInputDropdown1" class="fl form-label">Rapporter un</label>
            <select class="form-control" aria-label="Default select example" style="font-size: 24px;" id="selectExample" required>
                <option selected disabled value="">--</option>
                <option value="1" style="font-size: 25px;">Bug</option>
                <option value="2" style="font-size: 25px;">Feedback</option>
            </select>  
        </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="fl form-label">Adresse mail</label>
            <input type="email" class="form-control" id="exampleInputEmail1" name="exampleInputEmail1" :value="user.email" aria-describedby="emailHelp" style="font-size: 24px;">
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="fl form-label">Description</label>
            <textarea class="form-control" id="textAreaExample1" name="textAreaExample1" rows="3" style="font-size: 25px;" required></textarea>
          </div>

          <div class="mb-3" id="displayScenario">
              <textarea class="form-control" id="textAreaExample2" name="textAreaExample2"  style="font-size: 25px;" placeholder="Étape 1 &#8594; Étape 2 &#8594; Étape 3 &#8594; Étape 4" rows="4"></textarea>
              <label for="exampleInputPassword1" class="fl form-label">Scénario</label>
          </div>
          <div class="h-100 d-flex align-items-center justify-content-center">
          <button type="submit" class="btn btn-success" style="font-size: 32px;" @click="setRequired">Rapporter</button>
        </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-dismiss="modal" style="font-size: 24px;">Fermer</button>
      </div>
    </div>
  </div>
</div>
                        
                    
                        <div>
                        <table id="timeT" class="table table-bordered" width='100%'>
                            <thead>
                                <tr>
                                <th style="width: 70px; border: black;" class="bgGreen text-center">Nbre jours ouvrés</th>
                                <th style="width: 40px" class="bgGreen text-center">{{compteurJourOuvre}}</th>
                                <th style="width: 1px" class="bgGreen" v-for="(item,index) in daysInMonth"  :key="index">
                                    <b>{{item}}</b>
                                </th>
                            </tr>
                            <tr>
                                    <th class="bgGreen"></th>
                                    <th class="bgGreen"></th>
                                    <th style="width: 1px" class="bgGreen" v-for="(item,index) in days"  :key="index">
                                         {{item}}
                                    </th>
                                </tr>
                                <tr>
                                    <th style="background-color: rgb(202, 255, 241);">Nbre jours saisis</th>
                                    <th style="background-color: rgb(202, 255, 241);">{{jourSaisi}}</th>
                                    <th :id="'td_'+index" style="max-width: 1px" :class="item==0? 'normal' : item==0.5? 'manqueHeure' : item<=1? 'correct' : 'plusDeUn' " v-for="(item,index) in sumDays"  :key="index">
                                         <span style="font-style : italic;" >{{item!=0? item : ''}}</span>
                                         
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr class="titleTab">
                                    <td>Absence </td>
                                    <td>{{sAbsence}}</td>
                                    <td :COLSPAN="daysInMonth"></td>
                                </tr>
                                <tr >
                                    <td></td>
                                    <td><span style="float: left">Congés</span> <span style="float: right">{{sumColumns[0]!=0?sumColumns[0] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)"  contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_CP'" @blur="changeTdContent(index, 'CP')">
                                       
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><span style="float: left">RTT</span> <span style="float: right">{{sumColumns[1]!=0?sumColumns[1] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)"  contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_RT'" @blur="changeTdContent(index, 'RT')">
                                       
                                    </td>
                                </tr>
                                <tr  >
                                    <td></td>
                                    <td><span style="float: left">Arrêt maladie</span><span style="float: right">{{sumColumns[2]!=0?sumColumns[2] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_MA'" @blur="changeTdContent(index, 'MA')">
                                        
                                    </td>
                                </tr>
                                <tr >
                                    <td></td>
                                    <td><span style="float: left">Formation </span><span style="float: right">{{sumColumns[3]!=0? sumColumns[3] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)"  contenteditable="true" v-for="(item,index) in daysInMonth"   :key="index" :id="'td_'+index+'_FO'" @blur="changeTdContent(index, 'FO')">
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><span style="float: left">Congés exc.</span><span style="float: right">{{sumColumns[4]!=0? sumColumns[4] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_CX'" @blur="changeTdContent(index, 'CX')">
                                        
                                    </td>
                                </tr>
                                <tr >
                                    <td></td>
                                    <td><span style="float: left">Congés s. solde</span><span style="float: right">{{sumColumns[5]!=0? sumColumns[5] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_CS'" @blur="changeTdContent(index, 'CS')">
                                        
                                    </td>
                                </tr>
                                <tr class="titleTab">
                                    <td>Clientèle</td>
                                    <td>{{sClientele}}</td>
                                    <td :COLSPAN="daysInMonth"></td>
                                </tr>
                                <tr >
                                    <td><select class="custom select p-1" v-model="cid_sur_site" @change="onChangeCLIENT($event, 'site')">
                                        <option value="">Choisir un client</option>
                                        <option v-for="(client, index) in clients" :key="index" :value="client._id">{{client.name}}</option>
                                        </select>
                                    </td>
                                    <td><span style="float: left">Sur site</span><span style="float: right">{{sumColumns[6]!=0? sumColumns[6] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_site'" @blur="changeTdContent(index, 'site')">
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td><select class="custom select p-1" v-model="cid_en_teletravail" @change="onChangeCLIENT($event, 'teletravail')">
                                        <option value="">Choisir un client</option>
                                        <option v-for="(client, index) in clients" :key="index" :value="client._id">{{client.name}}</option>
                                        </select>
                                    </td>
                                    <td><span style="float: left">Télétravail</span> <span style="float: right">{{sumColumns[7]!=0? sumColumns[7] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_teletravail'" @blur="changeTdContent(index, 'teletravail')">
                                       
                                    </td>
                                </tr>
                                <tr >
                                    <td><select class="custom select p-1" v-model="cid_deplacement" @change="onChangeCLIENT($event, 'deplacement')">
                                        <option value="">Choisir un client</option>
                                        <option v-for="(client, index) in clients" :key="index" :value="client._id">{{client.name}}</option>
                                        </select>
                                    </td>
                                    <td><span style="float: left">Déplacement</span><span style="float: right">{{sumColumns[8]!=0? sumColumns[8] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_deplacement'" @blur="changeTdContent(index, 'deplacement')">
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td><select class="custom select p-1" v-model="cid_presentation" @change="onChangeCLIENT($event, 'presentation')">
                                        <option value="">Choisir un client</option>
                                        <option v-for="(client, index) in clients" :key="index" :value="client._id">{{client.name}}</option>
                                        </select>
                                    </td>
                                    <td><span style="float: left">Présentation</span><span style="float: right">{{sumColumns[9]!=0? sumColumns[9] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_presentation'" @blur="changeTdContent(index, 'presentation')">
                                       
                                    </td>
                                </tr>
                                 <tr class="titleTab">
                                    <td>Interne</td>
                                    <td>{{sInterne}}</td>
                                    <td :COLSPAN="daysInMonth"></td>
                                </tr>
                                <tr>
                                    <td rowspan="3" class="text-center justify-content-center align-items-center">Projet</td>
                                    <td><span style="float: left">Bulma</span><span style="float: right">{{sumColumns[10]!=0? sumColumns[10] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_projetBulma'" @blur="changeTdContent(index, 'projetBulma')">

                                    </td>
                                </tr>
                                 <tr>
                                    <td><span style="float: left">Bulma télétravail</span><span style="float: right">{{sumColumns[11]!=0? sumColumns[11] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_projetBulmaTeletravail'" @blur="changeTdContent(index, 'projetBulmaTeletravail')">

                                    </td>
                                </tr>
                                 <tr>
                                    <td><span style="float: left">Projet interne</span><span style="float: right">{{sumColumns[12]!=0? sumColumns[12] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_projetInterne'" @blur="changeTdContent(index, 'projetInterne')">

                                    </td>
                                </tr>
                                 <tr>
                                    <td rowspan="2">Structure</td>
                                    <td><span style="float: left">Domrémy</span><span style="float: right">{{sumColumns[13]!=0? sumColumns[13] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_structureDomremy'" @blur="changeTdContent(index, 'structureDomremy')">
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td><span style="float: left">Télétravail</span><span style="float: right">{{sumColumns[14]!=0? sumColumns[14] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_structureTeletravail'" @blur="changeTdContent(index, 'structureTeletravail')">
                                        
                                    </td>
                                </tr>
                                 <tr>
                                    <td rowspan="2">Intercontrat</td>
                                    <td><span style="float: left">Domrémy</span><span style="float: right">{{sumColumns[15]!=0? sumColumns[15] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_ICDomremy'" @blur="changeTdContent(index, 'ICDomremy')">
                                        
                                    </td>
                                </tr>

                                <tr>
                                    <td><span style="float: left">Télétravail</span><span style="float: right">{{sumColumns[16]!=0? sumColumns[16] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(item, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_ICTeletravail'" @blur="changeTdContent(index, 'ICTeletravail')">
                                        
                                    </td>
                                </tr>
                                <tr class="titleTab">
                                    <td>Heure Supplémentaire</td>
                                    <td>{{sSupp}}</td>
                                    <td :COLSPAN="daysInMonth"><strong>Lors de la saisie de vos heures supplémentaires, veuillez les indiquer en heures. Par exemple, si vous avez travaillé pendant 1 heure 30 minutes, veuillez saisir 1.5. Une fois vos heures supplémentaires saisies, vous aurez accès à un tableau détaillé où vous pourrez modifier l'heure de début et ajouter un commentaire si nécessaire.</strong></td>
                                </tr>
                                <tr>
                                    <td>Entrez en heure</td>
                                    <td><span style="float: left">Trav supp (h) </span><span style="float: right">{{sumColumns[17]!=0? sumColumns[17] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_supp'" @blur="changeTdContent(index, 'supp')">
                                            
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><span style="float: left">Astreinte</span><span style="float: right">{{sumColumns[18]!=0? sumColumns[18] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" contenteditable="true" v-for="(item,index) in daysInMonth"  :key="index" :id="'td_'+index+'_astreinte'" @blur="changeTdContent(index, 'astreinte')">
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
        </div>
        <div id="content" v-show="fromDatabase">
            <div class="card p-3 " style="background-color: hwb(41deg 5% 11%)">
                <div class="row">
                    <div class="col-5" >
                        
                        <h3 class=" text-white">{{timetracker.name}} {{timetracker.firstname}}</h3>
                        
                                <span class=" text-white">Mois</span> : <select class="form-control" width="50%"  v-model="month" @change="onchangeMonth" placeholder="mois" >
                                <option v-for="(item, index) in months" :value="index+1" :key="item">{{item}}</option>
                            </select> 
                                <span class=" text-white">Année</span> : <select class="form-control"  width="50%"  v-model="year" @change="onchangeYear" placeholder="mois" >
                                <option v-for="(item) in years" :value="item" :key="item">{{item}}</option>
                            </select>
                        
                    </div>
                    <div class="col-6">
                    </div>
                    <div class="col-1">
                        <button class="btn text-white" style="float: right; background-color: #024013" @click="saveTimetracker()" :disabled="timetracker.isValid=='YES'? true : false">Soumettre</button>
                        <button class="btn text-white mt-2" style="float: right; width: 240px; background-color: #024013" data-toggle="modal" data-target="#exampleModal1">Rapporter un bug/feedback</button>

                    </div>
                </div>
            </div>  

                        <!-- Modal -->
<div class="modal fade" data-backdrop="static" data-keyboard="false" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header bg-dark justify-content-center">
        <h5 class="modal-title text-white" id="exampleModalLabel" style="font-size: 30px;"><b>FAIRE UN RAPPORT</b></h5>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendEmail" method="get">
          <div class="mb-3">
            <label for="exampleInputDropdown1" class="fl form-label">Rapporter un</label>
            <select class="form-control" aria-label="Default select example" style="font-size: 24px;" id="selectExample" name="selectExample" required>
                <option selected disabled value="">--</option>
                <option value="Bug" style="font-size: 25px;">Bug</option>
                <option value="Feedback" style="font-size: 25px;">Feedback</option>
            </select>  
        </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="fl form-label">Adresse mail</label>
            <input type="email" class="form-control" id="exampleInputEmail1" name="exampleInputEmail1" :value="user.email" aria-describedby="emailHelp" style="font-size: 24px;">
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="fl form-label">Description</label>
            <textarea class="form-control" id="textAreaExample1" name="textAreaExample1" rows="3" style="font-size: 25px;" required></textarea>
          </div>

          <div class="mb-3" id="displayScenario">
            <label for="exampleInputPassword1" class="fl form-label">Scénario</label>
            <textarea class="form-control" id="textAreaExample2" name="textAreaExample2"  style="font-size: 25px;" placeholder="Étape 1 &#8594; Étape 2 &#8594; Étape 3 &#8594; Étape 4" rows="4"></textarea>
          </div>
          <div class="h-100 d-flex align-items-center justify-content-center">
          <button type="submit" class="btn btn-success" style="font-size: 32px;" @click="setRequired">Rapporter</button>
        </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-dismiss="modal" style="font-size: 24px;">Fermer</button>
      </div>
    </div>
  </div>
</div>
                    <div>
                        
                        <table id="timeT" class="table table-bordered" width='100%'>
                            <thead>
                                <tr>
                                <th style="width: 80px" class="bgGreen text-center">Nbre jours ouvrés</th>
                                <th style="width: 50px" class="bgGreen text-center">{{compteurJourOuvre}}</th>
                                <th style="width: 1px" class="bgGreen" v-for="(item,index) in daysInMonth"  :key="index">
                                    <b>{{item}}</b>
                                </th>
                            </tr>                            
                                <tr>
                                    <th class="bgGreen"></th>
                                    <th class="bgGreen"></th>
                                    <th style="width: 1px" class="bgGreen" v-for="(item,index) in days"  :key="index">
                                         {{item}}
                                    </th>
                                </tr>
                                <tr>
                                    <th style="background-color: rgb(202, 255, 241);">Nbre jours saisis</th>
                                    <th style="background-color: rgb(202, 255, 241);">{{jourSaisi}}</th>
                                     <th :id="'tdb_'+index" style="width: 1px" :class="item==0? 'normal' : item<=1? 'correct' : 'plusDeUn' " v-for="(item,index) in sumDays"  :key="index">
                                         <span style="font-style : italic;">{{item!=0? item : ''}}</span>
                                         <!--{{sumByDay(index)}}-->
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="titleTab">
                                    <td>Absence </td>
                                    <td>{{sAbsence}}</td>
                                    <td :COLSPAN="daysInMonth"></td>
                                </tr>
                                <tr >
                                    <td></td>
                                    <td><span style="float: left">Congés</span> <span style="float: right">{{sumColumns[0]!=0?sumColumns[0] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in aConge"  :key="index" :id="'tdb_'+index+'_CP'" @blur="changeTdContent(index, 'CP')">
                                        {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><span style="float: left">RTT</span> <span style="float: right">{{sumColumns[1]!=0?sumColumns[1] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in aRtt"  :key="index" :id="'tdb_'+index+'_RT'" @blur="changeTdContent(index, 'RT')">
                                       {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr  >
                                    <td></td>
                                    <td><span style="float: left">Arrêt maladie</span><span style="float: right">{{sumColumns[2]!=0?sumColumns[2] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true"  v-for="(item,index) in aMaladie"  :key="index" :id="'tdb_'+index+'_MA'" @blur="changeTdContent(index, 'MA')">
                                        {{( item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr >
                                    <td></td>
                                    <td><span style="float: left">Formation </span><span style="float: right">{{sumColumns[3]!=0? sumColumns[3] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)"  :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in aFormation"   :key="index" :id="'tdb_'+index+'_FO'" @blur="changeTdContent(index, 'FO')">
                                        {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><span style="float: left">Congés exc.</span><span style="float: right">{{sumColumns[4]!=0? sumColumns[4] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in aCongeExc"  :key="index" :id="'tdb_'+index+'_CX'" @blur="changeTdContent(index, 'CX')">
                                        {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr >
                                    <td></td>
                                    <td><span style="float: left">Congés s. solde</span><span style="float: right">{{sumColumns[5]!=0? sumColumns[5] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in aCongeSSolde"  :key="index" :id="'tdb_'+index+'_CS'" @blur="changeTdContent(index, 'CS')">
                                        {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr class="titleTab">
                                    <td>Clientèle</td>
                                    <td>{{sClientele}}</td>
                                    <td :COLSPAN="daysInMonth"></td>
                                </tr>
                                <tr >
                                    <td><select class="custom select p-1" v-model="cid_sur_site" @change="onChangeCLIENT($event, 'site')">
                                        <option value="">Choisir un client</option>
                                        <option v-for="(client, index) in clients" :key="index" :value="client._id">{{client.name}}</option>
                                        </select>
                                    </td>
                                    <td><span style="float: left">Sur site</span><span style="float: right">{{sumColumns[6]!=0? sumColumns[6] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in clienteleSite"  :key="index" :id="'tdb_'+index+'_site'" @blur="changeTdContent(index, 'site')">
                                        {{( item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td><select class="custom select p-1" v-model="cid_en_teletravail" @change="onChangeCLIENT($event, 'teletravail')">
                                        <option value="">Choisir un client</option>
                                        <option v-for="(client, index) in clients" :key="index" :value="client._id">{{client.name}}</option>
                                        </select>
                                    </td>
                                    <td><span style="float: left">Télétravail</span> <span style="float: right">{{sumColumns[7]!=0? sumColumns[7] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in clienteleTeletravail"  :key="index" :id="'tdb_'+index+'_teletravail'" @blur="changeTdContent(index, 'teletravail')">
                                       {{( item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr >
                                    <td><select class="custom select p-1" v-model="cid_deplacement" @change="onChangeCLIENT($event, 'deplacement')">
                                        <option value="">Choisir un client</option>
                                        <option v-for="(client, index) in clients" :key="index" :value="client._id">{{client.name}}</option>
                                        </select>
                                    </td>
                                    <td><span style="float: left">Déplacement</span><span style="float: right">{{sumColumns[8]!=0? sumColumns[8] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in clienteleDeplacement"  :key="index" :id="'tdb_'+index+'_deplacement'" @blur="changeTdContent(index, 'deplacement')">
                                        {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td><select class="custom select p-1" v-model="cid_presentation" @change="onChangeCLIENT($event, 'presentation')">
                                        <option value="">Choisir un client</option>
                                        <option v-for="(client, index) in clients" :key="index" :value="client._id">{{client.name}}</option>
                                        </select>
                                    </td>
                                    <td><span style="float: left">Présentation</span><span style="float: right">{{sumColumns[9]!=0? sumColumns[9] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in clientelePresentation"  :key="index" :id="'tdb_'+index+'_presentation'" @blur="changeTdContent(index, 'presentation')">
                                       {{( item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                 <tr class="titleTab">
                                    <td >Interne</td>
                                    <td>{{sInterne}}</td>
                                    <td :COLSPAN="daysInMonth"></td>
                                </tr>
                                <tr>
                                    <td rowspan="3" class="text-center">Projet</td>
                                    <td><span style="float: left"> Bulma </span><span style="float: right">{{sumColumns[10]!=0? sumColumns[10] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in projetBulma"  :key="index" :id="'tdb_'+index+'_projetBulma'" @blur="changeTdContent(index, 'projetBulma')">
                                            {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr>
                                    
                                    <td><span style="float: left"> Bulma télétravail</span><span style="float: right">{{sumColumns[11]!=0? sumColumns[11] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in projetBulmaTeletravail"  :key="index" :id="'tdb_'+index+'_projetBulmaTeletravail'" @blur="changeTdContent(index, 'projetBulmaTeletravail')">
                                            {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td><span style="float: left">Projet interne</span><span style="float: right">{{sumColumns[12]!=0? sumColumns[12] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in projetInterne"  :key="index" :id="'tdb_'+index+'_projetInterne'" @blur="changeTdContent(index, 'projetInterne')">
                                            {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2">Structure</td>
                                    <td><span style="float: left">Domrémy</span><span style="float: right">{{sumColumns[13]!=0? sumColumns[13] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in structureDomremy"  :key="index" :id="'tdb_'+index+'_structureDomremy'" @blur="changeTdContent(index, 'structureDomremy')">
                                            {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td><span style="float: left">Télétravail</span><span style="float: right">{{sumColumns[14]!=0? sumColumns[14] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in structureTeletravail"  :key="index" :id="'tdb_'+index+'_structureTeletravail'" @blur="changeTdContent(index, 'structureTeletravail')">
                                            {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2">Intercontrat</td>
                                    <td><span style="float: left">Domrémy</span><span style="float: right">{{sumColumns[15]!=0? sumColumns[15] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in intercontratDomremy"  :key="index" :id="'tdb_'+index+'_ICDomremy'" @blur="changeTdContent(index, 'ICDomremy')">
                                        {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                                <tr>
                                    <td><span style="float: left">Télétravail</span><span style="float: right">{{sumColumns[16]!=0? sumColumns[16] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in intercontratTeletravail"  :key="index" :id="'tdb_'+index+'_ICTeletravail'" @blur="changeTdContent(index, 'ICTeletravail')">
                                        {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>

                                <tr class="titleTab">
                                    <td>Heure Supplémentaire</td>
                                    <td>{{sSupp}}</td>
                                    <td :COLSPAN="daysInMonth"><strong>Lors de la saisie de vos heures supplémentaires, veuillez les indiquer en heures. Par exemple, si vous avez travaillé pendant 1 heure 30 minutes, veuillez saisir 1.5. Une fois vos heures supplémentaires saisies, vous aurez accès à un tableau détaillé où vous pourrez modifier l'heure de début et ajouter un commentaire si nécessaire.</strong></td>
                                </tr>
                                <tr>
                                    <td>Entrez en heure</td>
                                    <td><span style="float: left">Trav supp(h) </span><span style="float: right">{{sumColumns[17]!=0? sumColumns[17] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in supp"  :key="index" :id="'tdb_'+index+'_supp'" @blur="changeTdContent(index, 'supp')">
                                            {{item==0? '' : item}}
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><span style="float: left">Astreinte</span><span style="float: right">{{sumColumns[18]!=0? sumColumns[18] : ''}}</span></td>
                                    <td style="width: 1px" :class="classIfWeekEnd(index+1, this.month)" :contenteditable="timetracker.isValid=='YES'? false : true" v-for="(item,index) in astreinte"  :key="index" :id="'tdb_'+index+'_astreinte'" @blur="changeTdContent(index, 'astreinte')">
                                        {{(item==1 || item==0.5)? item : ''}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
        </div>

        <div class="row ">
            <div class="col-sm-5 ">
                <div class="form-group">
                    <label for="note">Notes</label>
                    <textarea class="form-control" :id="'note_'+this.user._id+'_'+this.month+'_'+this.year" v-model="note" rows="5" ></textarea>
                </div>
            </div>
            <div class="col-sm-7 " v-show="tabDetailHeureSupp && tabDetailHeureSupp.length>0">
                <table class="table table-bordered" >
                    <thead>
                        <th class="text-white" style="background-color: rgb(202, 117, 5);">Détail des heures supplémentaires</th>
                    </thead>
                    <tr>
                        <th>Jour (jj/mm)</th>
                        <th>Début (hh/mm)</th>
                        <th>Fin (hh/mm)</th>
                        <th>Détail</th>
                        <th>Commentaires</th>
                    </tr>
                    <tbody id="tabSuppContent">
                       <tr v-for="(heureSupp, index) in tabDetailHeureSupp" :key="index" :id="'supp_'+ heureSupp.date">
                            <td>{{ heureSupp.date }}</td>
                            <td contenteditable='true' :id="'supp_'+heureSupp.date+'_debut'" @blur="changeDebut(heureSupp.date)">{{ heureSupp.debut }}</td>
                            <td :id="'supp_'+heureSupp.date+'_fin'">{{calculateFinSupp(heureSupp.debut,heureSupp.detail) }}</td>
                            <td>{{ heureSupp.detail }}</td>
                            <td :id="'supp_'+heureSupp.date+'_commentaire'" contenteditable='true' @blur="changeCommentaire(heureSupp.date)">{{ heureSupp.commentaire }}</td>
                       </tr>
                    </tbody>
                </table>
            </div>
        
        </div>
        
        
      </div>
  </div>

</template>
<script>

import moment from 'moment'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

//import "https://unpkg.com/sticky-table-headers";

//import emailjs from '@emailjs/browser';
import emailjs from 'emailjs-com';

import store from '../store'
import Header from '@/components/Header.vue';
 import { useToast } from "vue-toastification";
const toast = useToast()

import axios from 'axios'
import data from '@/data/data'
export default {
  name: 'Timetracker',
  components: {
      Header
  },
  props:{
        showNav : {
            type : Boolean, 
            default : true
        } 
    },
  created(){
        var currentTime = new Date()
        this.month = currentTime.getMonth() + 1
        this.year = currentTime.getFullYear()
        this.years = [this.year-2, this.year-1, this.year]
        this.daysInMonth =  new Date(this.year, this.month, 0).getDate();
        this.days = this.getDay(this.daysInMonth)
        //console.log(this.days)
        this.weeks = this.getWeek(this.month)
        
  },
  mounted(){
    
    try{
       if(this.user.id == -1)
            this.$router.push('/login')
    }catch(error){
            console.error(error)
    }
    axios.get(data.baseUrl + "timetracker/get-year-list")
        .then(response => {
            this.years = response.data
        })
        .catch(error => console.log(error))
    axios.get(data.baseUrl + "clients")
        .then(response => {
            this.clients = response.data
        })
        .catch(error => console.log(error))
    let country='FRA'
    console.log(this.user.companyDetails)
    if(this.user.companyDetails && this.user.companyDetails.length!=0)
         country = this.user.companyDetails[0].countryDetails.countryAlpha3
    let holidays = data.jourFerieFrance
    if(country=='MUS') 
        holidays = data.jourFerieMaurice
    holidays.then(date=>{
        this.jourFerie = date    
        this.compteurJO = this.countJourOuvre()
    })
    
    axios.post(data.baseUrl+"timetracker-collab/", {"user_id" : this.user._id, "month" : this.months[this.month-1], "year" : this.year})
    .then(response=> {
        //console.log("timetracker database ")
        
            if(response.data.length==0){
                this.fromDatabase = false
                //console.log("timetracker vide")
                
                for(let i=0; i<this.daysInMonth; i++){
                    const date = new Date(this.month+'-'+(i+1)+"-"+this.year)
                    this.tabDate.push(this.format_date2(date))
                    this.sumDays.push(this.sumByDay(i))
                    this.clienteleSite.push(0)
                    this.clienteleTeletravail.push(0)
                    this.clienteleDeplacement.push(0)
                    this.clientelePresentation.push(0)
                    this.aConge.push(0)
                    this.aMaladie.push(0)
                    this.aFormation.push(0)
                    this.aRtt.push(0)
                    this.aCongeSSolde.push(0)
                    this.aCongeExc.push(0)
                    
                    this.supp.push(0)
                    this.astreinte.push(0)
                    this.projetBulma.push(0)
                    this.projetBulmaTeletravail.push(0)
                    this.projetInterne.push(0)
                    this.structureDomremy.push(0)
                    this.structureTeletravail.push(0)
                    this.intercontratDomremy.push(0)
                    this.intercontratTeletravail.push(0)
                }
                for( let i=0; i<this.libelles.length; i++){
                    this.sumColumns.push(this.sumByColumn(this.libelles[i]))
                }
                this.timetracker = {
                    name : this.user.last_name, 
                    firstname : this.user.first_name, 
                    daysFormatted : this.tabDate, 
                    clientele : this.sumClientele(),
                    year : this.year,
                    month : this.months[this.month-1],
                    daysTimetracker : this.days,

                    clienteleSite : this.clienteleSite, 
                    clienteleTeletravail : this.clienteleTeletravail, 
                    clienteleDeplacement : this.clienteleDeplacement, 
                    clientelePresentation : this.clientelePresentation, 

                    aConge : this.aConge,
                    aMaladie : this.aMaladie, 
                    aFormation : this.aFormation, 
                    aRtt : this.aRtt, 

                    projetBulma : this.projetBulma,
                    projetBulmaTeletravail : this.projetBulmaTeletravail,
                    projetInterne : this.projetInterne,
                    structureDomremy : this.structureDomremy,
                    structureTeletravail : this.structureTeletravail,
                    intercontratDomremy : this.intercontratDomremy,
                    intercontratTeletravail : this.intercontratTeletravail,
                    projet : this.projet,
                    intercontrat : this.intercontrat,
                    supp : this.supp,
                    astreinte : this.astreinte, 
                    note : this.note,
                    detailHeureSupp : this.tabDetailHeureSupp
                    
                }
            }
        else{
            this.fromDatabase = true
            this.timetracker = response.data[0]
            this.sumDays = this.timetracker.sumDays
            this.clienteleSite = this.timetracker.clienteleSite
            this.clienteleTeletravail = this.timetracker.clienteleTeletravail
            this.clienteleDeplacement = this.timetracker.clienteleDeplacement
            this.clientelePresentation = this.timetracker.clientelePresentation
            this.aConge = this.timetracker.aConge
            this.aMaladie = this.timetracker.aMaladie
            this.aFormation = this.timetracker.aFormation
            this.aRtt = this.timetracker.aRtt
            this.aCongeSSolde = this.timetracker.aCongeSSolde
            this.aCongeExc = this.timetracker.aCongeExc
            this.projet = this.timetracker.projet
            this.intercontrat = this.timetracker.intercontrat
            this.supp = this.timetracker.supp
            this.astreinte = this.timetracker.astreinte
            this.isValid = this.timetracker.isValid
            this.note = this.timetracker.note
            this.cid_sur_site = this.timetracker.cid_sur_site
            this.cid_en_teletravail = this.timetracker.cid_en_teletravail
            this.cid_deplacement = this.timetracker.cid_deplacement
            this.cid_presentation = this.timetracker.cid_presentation

            this.projetBulma = this.timetracker.projetBulma,
            this.projetBulmaTeletravail = this.timetracker.projetBulmaTeletravail,
            this.projetInterne = this.timetracker.projetInterne,
            this.structureDomremy = this.timetracker.structureDomremy,
            this.structureTeletravail = this.timetracker.structureTeletravail,
            this.intercontratDomremy = this.timetracker.intercontratDomremy,
            this.intercontratTeletravail = this.timetracker.intercontratTeletravail,
            this.tabDetailHeureSupp = this.timetracker.detailHeureSupp
            this.jourSaisi = this.timetracker.jourSaisi
            if(!this.tabDetailHeureSupp) this.tabDetailHeureSupp = []
            this.calculSumOnMounting()

             
        }
    }).catch(error=>console.log(error))
   
    
  },
  
  data(){
    return{
      user : store.state.user,
      
      search : '', 
      startRow: 0,
      rowsPerPage: 20,
      months :["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"],
      year : 0,
      month : 0,
      daysInMonth:0,
      days : [], 
      readFile : false, 
      years : [], 
      weeks : [],
      timetracker : {},
      error : false, 
      compteurJourOuvre : 0,
      compteurJO : 0 , 
      sumDays :[],
      libelles: ["CP", "RT",  "MA", "FO", "CX", "CS", "site", "teletravail", "deplacement", "presentation", "projetBulma", "projetBulmaTeletravail", "projetInterne","structureDomremy", "structureTeletravail", "ICDomremy", "ICTeletravail", "supp", "astreinte"],
      sumColumns: [], 
      clienteleSite : [],
      clienteleTeletravail : [], 
      clienteleDeplacement : [], 
      clientelePresentation : [],
      clientele : [], 
      sAbsence :0,
      sClientele :0,
      sInterne : 0,
      sSupp : 0,
      jourSaisi : 0,

      aConge : [], 
      aRtt : [],
      aFormation : [],
      aMaladie : [],
      aCongeSSolde : [], 
      aCongeExc : [],

        projet : [], 
        structure : [],
        intercontrat : [] , 
        supp:[],
        astreinte : [],
        tabDate : [] ,
        absence : [], 
       fromDatabase : false, 
       note : '', 
       clients : [], 
       projetBulma : [],
        projetBulmaTeletravail : [],
        projetInterne : [],
        structureDomremy : [],
        structureTeletravail : [],
        intercontratDomremy  : [],
        intercontratTeletravail : [],

       cid_sur_site : '',
       cid_en_teletravail : '',
       cid_deplacement : '', 
       cid_presentation : '',
        jourFerie :[], 

        tabDetailHeureSupp : []
    }
  },
  computed:{
    
  },
  methods: {

    setRequired(){

let scenario = document.getElementById("textAreaExample2");
let optionSelect = document.getElementById("selectExample");
let inputImage = document.getElementById("inputGroupFile");
//let imageInput = document.getElementById("displayScenario");
//let captureDecran = document.getElementById("captureEcran");

if (optionSelect.value == 1){
     scenario.required = true;
     inputImage.required = true;
    //imageInput.style.display = ''
    //captureDecran.style.display = ''
}
// else {
//     imageInput.style.display = 'none';
//     captureDecran.style.display = 'none';
// }
},

sendEmail(e) {
try {
emailjs.sendForm('service_3vvqvbr', 'template_7eua1j3', e.target,
'g_k7Ysx3IQVZQVnu1', {
  name: this.name,
  email: this.email,
  message: this.message,
  image: this.image
})


} catch(error) {
  console.log({error})
}
// Reset form field
this.name = ''
this.email = ''
this.message = ''
},
    codeforMonth(){
        let tab = []
        for(let i=1; i<=this.daysInMonth; i++){
            if(!this.isWeekend(i, this.month)){
                tab.push(this.code)
            }
            else tab.push(null)
        }
        return tab
    },
    codeforMonth2(user){
        const userPrev =  this.objectPrevisionnelle.filter(item=> {
            return item.collaborateur._id== user._id
        })
        return userPrev.day_code
    },
  
    getDay(nbrjours){
        const days=[]
        for(let i=1; i<=nbrjours; i++){
            const date = new Date(this.year+'-'+this.month+'-'+ i)
            const dayNum = date.getDay()
            console.log(i , dayNum)
            switch ( dayNum ) {
                case 0:
                    days.push('D')
                    break;
                case 1:
                    days.push('L')
                    break;
                case 2:
                    days.push('M')
                    break;
                case 3:
                    days.push('M')
                    break;
                case 4:
                    days.push('J')
                    break;
                case 5:
                    days.push('V')
                    break;
                default: 
                    days.push('S')
                    break;
            }
        }
        
        return days
    },
    
    getWeek(month)
    {
        let tabWeek = []
         var oneJan = new Date(this.year,0,1);
        for(let i=1; i<=this.daysInMonth; i++){
            const laDate = new Date(this.year, month-1, i)
            const week = Math.ceil((((laDate.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);
            tabWeek.push(week)
        }
        return tabWeek
    },
   isWeekend(day, month) {
        
        const date = new Date(this.year+'-'+month+'-'+ day)
        let isWk = false
         let isFerier = false
        let dateString = moment(date).format('DD-MM-YYYY');
        
        isFerier = this.jourFerie.includes(dateString)
        
        if(date.getDay() == 6 || date.getDay() == 0 || isFerier)
            isWk = true
        
        return isWk
    },
    classIfWeekEnd(day,month){
        if(this.isWeekend(day, month))
            return "null1"
    },
    countJourOuvre(){
        for(let day = 1; day<=this.daysInMonth; day++){
            const date = new Date(this.year+'-'+this.month+'-'+ day)
            let dateString = moment(date).format('DD-MM-YYYY');
            
            if(date.getDay() == 6 || date.getDay() == 0 || this.jourFerie.includes(dateString))
                console.log("WEEK-END")
            else this.compteurJourOuvre++
        }   
    },
    calculSum(){
        
        this.sClientele= this.sumClientele()
        this.sAbsence = this.sumAbsence()
        this.sInterne = this.sumInterne()
        this.sSupp = this.sumSupp()
        this.jourSaisi = this.sClientele+this.sAbsence+this.sInterne
    },
    calculSumOnMounting(){
        let sommeClientele = 0, sommeAbsence = 0, sommeInterne = 0, sommeSupp=0
        for(let i=0; i<this.daysInMonth; i++){
            
            sommeClientele+= this.clienteleSite[i]+this.clienteleTeletravail[i]+this.clienteleDeplacement[i]+this.clientelePresentation[i]
            sommeAbsence+=this.aConge[i]+this.aRtt[i]+this.aFormation[i]+this.aMaladie[i]+this.aCongeSSolde[i]+this.aCongeExc[i]
            sommeInterne+=this.projetBulma[i]+this.projetBulmaTeletravail[i]+this.projetInterne[i]+this.structureDomremy[i]+this.structureTeletravail[i]+this.intercontratDomremy[i]+this.intercontratTeletravail[i]
            sommeSupp+=(this.supp[i])+this.astreinte[i]
        }
        this.sClientele= sommeClientele
        this.sAbsence = sommeAbsence
        this.sInterne = sommeInterne
        this.sSupp = sommeSupp
        this.jourSaisi = this.sClientele+this.sAbsence+this.sInterne
    },
    initData(){
            this.clienteleSite = []
            this.clienteleTeletravail= []
            this.clienteleDeplacement= []
            this.clientelePresentation= []
            this.aConge= []
            this.aMaladie= []
            this.aFormation= []
            this.aRtt= []
            this.aCongeSSolde= []
            this.aCongeExc= []
            this.projet= []
            this.structure =[]
            this.intercontrat= []
            this.supp= []
            this.astreinte= []
            this.sAbsence =0,
            this.sClientele=0,
            this.sInterne = 0,
            this.sSupp=  0,
            this.jourSaisi = 0, 
            this.projetBulma = [],
            this.projetBulmaTeletravail = [],
            this.projetInterne = [],
            this.structureDomremy = [],
            this.structureTeletravail = [],
            this.intercontratDomremy  = [],
            this.intercontratTeletravail = [], 
            this.tabDetailHeureSupp = []
            //this.note=''
    },
    initClient(){
        
        this.cid_sur_site = ""
        this.cid_en_teletravail = ""
        this.cid_deplacement = "",
        this.cid_presentation = ""
    },
    onchangeMonth(){
        this.note=""
        this.days=[]
        this.compteurJourOuvre = 0
        this.daysInMonth = new Date(this.year, this.month, 0).getDate();
        this.getWeek(this.month)
        this.days = this.getDay(this.daysInMonth)
        
        this.sumColumns = []
        this.sumDays = [], 
        this.initClient()
        this.timetracker = {}
        
        this.countJourOuvre()
        axios.post(data.baseUrl+"timetracker-collab/", {"user_id" : this.user._id, "month" : this.months[this.month-1], "year" : this.year})
        .then(response=> {
            //console.log("OULALALA : ", response.data)
            this.initData()
            if(response.data.length==0){
                this.fromDatabase = false
                //console.log("timetracker vide")
                for(let i=0; i<this.daysInMonth; i++){
                    const date = new Date(this.month+'-'+(i+1)+"-"+this.year)
                    
                    this.tabDate.push(this.format_date2(date))
                    this.sumDays.push(this.sumByDay(i))
                    this.clienteleSite.push(0)
                    this.clienteleTeletravail.push(0)
                    this.clienteleDeplacement.push(0)
                    this.clientelePresentation.push(0)
                    this.aConge.push(0)
                    this.aMaladie.push(0)
                    this.aFormation.push(0)
                    this.aRtt.push(0)
                    this.aCongeSSolde.push(0)
                    this.aCongeExc.push(0)
                    
                    
                    this.projetBulma.push(0)
                    this.projetBulmaTeletravail.push(0)
                    this.projetInterne.push(0)
                    this.structureDomremy.push(0)
                    this.structureTeletravail.push(0)
                    this.intercontratDomremy.push(0)
                    this.intercontratTeletravail.push(0)
                    this.supp.push(0)
                    this.astreinte.push(0)
                    
                }
                for( let i=0; i<this.libelles.length; i++){
                    this.sumColumns.push(this.sumByColumn(this.libelles[i]))
                }
                //console.log(this.sumColumns)
                this.timetracker = {
                    name : this.user.last_name, 
                    firstname : this.user.first_name, 
                    daysFormatted : this.tabDate, 
                    clientele : this.sumClientele(),
                    year : this.year,
                    month : this.months[this.month-1],
                    daysTimetracker : this.days,

                    clienteleSite : this.clienteleSite, 
                    clienteleTeletravail : this.clienteleTeletravail, 
                    clienteleDeplacement : this.clienteleDeplacement, 
                    clientelePresentation : this.clientelePresentation, 

                    //absence : absence, 
                    aConge : this.aConge,
                    aMaladie : this.aMaladie, 
                    aFormation : this.aFormation, 
                    aRtt : this.aRtt, 

                    projet : this.projet,
                    intercontrat : this.intercontrat,
                    supp : this.supp, 
                    astreinte : this.astreinte, 
                    
                    cid_sur_site : this.cid_sur_site,
                    cid_en_teletravail : this.cid_en_teletravail,
                    cid_deplacement : this.cid_deplacement, 
                    cid_presentation : this.cid_presentation,

                    projetBulma : this.projetBulma,
                    projetBulmaTeletravail : this.projetBulmaTeletravail,
                    projetInterne : this.projetInterne,
                    structureDomremy : this.structureDomremy,
                    structureTeletravail : this.structureTeletravail,
                    intercontratDomremy : this.intercontratDomremy,
                    intercontratTeletravail : this.intercontratTeletravail,
                    detailHeureSupp : this.tabDetailHeureSupp
                }
                    
        }
        else{
            //console.log("timetracker de la base de donnée")
            this.fromDatabase = true
           
            this.timetracker = response.data[0]
            // console.log(this.timetracker)
            this.aConge = this.timetracker.aConge
            this.aRtt = this.timetracker.aRtt
            this.aFormation = this.timetracker.aFormation
            this.aMaladie = this.timetracker.aMaladie
            this.aCongeSSolde = this.timetracker.aCongeSSolde
            this.aCongeExc = this.timetracker.aCongeExc
            this.clienteleDeplacement = this.timetracker.clienteleDeplacement
            this.clienteleSite = this.timetracker.clienteleSite
            this.clienteleTeletravail = this.timetracker.clienteleTeletravail
            this.clientelePresentation = this.timetracker.clientelePresentation
            this.projet = this.timetracker.projet
            this.intercontrat = this.timetracker.intercontrat
            this.supp = this.timetracker.supp
            this.note = this.timetracker.note
            this.sumDays = this.timetracker.sumDays
            this.supp = this.timetracker.supp
            this.astreinte = this.timetracker.astreinte
            this.cid_sur_site = this.timetracker.cid_sur_site
            this.cid_en_teletravail = this.timetracker.cid_en_teletravail
            this.cid_deplacement = this.timetracker.cid_deplacement
            this.cid_presentation = this.timetracker.cid_presentation
            this.projetBulma = this.timetracker.projetBulma,
            this.projetBulmaTeletravail = this.timetracker.projetBulmaTeletravail,
            this.projetInterne = this.timetracker.projetInterne,
            this.structureDomremy = this.timetracker.structureDomremy,
            this.structureTeletravail = this.timetracker.structureTeletravail,
            this.intercontratDomremy = this.timetracker.intercontratDomremy,
            this.intercontratTeletravail = this.timetracker.intercontratTeletravail,
            this.structure = this.timetracker.structure
            this.tabDetailHeureSupp = this.timetracker.detailHeureSupp
            this.calculSumOnMounting()
        } 
        
    }).catch(error=>console.log(error))
    
    },
    changeDebut(date){
        const index = this.tabDetailHeureSupp.findIndex(item=> item.date==date)
        this.tabDetailHeureSupp[index].debut = document.getElementById('supp_'+date+'_debut').textContent
        const fin = this.calculateFinSupp(this.tabDetailHeureSupp[index].debut,this.tabDetailHeureSupp[index].detail)
        this.tabDetailHeureSupp[index].fin = fin
    },
    changeCommentaire(date){
        const index = this.tabDetailHeureSupp.findIndex(item=> item.date==date)
        this.tabDetailHeureSupp[index].commentaire = document.getElementById('supp_'+date+'_commentaire').textContent
    },
    convertHeureSupp(heureSupp){
        let res = ''
        if(heureSupp == Math.floor (heureSupp))
            res= heureSupp + ': 00' 
        else {
            const integer = Math.floor (heureSupp)
            
            let minute =0
            let decimal = heureSupp-integer
            minute = Math.floor((decimal*30)/0.5)
            res=integer + ':'+ minute
        }
        return res
    },
    calculateFinSupp(debut,supp){
        const tabDebut = debut.split(':');
        const hour = parseInt(tabDebut[0])
        const min = parseInt(tabDebut[1])
        const heureSupp = parseInt(supp.split(':')[0])
        const minSupp = parseInt(supp.split(':')[1])
        let sommeHeure = hour+heureSupp
        let sommeMinute = minSupp+min
        if(sommeHeure>23){
            sommeHeure = sommeHeure%24
        }
        while(sommeMinute>59){
            let diff = sommeMinute - 60 
            sommeMinute = diff
            sommeHeure++
        }
        let heureString = sommeHeure
        if(sommeHeure<10)   heureString = "0"+sommeHeure
        let minuteString = sommeMinute
        if(sommeMinute<10)   minuteString = "0"+sommeMinute
        var fin = heureString + ":" + minuteString
        
        return fin
    },
    addInTabSupp(day, month, value){
        console.log(this.tabDetailHeureSupp)
            const index = this.tabDetailHeureSupp.findIndex(item=> item.date==day+"/"+month)
            var newSupp = {
                        "date" : day+"/"+month,
                        "debut" : "17:00", 
                        "fin" : this.calculateFinSupp("17:00",this.convertHeureSupp(value)), 
                        "detail" :  this.convertHeureSupp(value), 
                        "commentaire" : ""
                    }
            if(index==-1)
                this.tabDetailHeureSupp.push(newSupp)
            else this.tabDetailHeureSupp[index].detail = this.convertHeureSupp(value) 
            console.log(this.tabDetailHeureSupp)
        
    },
    changeTdContent(index, libelle){
        let startID = "td"
        if(this.fromDatabase) startID = 'tdb'
        
        if(libelle!="supp" && libelle!="astreinte" ){
            if(this.sumByDay(index)<=1 ) //verifier si le total de par jour ne dépasse pas
            {
                if(document.getElementById(startID+'_'+index+'_'+libelle).textContent==1 || document.getElementById(startID+'_'+index+'_'+libelle).textContent==0.5 || document.getElementById(startID+'_'+index+'_'+libelle).textContent==""){
                    //const day = index+1
                    //console.log("Content exact pour la date "+ day +"  "+ this.month , libelle, this.projetBulma, this.projetBulmaTeletravail)
                    
                    if(libelle=="site")
                        this.clienteleSite[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="teletravail")
                        this.clienteleTeletravail[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="deplacement")
                        this.clienteleDeplacement[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="presentation")
                        this.clientelePresentation[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="CP")
                        this.aConge[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="RT")
                        this.aRtt[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="MA")
                        this.aMaladie[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="FO")
                        this.aFormation[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="CS")
                        this.aCongeSSolde[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="CX")
                        this.aCongeExc[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="projetBulma")
                        this.projetBulma[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="projetBulmaTeletravail")
                        this.projetBulmaTeletravail[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="projetInterne")
                        this.projetInterne[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="structureDomremy")
                        this.structureDomremy[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="structureTeletravail")
                        this.structureTeletravail[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="ICDomremy")
                        this.intercontratDomremy[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    else if(libelle=="ICTeletravail")
                        this.intercontratTeletravail[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    
                }
                else{
                    document.getElementById(startID+'_'+index+'_'+libelle).textContent=""
                }
            }else document.getElementById(startID+'_'+index+'_'+libelle).textContent=""
            
        }else{
            var day = index+1
            if(index+1<10)
                day = "0"+day
            var month = this.month
            if(this.month<10)
                month = "0"+this.month
                if(document.getElementById(startID+'_'+index+'_'+libelle).textContent!=''){
                    if(libelle=="supp"){
                        this.supp[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                        this.addInTabSupp(day, month, this.supp[index])
                    }
                    else if(libelle=="astreinte"){
                        this.astreinte[index]= parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)
                    }
                }
                else {
                    document.getElementById(startID+'_'+index+'_'+libelle).textContent=""
                    if(document.getElementById('supp_'+day+'/'+month)){
                        document.getElementById('supp_'+day+'/'+month).remove()
                        const index = this.tabDetailHeureSupp.findIndex(item=> item.date==day+"/"+month)
                        this.tabDetailHeureSupp.splice(index, 1)
                    }
                    if(libelle=="supp") this.supp[index] = 0
                    else if(libelle=="astreinte") this.astreinte[index] = 0
                }
            
           
            //else document.getElementById(startID+'_'+index+'_'+libelle).textContent=""
        }
        this.sumDays = [], this.sumColumns = []
        for(let i=0; i<this.daysInMonth; i++){
            const value = this.sumByDay(i)
            if(value>1){
                //if(libelle=='supp')
                    document.getElementById(startID+'_'+index).class='plusDeUn' 
                //else document.getElementById(startID+'_'+index+'_'+libelle).textContent==''
            }
            //else if(value<=1 && value>0) document.getElementById('tdb_'+index).class='correct' 
            this.sumDays.push(value)
        }
        for( let i=0; i<this.libelles.length; i++){
            this.sumColumns.push(this.sumByColumn(this.libelles[i]))
        }
        this.calculSum()
    },
    sumByDay(index){
        let startID = "td"
        if(this.fromDatabase) startID = 'tdb'
        let res = 0
        for(let i=0; i<this.libelles.length; i++){
            
            let chiffre = 0;
            if(document.getElementById(startID+'_'+index+'_'+this.libelles[i]).textContent=='')
            {
                chiffre=0
            }
            else{
                if(this.libelles[i]=='supp')
                    chiffre = parseFloat(document.getElementById(startID+'_'+index+'_'+this.libelles[i]).textContent)
                else chiffre = parseFloat(document.getElementById(startID+'_'+index+'_'+this.libelles[i]).textContent)
            } 
            res += chiffre
        } 
        return res
    },
    sumByColumn(libelle){
        let startID = "td"
        if(this.fromDatabase) startID = 'tdb'
        let res = 0
        for(let i=0; i<this.daysInMonth; i++){
            let chiffre = 0;
            if(document.getElementById(startID+'_'+i+'_'+libelle).innerHTML==''){
                chiffre = 0
            }
            else  chiffre = parseFloat(document.getElementById(startID+'_'+i+'_'+libelle).innerHTML)
            res += chiffre
        }
        return res
    },

     saveTimetracker(){
        if(!this.fromDatabase){
            for(let i=0; i<this.daysInMonth; i++){
                this.clientele.push(this.clienteleSite[i]+this.clienteleTeletravail[i]+this.clienteleDeplacement[i]+this.clientelePresentation[i])
                this.absence.push(this.aConge[i]+this.aRtt[i]+this.aFormation[i]+this.aMaladie[i]+this.aCongeSSolde[i]+this.aCongeExc[i])
                this.projet.push(this.projetBulma[i]+this.projetBulmaTeletravail[i]+ this.projetInterne[i])
                this.structure.push(this.structureDomremy[i]+this.structureTeletravail[i])
                this.intercontrat.push(this.intercontratDomremy[i]+this.intercontratTeletravail[i])
            }
            
            this.timetracker = {
                name : this.user.last_name, 
                firstname : this.user.first_name, 
                daysFormatted : this.tabDate, 
                sumDays : this.sumDays,
                clientele : this.sClientele,
                
                year : this.year,
                month : this.months[this.month-1],
                jours_ouvrable : this.compteurJourOuvre,
                daysTimetracker : this.days,
                jours_saisi : this.jourSaisi, 
                missed_day : this.sumAbsence(),
                interne_day: this.sInterne,
                suppDay : this.sSupp,
                clienteleday : this.clientele,
                clienteleSite : this.clienteleSite, 
                clienteleTeletravail : this.clienteleTeletravail, 
                clienteleDeplacement : this.clienteleDeplacement, 
                clientelePresentation : this.clientelePresentation, 
                
                cid_sur_site : this.cid_sur_site,
                cid_en_teletravail : this.cid_en_teletravail,
                cid_deplacement : this.cid_deplacement,
                cid_presentation : this.cid_presentation,

                absence : this.absence, 
                aConge : this.aConge,
                aMaladie : this.aMaladie, 
                aFormation : this.aFormation, 
                aRtt : this.aRtt, 
                aCongeSSolde : this.aCongeSSolde, 
                aCongeExc : this.aCongeExc,
                isValid : 'NO',
                projet : this.projet,
                structure : this.structure,
                intercontrat : this.intercontrat, 
                supp : this.supp, 
                astreinte : this.astreinte, 
                note : this.note, 
                projetBulma : this.projetBulma,
                projetBulmaTeletravail :this.projetBulmaTeletravail,
                projetInterne : this.projetInterne,
                structureDomremy : this.structureDomremy,
                structureTeletravail : this.structureTeletravail,
                intercontratDomremy : this.intercontratDomremy,
                intercontratTeletravail : this.intercontratTeletravail,

                detailHeureSupp : this.tabDetailHeureSupp

            }
            var tab = []
            tab.push(this.timetracker)
            var jsonTimetracker = JSON.stringify(tab)
            // console.log(jsonTimetracker)
             axios.post(data.baseUrl + "timetracker-collab/add/",JSON.parse(jsonTimetracker))
            .then(response => {
                //console.log(response.data)
                if(!JSON.stringify(response.data).includes("Error")){
                    toast.success("Timetracker enregistré") 
                }
                else {
                    toast.error(JSON.stringify(response.data)) 
                }
                setTimeout(()=>this.$router.go(), 1500)
            })
            .catch(error => console.log(error))
        }else{
            this.updateTimetracker()
        }
        
        
        
    },

    sumClientele(){
        return  this.sumColumns[6]+this.sumColumns[7]+this.sumColumns[8]+this.sumColumns[9]
    },
    onChangeCLIENT(event, element){
        if(element == "site")
            this.cid_sur_site = event.target.value
        else if(element == "teletravail")
            this.cid_en_teletravail = event.target.value
        else if(element == "deplacement")
            this.cid_deplacement = event.target.value
        else
            this.cid_presentation = event.target.value
        //console.log(this.cid_sur_site, this.cid_en_teletravail, this.cid_deplacement, this.cid_presentation)
    },
    sumAbsence(){
        return  this.sumColumns[0]+this.sumColumns[1]+this.sumColumns[2]+this.sumColumns[3]+this.sumColumns[4]+this.sumColumns[5]
    },
   sumInterne(){
        return  this.sumColumns[10]+this.sumColumns[11]+this.sumColumns[12]+this.sumColumns[13]+this.sumColumns[14]+this.sumColumns[15]+this.sumColumns[16]
    },
    sumSupp(){
        return  this.sumColumns[17]+this.sumColumns[18]
    },
    
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD')
          }
    },
    format_date2(value){
         if (value) {
           return moment(String(value)).format('yyyy-MM-DD')
          }
    },
    replaceWithBr(message) {
        return message.replace(/\n/g, "<br />")
    },
    updateTimetracker(){
        this.projet= []
        this.intercontrat= []
        this.clientele = []
        this.structure = []
        this.absence = []
        console.log(this.structureDomremy ,  this.structureTeletravail)
        for(let i=0; i<this.daysInMonth; i++){
                this.clientele.push(this.clienteleSite[i]+this.clienteleTeletravail[i]+this.clienteleDeplacement[i]+this.clientelePresentation[i])
                this.absence.push(this.aConge[i]+this.aRtt[i]+this.aFormation[i]+this.aMaladie[i]+this.aCongeSSolde[i]+this.aCongeExc[i])
                this.projet.push(this.projetBulma[i]+this.projetBulmaTeletravail[i]+ this.projetInterne[i])
                this.structure.push(this.structureDomremy[i]+this.structureTeletravail[i])
                this.intercontrat.push(this.intercontratDomremy[i]+this.intercontratTeletravail[i])
            }
            var payload = {
                "_id" : this.timetracker._id,
                "aConge" :this.aConge,
                "aRtt" : this.aRtt,
                "aMaladie" : this.aMaladie,
                "aCongeSSolde" : this.aCongeSSolde,
                "aFormation" : this.aFormation, 
                "aCongeExc" :this.aCongeExc,
                "clienteleDeplacement" :this.clienteleDeplacement,
                "clienteleSite" : this.clienteleSite,
                "clienteleTeletravail" : this.clienteleTeletravail,
                "clientelePresentation" : this.clientelePresentation,
                "projetBulma" : this.projetBulma,
                "projetBulmaTeletravail" :this.projetBulmaTeletravail,
                "structure" : this.structure,
                "projetInterne" : this.projetInterne,
                "structureDomremy" : this.structureDomremy,
                "structureTeletravail" : this.structureTeletravail,
                "intercontratDomremy" : this.intercontratDomremy,
                "intercontratTeletravail" : this.intercontratTeletravail,
                "projet" : this.projet,
                "intercontrat" : this.intercontrat,
                "clienteleday" : this.clientele,
                "absence" : this.absence, 
                "clientele" : this.sClientele,
                "note" : this.note,
                "sumDays" :this.sumDays,
                "supp" : this.supp,
                "missed_day" :this.sumAbsence(),
                "interne_day": this.sInterne,
                "suppDay" : this.sSupp,
                "astreinte" : this.astreinte,
                "jours_saisi" :this.jourSaisi,
                "cid_sur_site" : this.cid_sur_site,
                "cid_en_teletravail" : this.cid_en_teletravail,
                "cid_deplacement" : this.cid_deplacement,
                "cid_presentation" : this.cid_presentation,
                "detailHeureSupp" : this.tabDetailHeureSupp
            }
            console.log( payload)
            axios.put(data.baseUrl + "timetracker-collab/update/", payload)
            .then(response => {
                if(!JSON.stringify(response.data).includes('Error'))
                {
                    toast.success("Timetracker enregistré")
                } 
                else toast.error(JSON.stringify(response.data).includes('Error'))
                setTimeout(()=>this.$router.go(), 1500)
            })
            .catch(error => console.log(error))
    },
    logout(){
            localStorage.removeItem('user')
            this.$router.push('/login')
        },
    }, 
    unmounted(){
        this.rttChecked=[]
        this.companieChecked=[]
    }
}
</script>
<style>
.nav{
    background :#024013;
    height: 60px;
    align-content: center;
    color: white;
    z-index: 0;
    align-items: center;
    padding-right : 20px; 
    margin-bottom: 10px;
    width: 100%;
}

.form-inline h2{
    color: white; 
    margin-top: 3px;
  }
ul {
    padding-bottom: 20px
}

ul li a img {
    background: #66BB6A;
    top: 0;
    border: none;
    width: 20px
}
.main{
    transition: margin-left .5s;
    margin-left: 20px;
}
#content{
    margin-left: 20px;
}
td{
    text-align: center;
}

.tDetails{
    text-align: left;
    background-color: #10964c;
}
.carousel__item  {
     display: block;
     overflow: hidden;
     position: static;
     /*height: 500px !important;*/
     
 }
 .carousel__slide{
     width: 50%;
     margin-left : 20px
      
 }
 .null1{
     background-color: #665e5e33;
     width:2px;
 }
 .MA{
     background-color: #E37D0C;
     color : white; 
     width:2px
 }
 .JT{
     background-color: white;
     color : #000; 
     width:2px
 }
 .CP{
     background-color: #9fe0bc;
     color : white; 
     width:2px
 }
 .IC{
     background-color: #6C93A6;
     color : white;
     width:2px
 }
 .TM{
     background-color: #794415;
     color : white;
     width:2px
 }
.CX{
     background-color: #146791;
     color : white;
     width:2px
 }
 .CS{
     background-color: #368eb9;
     color : white;
     width:2px
 }
.RT{
     background-color: #10964c;
     color : white;
     width:2px
 }
 .TI{
     background-color: #494e4bdc;
     color : white;
     width:2px
 }
 .IR{
     background-color: #494e4b;
     color : white;
     width:2px
 }
 .TR{
     background-color: #025a25;
     color : white;
     width:2px
 }
 .AP{
     background-color: #f7e43c;
     width:2px
 }
 .CI{
    background-color: #494e4bdc;
   
     width:2px
 }
 .titleTab{
     background-color: rgba(4, 78, 60, 0.753);
     color : white
 }
 .plusDeUn{
     background-color: purple;
     color: white;
 }
 .correct{
     background-color: green;
     color: white;
 }
 .manqueHeure{
     background-color: #fab833;
     color: white;
 }

.bgGreen {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: rgb(202, 255, 241);
  color: black;
}

th{
    text-align: center;
}

thead tr:nth-child(1) th { position: sticky; top: 0;}
thead tr:nth-child(2) th { position: sticky; top: 43px;}
thead tr:nth-child(3) th { position: sticky; top: 86px;}
</style>