<template>
 <div class="nav">
         <Header :readFile="true" page="annuaire"/>
  </div>
    <div class="content p-2">
    <div class="row">
      <div class="mt-1 form-inline">
          <h2 class="form-control " style="background-color: #0c0c56">RECHERCHE </h2>
          <input class="form-control" v-model="search"/>
      </div>
      <!--div style="overflow-x:auto;"-->
          <table class="table table-striped">
              <tr class="head text-center">
                  
                  <th class="text-center">Prénom</th>
                  <th class="text-center">Nom</th>
                  <th class="text-center">Société</th>
                  <th class="text-center">RTT</th>
                  <th class="text-center">Téléphone</th>
                  <th class="text-center">Adresse</th>
                  <th class="text-center">Date de naissance</th>
                  <th class="text-center">Ville </th>
                  <th class="text-center">CP</th>
                  <th class="text-center">Date d'entrée</th>
                  <th></th>
              </tr>
              <tr v-for="item in filterUsers().slice(this.startRow,this.startRow+this.rowsPerPage)" :key="item._id">
                <td>{{item.first_name}}</td>
                <td>{{item.last_name}}</td>
                <td v-if='item.companyDetails[0]'>{{item.companyDetails[0].abbreviation}}</td>
                <td v-else></td>
                <td v-if='item.rttDetails[0]'>{{item.rttDetails[0].first_name }} {{ item.rttDetails.last_name}}</td>
                <td v-else></td>
                <td>{{item.userDetails.tel_no}}</td>
                <td> {{item.userDetails.address}}</td>
                <td>{{format_date(item.userDetails.dob)}}</td>
                <td>{{item.userDetails.town}} </td>
                <td>{{item.userDetails.postal_code}}</td>
                <td>{{format_date(item.userDetails.entry_date)}}</td>
                <!--td><router-link :to="{ name: 'EditCollab', params: { id: item._id } }"><i  class="fas fa-edit" /></router-link></td-->
              </tr>
            </table>
            <!--/div-->
              <div id="page-navigation" class="float-right" v-if="filterUsers().length>rowsPerPage">
                <button class="btn btn-primary text-white" @click=movePage(-1)>Précédent</button>
                {{startRow / rowsPerPage + 1}} page sur {{Math.ceil(filterUsers().length / rowsPerPage)}}
                <button class="btn btn-primary text-white" @click=movePage(1)>Suivant</button>
              </div> 
    </div>
    </div>
</template>

<script>


import axios from 'axios'
import data from '@/data/data'

import moment from 'moment'

import store from '../store'
import Header from '@/components/Header.vue';
export default {
  name: 'Annuaire',
  components: {
    Header
  },
  mounted(){
    try{
        console.log("USER RR  ///// ", this.user)
        if(this.user.id == -1)
            this.$router.push('/login')
    }catch(error){
            console.error(error)
    }
    const getCellValue = (tr, idx) => tr.children[idx].innerText || tr.children[idx].textContent;
    
    const compare = (idx, asc) => (a, b) => ((v1, v2) => 
        v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
        )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    
    document.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
        const table = th.closest('table');
        Array.from(table.querySelectorAll('tr:nth-child(n+2)'))
            .sort(compare(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
            .forEach(tr => table.appendChild(tr) );
    })));
    console.log("RTTCHECKED : ", this.rttChecked)
    axios.get(data.baseUrl + "users")
        .then(response => {
        console.log(response.data)
          this.collaborateurs = response.data
        })
        .catch(error => console.log(error))

    axios.post(data.baseUrl + "users/fetch-user-by-title", {"name" : "RTT"})
        .then(response => {
        console.log(response.data)
          this.rtts = response.data
        })
        .catch(error => console.log(error))
    
    axios.get(data.baseUrl + "companies")
        .then(response => {
        console.log(response.data)
          this.companies = response.data
        })
        .catch(error => console.log(error))
  },
  
  data(){
    return{
      user : store.state.user,
      collaborateurs : [], 
      rtts : [], 
      companies : [], 
      search : '', 
      idRtt : '', 
      idCompanie : '', 
      rttChecked :[], 
      companieChecked : [],
      startRow: 0,
      rowsPerPage: 15,
    }
  },
  computed:{
    
  },
  methods: {
    back(){
      console.log("back")
        this.$router.push("/")
    },
   
    filterUsers(){
      if(this.idRtt!=''){
        console.log("RTTT : " +this.idRtt)
        axios.post(data.baseUrl + "users/fetch-user-by-rtt", {"_id" : this.idRtt})
          .then(response => {
            console.log(response.data)
            return response.data
          })
          .catch(error => console.log(error))
      }
      if(this.idCompanie!=''){
        axios.post(data.baseUrl + "users/fetch-user-by-companie", {"_id" : this.idCompanie})
          .then(response => {
            console.log(response.data)
            return response.data
          })
          .catch(error => console.log(error))
      }
      if(this.search!="") {
          this.startRow=0
          return this.collaborateurs.filter(item => {
            return item.first_name.toLowerCase().includes(this.search.toLowerCase()) || item.last_name.toLowerCase().includes(this.search.toLowerCase())
      
          })
      }
      else return  this.collaborateurs
    }, 
    
    movePage(amount) {
        console.log("MOVE PAGE")
        var newStartRow = this.startRow + (amount * this.rowsPerPage);
        if (newStartRow >= 0 && newStartRow < this.collaborateurs.length) {
          this.startRow = newStartRow;
        }
        
    },

    format_date(value){
         if (value) {
           return moment(String(value)).format('YYYY-MM-DD')
          }
    },
    logout(){
            localStorage.removeItem('user')
            this.$router.push('/login')
    },
  }, 
  unmounted(){
    this.rttChecked=[]
    this.companieChecked=[]
  }
}
</script>
<style>
  .form-inline h2{
    color: white; 
    margin-top: 3px;
  }
ul {
    padding-bottom: 20px
}

ul li a img {
    background: #66BB6A;
    top: 0;
    border: none;
    width: 20px
}
.main{
    transition: margin-left .5s;
}
.content{
  margin: 20px;
}
.head{
  background-color: lightseagreen;
  color: white;
}
</style>