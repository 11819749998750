<template>
    <div id="timetracker">
        <div class="nav" v-show="showNav">
            <Header :readFile="true" />

        </div>

        <link ref="https://use.fontawesome.com/releases/v5.7.2/css/all.css" />
        <div class="container-fluid">

            <div class="alert alert-danger" id="error-alert" v-if="erreur != ''">

                <!--<p id="message_error"><strong>ERREUR ! </strong> </p>
                <div v-html="replaceWithBr(erreur)"></div-->
                <p :v-model="erreur">{{ erreur }}</p>

                <button class="btn btn-danger" v-show="user.role == 5 && tPrev.isValid == 'YES'"
                    @click="annulerValidation(tPrev, timetracker._id)"> Annuler validation </button>
            </div>
            <div class="alert alert-success" id="success-alert" v-else>

                <p :id="'message_error_' + timetracker._id"><strong>Timetracker correct {{ tPrev.isValid == 'YES' ?
                    'et validé!' : '!' }} </strong> </p>
                <button class="btn btn-danger" v-show="user.role == 5 && tPrev.isValid == 'YES'"
                    @click="annulerValidation(tPrev, timetracker._id)"> Annuler validation </button>
            </div>

            <div v-if='timetracker' id="divToPrint">
                <div class="card p-3 " style="background-color: hwb(41deg 5% 11%)">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-10">
                            <h2 class="text-white">{{ timetracker.name }} {{ timetracker.firstname }} - Mois
                                {{ months[month - 1] }} {{ year }} </h2>
                        </div>
                        <div class="col-2">
                            <button style="float-right" v-if="timetracker.isValid == 'YES'" class="btn btn-outline-success"
                                @click="exportToExcel('TMT - ' + month + '/' + year + ' - ' + timetracker.name + ' ' + timetracker.firstname + '.xlsx')">Exporter</button>
                            <button v-if="user.role == 5" :disabled="timetracker.isValid == 'YES'"
                                style="background-color: #a57e57" :id='"btn_" + timetracker._id + "_1"'
                                class="btn text-white"
                                @click="valider(tPrev._id, timetracker._id, timetracker.user_id)">Valider</button>
                        </div>
                    </div>

                </div>
                <div class="container ">
                    <div class="row pl-5 pr-5">
                        <div class="col-sm-6 p-3 pl-2">
                            <table style="border-collapse:separate; border-spacing: 0 10px;">
                                <tr>
                                    <td class="JT p-2"> JT</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">Jour Travaillé</td>
                                </tr>
                                <tr>
                                    <td class="MA p-2"> MA</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">Maladie</td>
                                </tr>
                                <tr>
                                    <td class="CP p-2 "> CP</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">Congé payé</td>
                                </tr>

                                <tr>
                                    <td class="CI p-2">CI</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">1/2 Congé / Intercontrat</td>
                                </tr>
                                <tr class="mb-1">
                                    <td class="CX p-2"> CX</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">Congé exceptionnel</td>
                                </tr>
                                <tr>
                                    <td class="TI">TI</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">1/2 Travail / Intercontrat</td>
                                </tr>
                                <tr class="mb-1">
                                    <td class="CS"> CS</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">Congé sans solde</td>
                                </tr>


                            </table>

                        </div>
                        <div class="col-sm-6 p-3">
                            <table style="border-collapse:separate; border-spacing: 0 10px;">
                                <tr class="mb-1">
                                    <td class="RT p-2"> RT</td>
                                    <td></td>
                                    <td class="p-1 p-2" style="text-align: left">RTT</td>
                                </tr>
                                <tr>
                                    <td class="TC p-2"> TC</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">1/2 Travail / Congé payé</td>
                                </tr>
                                <tr>
                                    <td class="TM p-2"> TM</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">1/2 Travail / Maladie </td>
                                </tr>
                                <tr>
                                    <td class="TR p-2"> TR</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">1/2 Travail / RTT</td>
                                </tr>
                                <tr class="mb-1">
                                    <td class="IR p-2"> IR</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">1/2 Intercontrat / RTT</td>
                                </tr>

                                <tr>
                                    <td class="IC p-2"> IC</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">Intercontrat</td>
                                </tr>
                                <tr>
                                    <td class="AP">AP</td>
                                    <td></td>
                                    <td class="p-2" style="text-align: left">Absence prévisionnelle</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <fieldset class="border p-4">
                    <legend class="w-auto pt-4">Timetracker prévisionnel</legend>
                    <div style="overflow-x:auto">
                        <table class="table table-bordered" width='100%' v-if="tPrev">

                            <thead class="sticky-top">
                                <th style="width:50px;">NOM</th>
                                <th style="width:50px;">Prénom</th>

                                <th style="width:2px; text-align:center" v-for='index in daysInMonth' :key="index">
                                    <b>{{ index }}</b>
                                    <b hidden>{{ index + 1 }}</b>
                                </th>

                            </thead>
                            <tbody>


                                <tr>
                                    <td class="fixed-width">{{ tPrev.user ? tPrev.user.last_name : "" }}</td>
                                    <td class="fixed-width">{{ tPrev.user ? tPrev.user.first_name : "" }}</td>

                                    <td :contenteditable="tPrev.isValid == 'NO' ? true : false"
                                        :class="code.code_abbrev ? code.code_abbrev + ' limit_2' : 'null limit_2'"
                                        @blur="changeCodePrev(index, tPrev, timetracker)"
                                        @input="limit_2('td' + index + '_' + tPrev.user_id)"
                                        :id="'td' + index + '_' + tPrev.user_id" style="width:2px;text-transform:uppercase"
                                        v-for='(code, index) in tPrev.tt_by_day' :key="index">
                                        <strong>{{ code.code_abbrev }} </strong>
                                    </td>
                                </tr>
                            </tbody>

                        </table>

                    </div>
                    <!--button :disabled="timetracker.isValid=='YES'" style="background-color: #a57e57" :id='"btn_"+timetracker._id'  class="btn btn-block text-white" @click="valider(tPrev, timetracker._id, timetracker.user_id)" >Valider</button-->
                </fieldset>
                <fieldset class="border p-4">
                    <legend class="w-auto pt-4">Timetracker du collaborateur</legend>
                    <div style="overflow-x:auto">

                        <p class="form-control" v-if="timetracker.note != ''"><b>Note :</b> {{ timetracker.note }}</p>
                        <div style="max-height: 900px;">
                            <table class="table table-bordered" width='100%' id="tableToExport" ref="table">

                                <thead class="sticky-top">
                                    <tr>
                                        <th style="width: 250px;" class="bgGreenTC">Nbre jours ouvrés</th>
                                        <th style="width: 50px" class="bgGreenTC text-center">{{ timetracker.jours_ouvrable
                                        }}</th>
                                        <th class="bgGreenTC text-center" style="width: 1px"
                                            v-for="(item, index) in daysInMonth" :key="index">
                                            <b>{{ item }}</b>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th class="bgGreenTC"></th>
                                        <th class="bgGreenTC"></th>
                                        <th style="width: 1px" v-for="(item, index) in days" :key="index" class="bgGreenTC">
                                            {{ item }}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style="background-color: rgb(202, 255, 241);">Nbre jours saisis</th>
                                        <th style="background-color: rgb(202, 255, 241);">{{ timetracker.jours_saisi }}</th>
                                        <th :id="'tdb_' + index" style="width: 1px"
                                            :class="item == 0 ? 'normal' : item <= 1 ? 'correct' : 'plusDeUn'"
                                            v-for="(item, index) in sumDays" :key="index">
                                            <span style="font-style : italic;">{{ item != 0 ? item : '' }}</span>
                                            <!--{{sumByDay(index)}}-->
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="titleTab">
                                        <td>Absence </td>
                                        <td>{{ timetracker.missed_day }}</td>
                                        <td :COLSPAN="daysInMonth"></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><span style="float: left">Congés</span> <span style="float: right">{{
                                            sumColumns[0] != 0 ? sumColumns[0] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in aConge" :key="index" :id="'tdb_' + index + '_CP'"
                                            @blur="changeTdContent(index, 'CP')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><span style="float: left">RTT</span> <span style="float: right">{{ sumColumns[1]
                                            != 0 ? sumColumns[1] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in aRtt" :key="index" :id="'tdb_' + index + '_RT'"
                                            @blur="changeTdContent(index, 'RT')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><span style="float: left">Arrêt maladie</span><span style="float: right">{{
                                            sumColumns[2] != 0 ? sumColumns[2] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in aMaladie" :key="index" :id="'tdb_' + index + '_MA'"
                                            @blur="changeTdContent(index, 'MA')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><span style="float: left">Formation </span><span style="float: right">{{
                                            sumColumns[3] != 0 ? sumColumns[3] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in aFormation" :key="index" :id="'tdb_' + index + '_FO'"
                                            @blur="changeTdContent(index, 'FO')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><span style="float: left">Congés exc.</span><span style="float: right">{{
                                            sumColumns[4] != 0 ? sumColumns[4] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in aCongeExc" :key="index" :id="'tdb_' + index + '_CX'"
                                            @blur="changeTdContent(index, 'CX')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><span style="float: left">Congés s. solde</span><span style="float: right">{{
                                            sumColumns[5] != 0 ? sumColumns[5] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in aCongeSSolde" :key="index" :id="'tdb_' + index + '_CS'"
                                            @blur="changeTdContent(index, 'CS')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr class="titleTab">
                                        <td>Clientèle</td>
                                        <td>{{ timetracker.clientele }}</td>
                                        <td :COLSPAN="daysInMonth"></td>
                                    </tr>
                                    <tr>
                                        <td>{{ getClient(cid_sur_site)[0] ? getClient(cid_sur_site)[0].name : '' }}</td>
                                        <td><span style="float: left">Sur site</span><span style="float: right">{{
                                            sumColumns[6] != 0 ? sumColumns[6] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in clienteleSite" :key="index"
                                            :id="'tdb_' + index + '_site'" @blur="changeTdContent(index, 'site')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ getClient(cid_en_teletravail)[0] ? getClient(cid_en_teletravail)[0].name : ''
                                        }}
                                        </td>
                                        <td><span style="float: left">Télétravail</span> <span style="float: right">{{
                                            sumColumns[7] != 0 ? sumColumns[7] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in clienteleTeletravail" :key="index"
                                            :id="'tdb_' + index + '_teletravail'"
                                            @blur="changeTdContent(index, 'teletravail')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ getClient(cid_deplacement)[0] ? getClient(cid_deplacement)[0].name : '' }}
                                        </td>
                                        <td><span style="float: left">Déplacement</span><span style="float: right">{{
                                            sumColumns[8] != 0 ? sumColumns[8] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in clienteleDeplacement" :key="index"
                                            :id="'tdb_' + index + '_deplacement'"
                                            @blur="changeTdContent(index, 'deplacement')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ getClient(cid_presentation)[0] ? getClient(cid_presentation)[0].name : '' }}
                                        </td>
                                        <td><span style="float: left">Présentation</span><span style="float: right">{{
                                            sumColumns[9] != 0 ? sumColumns[9] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in clientelePresentation" :key="index"
                                            :id="'tdb_' + index + '_presentation'"
                                            @blur="changeTdContent(index, 'presentation')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr class="titleTab">
                                        <td>Interne</td>
                                        <td>{{ timetracker.interne_day }}</td>
                                        <td :COLSPAN="daysInMonth"></td>
                                    </tr>
                                    <tr>
                                        <td rowspan="3" class="text-center">Projet</td>
                                        <td><span style="float: left"> Bulma </span><span style="float: right">{{
                                            sumColumns[10] != 0 ? sumColumns[10] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in projetBulma" :key="index"
                                            :id="'tdb_' + index + '_projetBulma'"
                                            @blur="changeTdContent(index, 'projetBulma')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>

                                        <td><span style="float: left"> Bulma télétravail</span><span style="float: right">{{
                                            sumColumns[11] != 0 ? sumColumns[11] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in projetBulmaTeletravail" :key="index"
                                            :id="'tdb_' + index + '_projetBulmaTeletravail'"
                                            @blur="changeTdContent(index, 'projetBulmaTeletravail')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span style="float: left">Projet interne</span><span style="float: right">{{
                                            sumColumns[12] != 0 ? sumColumns[12] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in projetInterne" :key="index"
                                            :id="'tdb_' + index + '_projetInterne'"
                                            @blur="changeTdContent(index, 'projetInterne')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2">Structure</td>
                                        <td><span style="float: left">Domrémy</span><span style="float: right">{{
                                            sumColumns[13] != 0 ? sumColumns[13] : '' }}</span></td>
                                        <td :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in structureDomremy" :key="index"
                                            :id="'tdb_' + index + '_structureDomremy'"
                                            @blur="changeTdContent(index, 'structureDomremy')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span style="float: left">Télétravail</span><span style="float: right">{{
                                            sumColumns[14] != 0 ? sumColumns[14] : '' }}</span></td>
                                        <td style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                        :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            v-for="(item, index) in structureTeletravail" :key="index"
                                            :id="'tdb_' + index + '_structureTeletravail'"
                                            @blur="changeTdContent(index, 'StructureTeletravail')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2">Intercontrat</td>
                                        <td><span style="float: left">Domrémy</span><span style="float: right">{{
                                            sumColumns[15] != 0 ? sumColumns[15] : '' }}</span></td>
                                        <td style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            v-for="(item, index) in intercontratDomremy" :key="index"
                                            :id="'tdb_' + index + '_ICDomremy'" @blur="changeTdContent(index, 'ICDomremy')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span style="float: left">Télétravail</span><span style="float: right">{{
                                            sumColumns[15] != 0 ? sumColumns[15] : '' }}</span></td>
                                        <td style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            v-for="(item, index) in intercontratTeletravail" :key="index"
                                            :id="'tdb_' + index + '_ICTeletravail'"
                                            @blur="changeTdContent(index, 'ICTeletravail')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                    <tr class="titleTab">
                                        <td>Heure Supplémentaire</td>
                                        <td>{{ sSupp }}</td>
                                        <td :COLSPAN="daysInMonth"></td>
                                    </tr>
                                    <tr>
                                        <td>Entrer en heure</td>
                                        <td><span style="float: left">Trav supp(h) </span><span style="float: right">{{
                                            sumColumns[12] != 0 ? sumColumns[12] : '' }}</span></td>
                                            <!-- :contenteditable="timetracker.isValid == 'YES' ? false : true" -->
                                        <td style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in supp" :key="index"
                                            :contenteditable="timetracker.isValid == 'YES' ? false : true"
                                            :id="'tdb_' + index + '_supp'" @blur="changeTdContent(index, 'supp')">
                                            {{ item == 0 ? '' : item }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td><span style="float: left">Astreinte</span><span style="float: right">{{
                                            sumColumns[13] != 0 ? sumColumns[13] : '' }}</span></td>
                                        <td style="width: 1px" :class="classIfWeekEnd(index + 1, this.month)"
                                            v-for="(item, index) in astreinte" :key="index"
                                            :id="'tdb_' + index + '_astreinte'"
                                            
                                            @blur="changeTdContent(index, 'astreinte')">
                                            {{ (item == 1 || item == 0.5) ? item : '' }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-sm-7 " v-show="tabDetailHeureSupp && tabDetailHeureSupp.length > 0">
                        <table class="table table-bordered">
                            <thead>
                                <th class="text-white" style="background-color: rgb(202, 117, 5);">Détail des heures
                                    supplémentaires</th>
                            </thead>
                            <tr>
                                <th>Jour (jj/mm)</th>
                                <th>Début (hh/mm)</th>
                                <th>Fin (hh/mm)</th>
                                <th>Détail</th>
                                <th>Commentaires</th>
                            </tr>
                            <tbody id="tabSuppContent">
                                <tr v-for="(heureSupp, index) in tabDetailHeureSupp" :key="index"
                                    :id="'supp_' + heureSupp.date">
                                    <td>{{ heureSupp.date }}</td>
                                    <td :id="'supp_' + heureSupp.date + '_debut'">{{ heureSupp.debut }}</td>
                                    <td :id="'supp_' + heureSupp.date + '_fin'">{{ heureSupp.fin }}</td>
                                    <td>{{ heureSupp.detail }}</td>
                                    <td :id="'supp_' + heureSupp.date + '_commentaire'">{{ heureSupp.commentaire }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fieldset>
            </div>
            <div id="content" v-else>
                <div class="card p-3 " style="background-color: hwb(41deg 5% 11%)">
                    <h2 class="text-center text-white">Timetracker du mois de {{ months[month - 1] }} non prêt</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import store from '../store'
import Header from '@/components/Header.vue';
import { useToast } from "vue-toastification";
const toast = useToast()
import * as XLSX from 'xlsx'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import data from '@/data/data'
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

export default {
    name: 'TimetrackerCollab',
    components: {
        Header
    },
    props: {
        showNav: {
            type: Boolean,
            default: true
        }
    },
    beforeCreate() {

    },
    created() {
        this.userId = this.$route.query.id
        this.month = this.$route.query.month
        this.year = this.$route.query.year
        this.years = [this.year - 2, this.year - 1, this.year]
        this.daysInMonth = new Date(this.year, this.month, 0).getDate();
        this.days = this.getDay(this.daysInMonth)
        this.weeks = this.getWeek(this.month)
        this.compteurJO = this.countJourOuvre()
        axios.post(data.baseUrl + "timetracker-collab/", { "user_id": this.userId, "month": this.months[this.month - 1], "year": parseInt(this.year) })
            .then(response => {
                console.log("timetracker database length : ", response.data.length)
                this.timetracker = response.data[0]
                this.sumDays = this.timetracker.sumDays
                this.clienteleSite = this.timetracker.clienteleSite
                this.clienteleTeletravail = this.timetracker.clienteleTeletravail
                this.clienteleDeplacement = this.timetracker.clienteleDeplacement
                this.clientelePresentation = this.timetracker.clientelePresentation
                this.aConge = this.timetracker.aConge
                this.aMaladie = this.timetracker.aMaladie
                this.aFormation = this.timetracker.aFormation
                this.aRtt = this.timetracker.aRtt
                this.aCongeSSolde = this.timetracker.aCongeSSolde
                this.aCongeExc = this.timetracker.aCongeExc
                this.projet = this.timetracker.projet
                this.intercontrat = this.timetracker.intercontrat
                this.supp = this.timetracker.supp
                this.astreinte = this.timetracker.astreinte
                this.sSupp = this.timetracker.suppDay
                this.cid_sur_site = this.timetracker.cid_sur_site
                this.cid_en_teletravail = this.timetracker.cid_en_teletravail
                this.cid_deplacement = this.timetracker.cid_deplacement
                this.cid_presentation = this.timetracker.cid_presentation
                this.projetBulma = this.timetracker.projetBulma,
                this.projetBulmaTeletravail = this.timetracker.projetBulmaTeletravail,
                this.projetInterne = this.timetracker.projetInterne,
                this.structureDomremy = this.timetracker.structureDomremy,
                this.structureTeletravail = this.timetracker.structureTeletravail,
                this.intercontratDomremy = this.timetracker.intercontratDomremy,
                this.intercontratTeletravail = this.timetracker.intercontratTeletravail,
                this.structure = this.timetracker.structure
                this.tabDetailHeureSupp = this.timetracker.detailHeureSupp
                axios.post(data.baseUrl + "timetracker/get-details/", { "user_id": this.timetracker.user_id, "month": parseInt(this.month), "year": parseInt(this.year) })
                    .then(response => {
                        this.tPrev = response.data[0]
                        this.erreur = this.compare(this.timetracker, this.tPrev)
                        console.log("ERREUR")
                    }).catch(error => console.error(error))
            }).catch(error => console.log(error))
    },
    mounted() {
        try {
            if (this.user.id == -1)
                this.$router.push('/login')
        } catch (error) {
            console.error(error)
        }
        axios.get(data.baseUrl + "clients")
            .then(response => {
                this.clients = response.data

            })
        axios.get(data.baseUrl + "codetypes")
            .then(response => {
                this.etats = response.data
            })
            .catch(error => console.log(error))
        let country = 'FRA'
        if (this.user.companyDetails && this.user.companyDetails.length != 0)
            country = this.user.companyDetails[0].countryDetails.countryAlpha3
        let holidays = data.jourFerieFrance
        if (country == 'MUS')
            holidays = data.jourFerieMaurice
        holidays.then(date => {
            this.jourFerie = date
        })



    },
    data() {
        return {
            user: store.state.user,
            fromDatabase: true,
            search: '',
            startRow: 0,
            rowsPerPage: 20,
            months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
            year: 0,
            month: 0,
            daysInMonth: 0,
            days: [],
            readFile: false,
            years: [],
            weeks: [],
            timetracker: {},
            error: false,
            compteurJourOuvre: 0,
            compteurJO: 0,
            sumDays: [],
            libelles: ["CP", "RT", "MA", "FO", "CX", "CS", "site", "teletravail", "deplacement", "presentation", "projetBulma", "projetBulmaTeletravail", "projetInterne", "structureDomremy", "structureTeletravail", "ICDomremy", "ICTeletravail", "supp", "astreinte"],
            sumColumns: [],
            clienteleSite: [],
            clienteleTeletravail: [],
            clienteleDeplacement: [],
            clientelePresentation: [],
            clientele: [],
            sAbsence: 0,
            sClientele: 0,
            sInterne: 0,
            sSupp: 0,
            jourSaisi: 0,

            aConge: [],
            aRtt: [],
            aFormation: [],
            aMaladie: [],
            aCongeSSolde: [],
            aCongeExc: [],
            projetBulma: [],
            projetBulmaTeletravail: [],
            projetInterne: [],
            structureDomremy: [],
            structureTeletravail: [],
            intercontratDomremy: [],
            intercontratTeletravail: [],

            cid_sur_site: '',
            cid_en_teletravail: '',
            cid_deplacement: '',
            cid_presentation: '',

            projet: [],
            structure: [],
            intercontrat: [],
            supp: [],
            astreinte: [],
            tabDate: [],
            absence: [],
            note: '',
            clients: [],
            tPrev: {},
            jourFerie: [], erreur : '', 
            tabDetailHeureSupp : []
        }
    },
    computed: {

    },
    methods: {
        limit_2(elementID) {
            var texte = document.getElementById(elementID).textContent;
            let suggestion = ''
            let codes = ['JT', 'MA', 'IC', 'CP', 'RT', 'CX', 'CS', 'TC', 'CI', 'TM', 'TI', 'AP']
            if (texte != '')
                for (let i = 0; i < codes.length; i++) {
                    let suggestionCourante = codes[i];
                    if (suggestionCourante.startsWith(texte)) {
                        suggestion = suggestionCourante;
                        break;
                    }
                }
            if (texte.length > 2) {
                document.getElementById(elementID).textContent = texte.slice(0, 2);
            }
            if (suggestion !== '') {
                document.getElementById(elementID).textContent = suggestion;
            }
        },
        async getPrevisionnel(userId) {
            let prev = {}
            await axios.post(data.baseUrl + "timetracker/get-details/", { "user_id": userId, "month": this.months[this.month - 1], "year": parseInt(this.year) })
                .then(response => {
                    //console.log(response)
                    this.tPrev = response.data[0]
                    prev = this.tPrev
                    //console.log(prev)
                    return prev
                }).catch(error => console.error(error))

        },

        sumByColumn(libelle) {
            let startID = 'tdb'
            let res = 0
            for (let i = 0; i < this.daysInMonth; i++) {
                //console.log(startID+'_'+i+'_'+libelle)  
                let chiffre = 0;
                if (document.getElementById(startID + '_' + i + '_' + libelle).innerHTML == '') {
                    chiffre = 0
                }
                else chiffre = parseFloat(document.getElementById(startID + '_' + i + '_' + libelle).innerHTML)
                res += chiffre
            }

            return res
        },
        printDocument() {
            const pdfTable = document.getElementById('divToPrint');
            var html = htmlToPdfmake(pdfTable.innerHTML);
            const documentDefinition = { content: html, pageSize: "A3", pageOrientation: 'landscape' };
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            pdfMake.createPdf(documentDefinition).open();
        },
        printDocument2(type, fn, dl) {
            var elt = document.getElementById('tableToExport');
            var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
            const worksheet = wb.Sheets["Sheet JS"];
            worksheet['!cols'][0] = { width: 28, horizontal: 'center' };
            worksheet['!cols'][1] = { width: 20, alignment: { horizontal: 'center' } };
            return dl ?
                XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
                XLSX.writeFile(wb, fn || ('.' + (type || 'xlsx')));
        },

        exportToExcel(nameFile) {
            const workbook = new ExcelJS.Workbook()
            const sheet = workbook.addWorksheet('My Sheet')
            //sheet.properties.defaultRowHeight=80
            console.log(this.timetracker)
            var column = [{
                header: '',
                key: "Title",
                width: 25
            }, {
                header: "",
                key: "column2",
                width: 20
            }, {
                //header : this.timetracker.month,
                key: "i1",
                width: 5
            }, {
                //header : this.timetracker.year,
                key: "i2",
                width: 5
            }]

            for (let i = 2; i < this.timetracker.daysFormatted.length; i++) {
                let index = i + 1
                column.push({
                    header: '',
                    key: "i" + index,
                    width: 5
                })
            }
            sheet.columns = column

            console.log(this.jourSaisi);

            // Merge cells A1:B2 and set the value to "TimeTracker" as a heading
            const mergedCell0 = sheet.getCell('A1');
            mergedCell0.value = 'TimeTracker';
            mergedCell0.font = { bold: true, size: 18 };
            mergedCell0.alignment = { horizontal: 'center', vertical: 'middle' };

            sheet.mergeCells('A1:B2');

            //sheet.addRow();

            //sheet.addRow();

            // Add a row below row number 3 with column A titled "R PLANNING"
            const row03 = sheet.addRow();
            row03.getCell('A').value = 'RÉSUMÉ PLANNING';

            // Set the yellow color for the whole row and white font color
            row03.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'A67E56' } // Yellow color code
            };

            // Set the font color to white and bold for the whole row
            row03.eachCell({ includeEmpty: true }, cell => {
                cell.font = {
                    color: { argb: 'FFFFFFFF' }, // White font color
                    bold: true
                };
            });
            // Merge columns H, I, J, and K for the date
            sheet.mergeCells('M2:Q2');
            const dateMergedCell = sheet.getCell('M2');
            dateMergedCell.value = { richText: [{ text: 'Date: ', font: { color: { argb: '0F5752' } } }] };
            dateMergedCell.value.richText.push({ text: this.timetracker.month + " " + this.timetracker.year, font: { color: { argb: 'A67E56' } } });
            dateMergedCell.alignment = { vertical: 'middle', horizontal: 'center' };
            dateMergedCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFFFF' }
            };
            dateMergedCell.border = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } }
            };
            dateMergedCell.font = {
                family: 1,
                size: 12,
                bold: true
            };

            // Merge columns M, N, O, and P with the label Title and text title
            sheet.mergeCells('S2:Y2');
            const titleMergedCell = sheet.getCell('S2');
            titleMergedCell.value = { richText: [{ text: 'Traité Par: ', font: { color: { argb: '0F5752' } } }] };
            titleMergedCell.value.richText.push({ text: this.timetracker.validFirstName, font: { color: { argb: 'A67E56' } } });
            titleMergedCell.alignment = { vertical: 'middle', horizontal: 'center' };
            titleMergedCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFFFF' }
            };
            titleMergedCell.border = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } }
            };
            titleMergedCell.font = {
                family: 1,
                size: 12,
                bold: true
            };

            // Merge columns H, I, J and K
            sheet.mergeCells('M1:Q1');
            // Set label "Date" and date value in the merged cell, with label in blue and date in red
            const mergedCell = sheet.getCell('M1');
            mergedCell.value = { richText: [{ text: 'NOM: ', font: { color: { argb: '0F5752' } } }] };
            mergedCell.value.richText.push({ text: this.timetracker.name + " " + this.timetracker.firstname, font: { color: { argb: 'A67E56' } } });
            mergedCell.alignment = { vertical: 'middle', horizontal: 'center' }; // Center alignment
            mergedCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFFFF' } // White fill color for the cell
            };
            mergedCell.border = {
                top: { style: 'thin', color: { argb: 'FF000000' } }, // Black top border
                bottom: { style: 'thin', color: { argb: 'FF000000' } } // Black bottom border
            };
            mergedCell.font = {
                family: 1, // Font family
                size: 12, // Font size
                bold: true // Label is bold
            };

            // Merge columns H, I, J and K
            sheet.mergeCells('S1:Y1');
            // Set label "Date" and date value in the merged cell, with label in blue and date in red
            const mergedCells = sheet.getCell('S1');
            mergedCells.value = { richText: [{ text: 'RESPONSABLE: ', font: { color: { argb: '0F5752' } } }] };
            //J'ai changé responsable this.timetracker.rttFirstName + " " + this.timetracker.rttLastName  en  BULMA 
            mergedCells.value.richText.push({ text: "BULMA", font: { color: { argb: 'A67E56' } } });
            mergedCells.alignment = { vertical: 'middle', horizontal: 'center' }; // Center alignment
            mergedCells.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFFFFF' } // White fill color for the cell
            };
            mergedCells.border = {
                top: { style: 'thin', color: { argb: 'FF000000' } }, // Black top border
                bottom: { style: 'thin', color: { argb: 'FF000000' } } // Black bottom border
            };
            mergedCells.font = {
                family: 1, // Font family
                size: 12, // Font size
                bold: true // Label is bold
            };

            const row1 = {
                Title: "Nombre jours ouvrés",
                column2: this.timetracker.jours_ouvrable,
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row1[key] = i + 1;
            }
            sheet.addRow(row1);

            const row2 = {
                Title: null,
                column2: null,
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row2[key] = this.timetracker.daysTimetracker[i];
            }
            sheet.addRow(row2);


            // Add a row below row number 5 (line 6) with column titles
            // const newRow03 = sheet.addRow();
            // newRow03.getCell('A').value = "Nbre jours saisis";
            // newRow03.getCell('B').value = this.timetracker.jours_saisi;
            // for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
            //     const key = 'i' + (i + 1);
            //     newRow03[key] = this.timetracker.jours_saisi[i] == 0 ? '' : this.timetracker.jours_saisi[i];
            // }

            const newRow03 = {
                Title: "Nombre jours saisis",
                column2: this.timetracker.jours_saisi,
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                //newRow03[key] = this.timetracker.jourSaisi[i] == 0 ? '' : this.timetracker.jourSaisi[i];
                newRow03[key] = this.timetracker.sumDays[i] == 0 ? '' : this.timetracker.sumDays[i];
            }
            //const row3Style = sheet.addRow(row3);
            sheet.addRow(newRow03);

            //sheet.addRow();

            const DPlanning = sheet.addRow();
            DPlanning.getCell('A').value = 'DÉTAIL PLANNING';

            // Set the yellow color for the whole row and white font color
            DPlanning.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'A67E56' } // Yellow color code
            };

            // Set the font color to white and bold for the whole row
            DPlanning.eachCell({ includeEmpty: true }, cell => {
                cell.font = {
                    color: { argb: 'FFFFFFFF' }, // White font color
                    bold: true
                };
            });

            const row3 = {
                Title: "Absence",
                column2: this.timetracker.missed_day,
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row3[key] = null;
            }
            //const row3Style = sheet.addRow(row3);
            sheet.addRow(row3);

            const row4 = {
                Title: null,
                column2: "Congés",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row4[key] = this.timetracker.aConge[i] == 0 ? '' : this.timetracker.aConge[i];
            }
            sheet.addRow(row4);

            const row5 = {
                Title: null,
                column2: "RTT",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row5[key] = this.timetracker.aRtt[i] == 0 ? '' : this.timetracker.aRtt[i];
            }
            sheet.addRow(row5);

            const row6 = {
                Title: null,
                column2: "Arrêt maladie",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row6[key] = this.timetracker.aMaladie[i] == 0 ? '' : this.timetracker.aMaladie[i];
            }
            sheet.addRow(row6);
            const row7 = {
                Title: null,
                column2: "Formation",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row7[key] = this.timetracker.aFormation[i] == 0 ? '' : this.timetracker.aFormation[i];
            }
            sheet.addRow(row7);
            const row8 = {
                Title: null,
                column2: "Congés exc.",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row8[key] = this.timetracker.aCongeExc[i] == 0 ? '' : this.timetracker.aCongeExc[i];
            }
            sheet.addRow(row8);
            const row9 = {
                Title: null,
                column2: "Congés sans solde",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row9[key] = this.timetracker.aCongeSSolde[i] == 0 ? '' : this.timetracker.aCongeSSolde[i];
            }
            sheet.addRow(row9);
            const row10 = {
                Title: "Clientèle",
                column2: this.timetracker.clientele,
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row10[key] = null;
            }
            //const row10Style = sheet.addRow(row3);
            sheet.addRow(row10);
            const row11 = {
                Title: this.getClient(this.timetracker.cid_sur_site)[0] ? this.getClient(this.timetracker.cid_sur_site)[0].name : '',
                //Title : this.timetracker.cid_sur_site,
                column2: "Sur site",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row11[key] = this.timetracker.clienteleSite[i] == 0 ? '' : this.timetracker.clienteleSite[i];
            }
            sheet.addRow(row11);

            const row12 = {
                Title: this.getClient(this.timetracker.cid_en_teletravail)[0] ? this.getClient(this.timetracker.cid_en_teletravail)[0].name : '',
                //Title: this.timetracker.cid_en_teletravail,
                column2: "Télétravail",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row12[key] = this.timetracker.clienteleTeletravail[i] == 0 ? '' : this.timetracker.clienteleTeletravail[i];
            }
            sheet.addRow(row12);
            const row13 = {
                Title: this.getClient(this.timetracker.cid_deplacement)[0] ? this.getClient(this.timetracker.cid_deplacement)[0].name : '',
                //Title: this.timetracker.cid_deplacement,
                column2: "Déplacement",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row13[key] = this.timetracker.clienteleDeplacement[i] == 0 ? '' : this.timetracker.clienteleDeplacement[i];
            }
            sheet.addRow(row13);
            const row14 = {
                //Title: this.timetracker.cid_presentation,
                Title: this.getClient(this.timetracker.cid_presentation)[0] ? this.getClient(this.timetracker.cid_presentation)[0].name : '',
                column2: "Présentation",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row14[key] = this.timetracker.clientelePresentation[i] == 0 ? '' : this.timetracker.clientelePresentation[i];
            }
            sheet.addRow(row14);

            const row15 = {
                Title: "Interne",
                column2: this.timetracker.interne_day,
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row15[key] = null;
            }
            //const row15Style = sheet.addRow(row15);
            sheet.addRow(row15);
            const row16 = {
                Title: "Projet",
                column2: "Bulma",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row16[key] = this.timetracker.projetBulma[i] == 0 ? '' : this.timetracker.projetBulma[i];
            }
            sheet.addRow(row16);
            const row17 = {
                Title: "Projet",
                column2: "Bulma télétravail",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row17[key] = this.timetracker.projetBulmaTeletravail[i] == 0 ? '' : this.timetracker.projetBulmaTeletravail[i];
            }
            sheet.addRow(row17);
            const row18 = {
                Title: "Projet",
                column2: "Projet interne",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row18[key] = this.timetracker.projetInterne[i] == 0 ? '' : this.timetracker.projetInterne[i];
            }
            sheet.addRow(row18);
            const row19 = {
                Title: "Structure",
                column2: "Domrémy",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row19[key] = this.timetracker.structureDomremy[i] == 0 ? '' : this.timetracker.structureDomremy[i];
            }
            sheet.addRow(row19);
            const row20 = {
                Title: "Structure",
                column2: "Télétravail",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row20[key] = this.timetracker.structureTeletravail[i] == 0 ? '' : this.timetracker.structureTeletravail[i];
            }
            sheet.addRow(row20);
            
            const row22 = {
                Title: "Intercontrat",
                column2: "Domrémy",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row22[key] = this.timetracker.intercontratDomremy[i] == 0 ? '' : this.timetracker.intercontratDomremy[i];
            }
            sheet.addRow(row22);
            const row23 = {
                Title: "Intercontrat",
                column2: "Télétravail",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row23[key] = this.timetracker.intercontratTeletravail[i] == 0 ? '' : this.timetracker.intercontratTeletravail[i];
            }
            sheet.addRow(row23);

            const row25 = {
                Title: "Heure supplémentaire",
                column2: this.timetracker.suppDay,
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row25[key] = null
            }
            //const row25Style = sheet.addRow(row25);
            sheet.addRow(row25);
            const row26 = {
                Title: "Entrez en heure",
                column2: "Trav supp (h)",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row26[key] = this.timetracker.supp[i] == 0 ? '' : this.timetracker.supp[i];
            }
            sheet.addRow(row26);
            const row27 = {
                Title: null,
                column2: "Astreinte",
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row27[key] = this.timetracker.astreinte[i] == 0 ? '' : this.timetracker.astreinte[i];
            }
            sheet.addRow(row27);

            sheet.addRow();
            sheet.addRow();
            sheet.addRow();

            const row28 = {
                Title: 'Note : ' + this.timetracker.note,
                column2: null,
            };
            for (let i = 0; i < this.timetracker.daysFormatted.length; i++) {
                const key = 'i' + (i + 1);
                row28[key] = null;
            }
            sheet.addRow(row28);
            // this.timetracker.detailHeureSupp [{"date" : "day"+"/"+"month",
            //         "debut" : "17:00", 
            //         "fin" : "18:00", 
            //         "detail" :  "1:00", 
            //         "commentaire" : "texte"}]
            // this.timetracker.validFirstName
            // this.timetrcker.validLastName

            // Set text color to orange for cells in column B (rows 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 26, 27)
            const columnBRows = [9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 21, 22, 23, 24, 25, 26, 27, 28, 30, 31];
            columnBRows.forEach(rowNumber => {
                const cell = sheet.getCell(`B${rowNumber}`);
                cell.font = {
                    color: { argb: 'A67E56' } // Orange text color
                };
            });

            // Set text color to orange for cells in column AB (rows 17, 18, 19, 20, 21, 22, 23, 24)
            const columnABRows = [21, 22, 23, 24, 25, 26, 27, 28, 30];
            columnABRows.forEach(rowNumber => {
                const cell = sheet.getCell(`A${rowNumber}`);
                cell.font = {
                    color: { argb: 'A67E56' } // Orange text color
                };
            });

            const rowNumber035 = 35;

            const row035 = sheet.getRow(rowNumber035);
            row035.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'A67E56' } // Orange color
            };

            row035.font = {
                bold: true,
                color: { argb: 'FFFFFFFF' } // White color
            };

            const rowNumber037 = 37;

            const row037 = sheet.getRow(rowNumber037);
            row037.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'A67E56' } // Orange color
            };

            row037.font = {
                bold: true,
                color: { argb: 'FFFFFFFF' } // White color
            };

            const rows = [4, 6];
            const column0B = 'B';

            rows.forEach(rowNumber => {
                const cell = sheet.getCell(`${column0B}${rowNumber}`);
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '0F5752' } // Green color
                };

                cell.font = {
                    bold: true,
                    color: { argb: 'FFFFFFFF' } // White color
                };
            });

            const rows004 = [4, 6];
            const column00A = 'A';

            rows004.forEach(rowNumber => {
                const cell = sheet.getCell(`${column00A}${rowNumber}`);

                cell.font = {
                    bold: true,
                    color: { argb: '0F5752' } // White color
                };
            });

            const row = sheet.getRow(5); // Specify the row number you want to iterate through
            row.eachCell((cell, colNumber) => {
                if (cell.value === 'S' || cell.value === 'D') {
                    console.log('Weekend');
                    for (let rowNum = 4; rowNum <= 33; rowNum++) {
                        const cell = sheet.getCell(rowNum, colNumber);
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'A67E56' } // Orange color code
                        };
                        cell.font = {
                            color: { argb: 'FFFFFFFF' } // White text color
                        };
                        cell.border = {
                            top: { style: 'double', color: { argb: 'FFFFFFFF' } }, // White top border
                            left: { style: 'double', color: { argb: 'FFFFFFFF' } }, // White left border
                            bottom: { style: 'double', color: { argb: 'FFFFFFFF' } }, // White bottom border
                            right: { style: 'double', color: { argb: 'FFFFFFFF' } } // White right border
                        };
                    }
                } else {
                    console.log(`Cell ${colNumber}: ${cell.value}`);
                    for (let rowNum = 4; rowNum <= 33; rowNum++) {
                        const cell = sheet.getCell(rowNum, colNumber);
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '0F5752' } // Light green color code
                        };
                        cell.font = {
                            color: { argb: 'FFFFFFFF' } // White text color
                        };
                        cell.border = {
                            top: { style: 'double', color: { argb: 'FFFFFFFF' } }, // White top border
                            left: { style: 'double', color: { argb: 'FFFFFFFF' } }, // White left border
                            bottom: { style: 'double', color: { argb: 'FFFFFFFF' } }, // White bottom border
                            right: { style: 'double', color: { argb: 'FFFFFFFF' } } // White right border
                        };
                    }
                }
            });

            const row007 = sheet.getRow(7);
            row007.eachCell({ includeStyle: true }, cell => {
                cell.numFmt = null;
            });

            // Add yellow bottom border to row 4
            for (let colNumber = 1; colNumber <= sheet.columnCount; colNumber++) {
                const cell = sheet.getCell(4, colNumber);
                cell.border = {
                    top: { style: 'double', color: { argb: 'FFFFFFFF' } }, // White top border
                    left: { style: 'double', color: { argb: 'FFFFFFFF' } }, // White left border
                    bottom: { style: 'double', color: { argb: 'A67E56' } }, // Yellow bottom border
                    right: { style: 'double', color: { argb: 'FFFFFFFF' } } // White right border
                };
            }

            const formattedRows = [8, 15, 20, 29];
            formattedRows.forEach(rowNumber => {
                const cellA = sheet.getCell(`A${rowNumber}`);
                cellA.font = {
                    bold: true,
                    color: { argb: 'A67E56' } // Orange text color
                };
                cellA.border = {
                    top: { style: 'thick', color: { argb: 'A67E56' } }, // White top border
                    left: { style: 'thick', color: { argb: 'A67E56' } }, // White left border
                    bottom: { style: 'thick', color: { argb: 'A67E56' } }, // White bottom border
                    right: { style: 'thick', color: { argb: 'A67E56' } } // White right border
                };

                const cellB = sheet.getCell(`B${rowNumber}`);
                cellB.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '0F5752' } // Green fill color for column B
                };
                cellB.font = {
                    color: { argb: 'FFFFFFFF' } // White text color for column B
                };
                cellB.border = {
                    top: { style: 'thick', color: { argb: 'A67E56' } }, // White top border
                    left: { style: 'thick', color: { argb: 'A67E56' } }, // White left border
                    bottom: { style: 'thick', color: { argb: 'A67E56' } }, // White bottom border
                    right: { style: 'thick', color: { argb: 'A67E56' } } // White right border
                };

                const row = sheet.getRow(rowNumber);
                row.border = {
                    top: { style: 'thick', color: { argb: 'A67E56' } }, // Gold color
                    bottom: { style: 'thick', color: { argb: 'A67E56' } } // Gold color
                };

            });

            // Set the fill color for the specified range (row 7, columns C to AF)
            const startColumn07 = 3; // Column C is index 3
            const endColumn07 = 33; // Column AF is index 32
            const rowNumber07 = 7;
            const fillColor = 'A67E56'; // Blue color

            for (let col = startColumn07; col <= endColumn07; col++) {
                const cell = sheet.getCell(rowNumber07, col);
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: fillColor },
                };
                cell.border = {
                    top: { style: 'none' },
                    left: { style: 'none' },
                    bottom: { style: 'none' },
                    right: { style: 'none' },
                };
            }

            const rowsToUpdate = [8, 15, 20, 29];
            const startColumn = 3; // Column C
            const endColumn = 33; // Column AF

            rowsToUpdate.forEach(rowNumber => {
                const row = sheet.getRow(rowNumber);
                for (let col = startColumn; col <= endColumn; col++) {
                    const cell = row.getCell(col);
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // Hex code for white color
                    };
                }
            });
            // Define the range C4:AF33
            const startColumnIndex = 3; // Column C is the 3rd column
            const endColumnIndex = 33; // Column AF is the 32nd column
            const startRowIndex = 4;
            const endRowIndex = 33;

            // Set the horizontal alignment to center for each cell in the range
            for (let row = startRowIndex; row <= endRowIndex; row++) {
                for (let col = startColumnIndex; col <= endColumnIndex; col++) {
                    const cell = sheet.getCell(row, col);
                    cell.alignment = { horizontal: 'center' };
                    //sheet.getColumn(col).width = 2;
                }
            }
            // Adjust the width for each column individually
            // for (let col = startColumnIndex; col <= endColumnIndex; col++) {
            //     sheet.getColumn(col).width = 2;
            // }

            sheet.getCell('A37').value = 'DÉTAILS DES HEURES SUPPLÉMENTAIRES'; // Set the title above the table
            sheet.getCell('C32').style.font = { bold: true }; // Apply bold font style to the title

            //console.log(this.timetracker.heureSupp.date);

            // Generate rows based on the detailHeureSupp array
            if (this.timetracker.detailHeureSupp && this.timetracker.detailHeureSupp.length > 0) {
                const rowsTable = this.timetracker.detailHeureSupp.map(obj => [
                    obj.date,
                    obj.debut,
                    obj.fin,
                    obj.detail,
                    obj.commentaire
                ]);

                // Add a table to the sheet with dynamically generated rows
                sheet.addTable({
                    name: 'MyTable',
                    ref: 'A38',
                    headerRow: true,
                    style: {
                        theme: 'TableStyleLight1',
                        showRowStripes: true,
                        headerRowFont: {
                            size: 8,
                        },
                    },
                    columns: [
                        { name: 'Jour(jj/mm)' },
                        { name: 'Début(hh/mm)' },
                        { name: 'Fin(hh/mm)' },
                        { name: 'Détails' },
                        { name: 'Commentaires' },
                    ],
                    rows: rowsTable,
                });
            }




            // const styleBG = {
            //     type: 'pattern',
            //     pattern:'solid',
            //     fgColor:{ argb: 'FFFFFFFF'},

            // };

            //row3Style.fill = styleBG
            //row10Style.fill = styleBG
            //row15Style.fill = styleBG
            //row25Style.fill = styleBG
            workbook.xlsx.writeBuffer().then(data => {
                const blob = new Blob([data], {
                    type: "application/vdn.openxmlformats-officedocument.spreadsheet.sheet"
                })
                const url = window.URL.createObjectURL(blob)
                const anchor = document.createElement('a')
                anchor.href = url
                anchor.download = nameFile
                anchor.click()
            })
        },
        getClientById(id) {
            if (id != "") {
                axios.post(data.baseUrl + "clients/", { '_id': id })
                    .then(response => {
                        return response.data[0]
                    })
                    .catch(error => console.log(error))
            }
            return null

        },
        getClient(id) {
            return this.clients.filter(item => {
                return item._id == id
            })
        },
        async client(id) {
            let client = await this.getClientById(id)
            if (client != null)
                return client.name
            return ""
        },
        codeforMonth() {
            let tab = []
            for (let i = 1; i <= this.daysInMonth; i++) {
                if (!this.isWeekend(i, this.month)) {
                    tab.push(this.code)
                }
                else tab.push(null)
            }
            return tab
        },
        codeforMonth2(user) {
            const userPrev = this.objectPrevisionnelle.filter(item => {
                return item.collaborateur._id == user._id
            })
            return userPrev.day_code
        },

        getDay(nbrjours) {
            const days = []
            for (let i = 1; i <= nbrjours; i++) {
                const date = new Date(this.year + '-' + this.month + '-' + i)
                const dayNum = date.getDay()
                switch (dayNum) {
                    case 0:
                        days.push('D')
                        break;
                    case 1:
                        days.push('L')
                        break;
                    case 2:
                        days.push('M')
                        break;
                    case 3:
                        days.push('M')
                        break;
                    case 4:
                        days.push('J')
                        break;
                    case 5:
                        days.push('V')
                        break;
                    default:
                        days.push('S')
                        break;
                }
            }

            return days
        },

        changeCodePrev(index, previsionnelle) {
            //console.log("CHANGE CODE dans l'index : ", index)
            const codeAbbrev = []
            this.etats.forEach(item => {
                codeAbbrev.push(item.code_abbrev)
            })

            const user_id = previsionnelle.user._id

            const id = "td" + index + "_" + user_id

            const year = this.year
            const month = this.month
            if (codeAbbrev.includes(document.getElementById(id).textContent.toUpperCase())) {
                document.getElementById(id).className = document.getElementById(id).textContent.toUpperCase()
                const code_abbrev = document.getElementById(id).textContent.toUpperCase()
                var payload = { "user_id": user_id, "year": year, "month": month, "day": index + 1, "code_abbrev": code_abbrev }
                axios.put(data.baseUrl + "timetracker_by_day/update-by-code-abbrev/", payload)
                    .then(response => {
                        console.log(response.data)
                    })
                    .catch(error => console.log(error))

            }
            else {
                if (document.getElementById(id).textContent == "") {
                    document.getElementById(id).className = "null"
                    axios.put(data.baseUrl + "timetracker_by_day/update-by-code-abbrev/", { "user_id": user_id, "year": year, "month": month, "day": index + 1, "code_abbrev": "" })
                        .then(response => {

                            console.log(response.data)
                        })
                        .catch(error => console.log(error))
                }
                else document.getElementById(id).textContent = "JT"
            }
            //this.$router.go()
        },
        getWeek(month) {
            let tabWeek = []
            var oneJan = new Date(this.year, 0, 1);

            for (let i = 1; i <= this.daysInMonth; i++) {
                const laDate = new Date(this.year, month - 1, i)
                const week = Math.ceil((((laDate.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7);
                tabWeek.push(week)
            }
            return tabWeek
        },
        isWeekend(day, month) {
            const date = new Date(this.year + '-' + month + '-' + day)
            let isWk = false
            let isFerier = false
            let dateString = moment(date).format('DD-MM-YYYY');

            isFerier = this.jourFerie.includes(dateString)
            //console.log(day, isFerier)
            if (date.getDay() == 6 || date.getDay() == 0 || isFerier)
                isWk = true
            return isWk
        },
        classIfWeekEnd(day, month) {
            if (this.isWeekend(day, month))
                return "null1"
        },
        countJourOuvre() {
            for (let day = 1; day <= this.daysInMonth; day++) {
                const date = new Date(this.year + '-' + this.month + '-' + day)
                if (date.getDay() == 6 || date.getDay() == 0)
                    console.log("WEEK-END")
                else this.compteurJourOuvre++
            }
        },
        initData() {
            this.clienteleSite = []
            this.clienteleTeletravail = []
            this.clienteleDeplacement = []
            this.clientelePresentation = []
            this.aConge = []
            this.aMaladie = []
            this.aFormation = []
            this.aRtt = []
            this.aCongeSSolde = []
            this.aCongeExc = []
            this.projet = []
            this.intercontrat = []

            this.supp = []
            this.astreinte = []
        },

        sumClientele() {
            return this.sumColumns[6] + this.sumColumns[7] + this.sumColumns[8] + this.sumColumns[9]
        },
        sumAbsence() {
            return this.sumColumns[0] + this.sumColumns[1] + this.sumColumns[2] + this.sumColumns[3] + this.sumColumns[4] + this.sumColumns[5]
        },
        sumInterne() {
            return this.sumColumns[10] + this.sumColumns[11] + this.sumColumns[12] + this.sumColumns[13] + this.sumColumns[14] + this.sumColumns[15] + this.sumColumns[16]
        },
        sumSupp() {
            return this.sumColumns[17] + this.sumColumns[18]
        },

        format_date(value) {
            if (value) {
                return moment(String(value)).format('DD')
            }
        },
        valider(prev_id, timetracker_id, collab_id) {
            var payload = {
                "timetracker_id": prev_id,
                "timetracker_collab_id": timetracker_id,
                "timetracker_user_id": collab_id,
                "user_id": this.user._id,
                "month": this.month,
                "year": this.year
            }
            //console.log(payload)
            axios.post(data.baseUrl + "timetracker/validate-timetracker/", payload)
                .then(response => {
                    //console.log(response.data) 
                    if (!JSON.stringify(response.data).includes("Error"))
                        toast("Timetracker validé")
                    else {
                        toast.error(JSON.stringify(response.data))
                    }

                    setTimeout(() => window.location.reload(), 1500)
                })
        },


        valid(idPrev, idTimetracker) {
            //console.log( {"_id": idPrev,"isValid": "YES"})
            axios.put(data.baseUrl + "timetracker/update/", { "_id": idPrev, "isValid": "YES", "valid_user_id": this.user._id, "valid_date": Date.now })
                .then(response => {
                    //console.log(response.data)
                    if (!JSON.stringify(response.data).includes("Error"))
                        axios.put(data.baseUrl + "timetracker-collab/update/", { "_id": idTimetracker, "isValid": 'YES', "valid_user_id": this.user._id, "valid_date": Date.now })
                            .then(response => {
                                //console.log(response.data)
                                if (!JSON.stringify(response.data).includes("Error")) {
                                    document.getElementById("btn_" + idTimetracker).disabled


                                    this.$router.go()
                                } else {
                                    toast.error(JSON.stringify(response.data))
                                }
                            })
                    toast("Timetracker validé")
                })
                .catch(error => console.log(error))

        },
        annulerValidation(idPrev, idTimetracker) {

            axios.put(data.baseUrl + "timetracker/update/", { "_id": idPrev, "isValid": "NO", "valid_user_id": this.user._id, "valid_date": Date.now })
                .then(response => {
                    console.log("firts update : ", response)
                    axios.put(data.baseUrl + "timetracker-collab/update/", { "_id": idTimetracker, "isValid": 'NO', "valid_user_id": this.user._id, "valid_date": Date.now })
                        .then(response => {
                            console.log(response)
                            this.$router.go()
                        })
                    //document.getElementById("btn_"+idTimetracker).disabled
                    toast("Validation annulée")
                })
                .catch(error => console.log(error))
        },

        format_date2(value) {
            if (value) {
                return moment(String(value)).format('yyyy-MM-DD')
            }
        },
        replaceWithBr(message) {
            return message.replace(/\n/g, "<br />")
        },
        compare(timetracker, previsionnel) {

            let erreur = ''

            let clientele = timetracker.clienteleday, projet = timetracker.projet, aMaladie = timetracker.aMaladie, aConge = timetracker.aConge
            let aRtt = timetracker.aRtt, aCongeExc = timetracker.aCongeExc, acongeSSolde = timetracker.aCongeSSolde, intercontrat = timetracker.intercontrat
            let structure = timetracker.structure
            console.log(timetracker)
            console.log(clientele.length)
            if (erreur == '' && previsionnel) {
                for (let i = 0; i < clientele.length; i++) {
                    let jours = i + 1

                    if (clientele[i] == 1 || projet[i] == 1 || structure[i] == 1) {
                        // console.log("ONE :", jours)
                        if (previsionnel.tt_by_day[i] && previsionnel.tt_by_day[i].code_abbrev != "JT") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> Jour travaillé : JT \n"
                        }
                    }
                    else if (clientele[i] == 0.5 || projet[i] == 0.5 || structure[i] == 0.5) {
                        // console.log("2 : ", jours)
                        if (aConge[i] == 0.5 && previsionnel.tt_by_day[i].code_abbrev != "TC") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> 1/2 Travail / Congé payé : TC \n"
                        }
                        else if (aMaladie[i] == 0.5 && previsionnel.tt_by_day[i].code_abbrev != "TM") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> 1/2 Travail / Maladie : TM \n"
                        }
                        else if (aRtt[i] == 0.5 && previsionnel.tt_by_day[i].code_abbrev != "TR") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> 1/2 Travail / RTT : TR \n"
                        }
                        else if (intercontrat[i] == 0.5 && previsionnel.tt_by_day[i].code_abbrev == "TI") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==>1/2 Travail / Intercontrat : TI \n";
                        }
                        //else if(aFormation[i]==0.5 && prev[0].day_code[i]=="??"){
                        //  compt++;
                        //}
                    } else if (intercontrat[i] == 0.5) {
                        // console.log("3 : ", jours)
                        if (aRtt[i] == 0.5 && previsionnel.tt_by_day[i].code_abbrev != "IR") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> 1/2 Intercontrat / RTT : IR \n"
                        }
                    }
                    else {
                        // console.log(jours, intercontrat[i]) 
                        if (aMaladie[i] == 1 && previsionnel.tt_by_day[i].code_abbrev != "MA") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> Maladie : MA \n"
                        } else if (aConge[i] == 1 && previsionnel.tt_by_day[i].code_abbrev != "CP") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> Congés : CP \n"
                        } else if (aRtt[i] == 1 && previsionnel.tt_by_day[i].code_abbrev != "RT") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> RTT : RT \n"
                        }
                        else if (aCongeExc[i] == 1 && previsionnel.tt_by_day[i].code_abbrev != "CX") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> Congés exception : CX \n"
                        } else if (acongeSSolde[i] == 1 && previsionnel.tt_by_day[i].code_abbrev != "CS") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> Congés sans solde : CS \n"
                        }
                        else if (intercontrat[i] == 1 && previsionnel.tt_by_day[i].code_abbrev != "IC") {
                            this.error = true
                            erreur = erreur + "Erreur de code  à la date de " + jours + ' ' + this.months[this.month - 1] + " " + this.year + " ==> Intercontrat : IC \n"
                        }

                    }
                }
            }
            //console.log(erreur)
            this.erreur = erreur

            //console.log("HEEERE", this.erreur)
            //if(erreur=='')
            //document.getElementById("btn_"+timetracker._id).disabled
            return this.erreur
        },

        logout() {
            localStorage.removeItem('user')
            this.$router.push('/login')
        },

        changeTdContent(index, libelle) {
            let startID = "td"
            if (this.fromDatabase) startID = 'tdb'
            if (libelle != "supp") {
                if (document.getElementById(startID + '_' + index + '_' + libelle).textContent == 1 || document.getElementById(startID + '_' + index + '_' + libelle).textContent == 0.5 || document.getElementById(startID + '_' + index + '_' + libelle).textContent == "") {
                    //const day = index+1
                    //console.log("Content exact pour la date "+ day +"  "+ this.month , libelle, this.projetBulma, this.projetBulmaTeletravail)
                    if (libelle == "site")
                        this.clienteleSite[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "teletravail")
                        this.clienteleTeletravail[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "deplacement")
                        this.clienteleDeplacement[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "presentation")
                        this.clientelePresentation[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "CP")
                        this.aConge[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "RT")
                        this.aRtt[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "MA")
                        this.aMaladie[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "FO")
                        this.aFormation[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "CS")
                        this.aCongeSSolde[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "CX")
                        this.aCongeExc[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "projetBulma")
                        this.projetBulma[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "projetBulmaTeletravail")
                        this.projetBulmaTeletravail[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "projetInterne")
                        this.projetInterne[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "structureDomremy")
                        this.structureDomremy[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "structureTeletravail")
                        this.structureTeletravail[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "ICDomremy")
                        this.intercontratDomremy[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "ICTeletravail")
                        this.intercontratTeletravail[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                    else if (libelle == "astreinte")
                        this.astreinte[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                }
                else {
                    document.getElementById(startID + '_' + index + '_' + libelle).textContent = ""
                }

            } else {
                //let val =  parseFloat(document.getElementById(startID+'_'+index+'_'+libelle).textContent)

                //if(val>=1 && val<=8){
                //document.getElementById(startID+'_'+index+'_'+libelle).textContent=val/8
                this.supp[index] = parseFloat(document.getElementById(startID + '_' + index + '_' + libelle).textContent)
                //}else if(val>0 && val<1){
                //console.log("DO NOT CHANGE")
                //}
                //else document.getElementById(startID+'_'+index+'_'+libelle).textContent=""
            }
            this.sumDays = [], this.sumColumns = []
            for (let i = 0; i < this.daysInMonth; i++) {
                const value = this.sumByDay(i)
                if (value > 1)
                    document.getElementById(startID + '_' + index).class = 'plusDeUn'
                //else if(value<=1 && value>0) document.getElementById('tdb_'+index).class='correct' 
                this.sumDays.push(value)
            }
            for (let i = 0; i < this.libelles.length; i++) {
                this.sumColumns.push(this.sumByColumn(this.libelles[i]))
            }
            this.calculSum()
            this.updateTimetracker()
        },
        sumByDay(index) {
            let startID = "td"
            if (this.fromDatabase) startID = 'tdb'
            let res = 0
            for (let i = 0; i < this.libelles.length; i++) {

                let chiffre = 0;
                if (document.getElementById(startID + '_' + index + '_' + this.libelles[i]).textContent == '') {
                    chiffre = 0
                }
                else {
                    if (this.libelles[i] == 'supp')
                        chiffre = parseFloat(document.getElementById(startID + '_' + index + '_' + this.libelles[i]).textContent)
                    else chiffre = parseFloat(document.getElementById(startID + '_' + index + '_' + this.libelles[i]).textContent)
                }

                res += chiffre

            }
            return res
        },

        calculSum() {

            this.sClientele = this.sumClientele()
            this.sAbsence = this.sumAbsence()
            this.sInterne = this.sumInterne()
            this.sSupp = this.sumSupp()
            this.jourSaisi = this.sClientele + this.sAbsence + this.sInterne
        },
        // updateTimetracker() {
        //     for (let i = 0; i < this.daysInMonth; i++) {

        //         this.clientele.push(this.clienteleSite[i] + this.clienteleTeletravail[i] + this.clienteleDeplacement[i] + this.clientelePresentation[i])
        //         this.absence.push(this.aConge[i] + this.aRtt[i] + this.aFormation[i] + this.aMaladie[i] + this.aCongeSSolde[i] + this.aCongeExc[i])

        //     }
        //     var payload = {
        //         "_id": this.timetracker._id,
        //         "aConge": this.aConge,
        //         "aRtt": this.aRtt,
        //         "aMaladie": this.aMaladie,
        //         "aCongeSSolde": this.aCongeSSolde,
        //         "aCongeExc": this.aCongeExc,
        //         "clienteleDeplacement": this.clienteleDeplacement,
        //         "clienteleSite": this.clienteleSite,
        //         "clienteleTeletravail": this.clienteleTeletravail,
        //         "clientelePresentation": this.clientelePresentation,
        //         "projetBulma": this.projetBulma,
        //         "projetBulmaTeletravail": this.projetBulmaTeletravail,
        //         "projetInterne": this.projetInterne,
        //         "structureDomremy": this.structureDomremy,
        //         "structureTeletravail": this.structureTeletravail,
        //         "intercontratDomremy": this.intercontratDomremy,
        //         "intercontratTeletravail": this.intercontratTeletravail,
        //         "projet": this.projet,
        //         "intercontrat": this.intercontrat,
        //         "clienteleday": this.clientele,
        //         "absence": this.absence,
        //         "note": this.note,
        //         "sumDays": this.sumDays,
        //         "supp": this.supp,
        //         "astreinte": this.astreinte,
        //         "cid_sur_site": this.cid_sur_site,
        //         "cid_en_teletravail": this.cid_en_teletravail,
        //         "cid_deplacement": this.cid_deplacement,
        //         "cid_presentation": this.cid_presentation,
        //     }
        //     axios.put(data.baseUrl + "timetracker-collab/update/", payload)
        //         .then(response => {

        //             //console.log(response.data) 
        //             if (!JSON.stringify(response.data).includes('Error'))
        //                 console.log("Timetracker enregistré")
        //             else toast.error(JSON.stringify(response.data).includes('Error'))
        //         })
        //         .catch(error => console.log(error))

        // },
        updateTimetracker(){
        this.projet= []
        this.intercontrat= []
        this.clientele = []
        this.structure = []
        this.absence = []
        // console.log(this.structureDomremy ,  this.structureTeletravail)
        for(let i=0; i<this.daysInMonth; i++){
                this.clientele.push(this.clienteleSite[i]+this.clienteleTeletravail[i]+this.clienteleDeplacement[i]+this.clientelePresentation[i])
                this.absence.push(this.aConge[i]+this.aRtt[i]+this.aFormation[i]+this.aMaladie[i]+this.aCongeSSolde[i]+this.aCongeExc[i])
                this.projet.push(this.projetBulma[i]+this.projetBulmaTeletravail[i]+ this.projetInterne[i])
                this.structure.push(this.structureDomremy[i]+this.structureTeletravail[i])
                this.intercontrat.push(this.intercontratDomremy[i]+this.intercontratTeletravail[i])
            }
            var payload = {
                "_id" : this.timetracker._id,
                "aConge" :this.aConge,
                "aRtt" : this.aRtt,
                "aMaladie" : this.aMaladie,
                "aCongeSSolde" : this.aCongeSSolde,
                "aFormation" : this.aFormation, 
                "aCongeExc" :this.aCongeExc,
                "clienteleDeplacement" :this.clienteleDeplacement,
                "clienteleSite" : this.clienteleSite,
                "clienteleTeletravail" : this.clienteleTeletravail,
                "clientelePresentation" : this.clientelePresentation,
                "projetBulma" : this.projetBulma,
                "projetBulmaTeletravail" :this.projetBulmaTeletravail,
                "structure" : this.structure,
                "projetInterne" : this.projetInterne,
                "structureDomremy" : this.structureDomremy,
                "structureTeletravail" : this.structureTeletravail,
                "intercontratDomremy" : this.intercontratDomremy,
                "intercontratTeletravail" : this.intercontratTeletravail,
                "projet" : this.projet,
                "intercontrat" : this.intercontrat,
                "clienteleday" : this.clientele,
                "absence" : this.absence, 
                "clientele" : this.sClientele,
                "note" : this.note,
                "sumDays" :this.sumDays,
                "supp" : this.supp,
                "missed_day" :this.sumAbsence(),
                "interne_day": this.sInterne,
                "suppDay" : this.sSupp,
                "astreinte" : this.astreinte,
                "jours_saisi" :this.jourSaisi,
                "cid_sur_site" : this.cid_sur_site,
                "cid_en_teletravail" : this.cid_en_teletravail,
                "cid_deplacement" : this.cid_deplacement,
                "cid_presentation" : this.cid_presentation,
                "detailHeureSupp" : this.tabDetailHeureSupp
            }
            console.log( payload)
            axios.put(data.baseUrl + "timetracker-collab/update/", payload)
            .then(response => {
                if(!JSON.stringify(response.data).includes('Error'))
                {
                    toast.success("Timetracker enregistré")
                } 
                else toast.error(JSON.stringify(response.data).includes('Error'))
                setTimeout(()=>this.$router.go(), 1500)
            })
            .catch(error => console.log(error))
        },

    },
    unmounted() {
        this.rttChecked = []
        this.companieChecked = []
    }
}
</script>
<style>
#timetracker {
    zoom: "50%";
}

.nav {
    background: #024013;
    height: 60px;
    align-content: center;
    color: white;
    z-index: 0;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 10px;
    width: 100%;
}

.form-inline h2 {
    color: white;
    margin-top: 3px;
}

ul {
    padding-bottom: 20px
}

ul li a img {
    background: #66BB6A;
    top: 0;
    border: none;
    width: 20px
}

.main {
    transition: margin-left .5s;
    margin-left: 20px;
}

#content {
    margin-left: 20px;
}

th {
    text-align: center;
}

.carousel__item {
    display: block;
    overflow: hidden;
    position: static;
    /*height: 500px !important;*/

}

.carousel__slide {
    width: 50%;
    margin-left: 20px
}

.null1 {
    background-color: #665e5e33;
    width: 2px;
}

.bgGreenTC {
    padding-top: 12px;
    padding-bottom: 12px;
    /* text-align: center; */
    background-color: rgb(202, 255, 241);
    /* background-color: aqua; */
    color: black;
}
</style>